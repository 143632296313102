import { classnames } from '@/helpers';
import { HTMLAttributes } from 'react';
import { BUTTON_PADDING } from '../Button/Button';
import { ButtonSize, ButtonVariant } from '../types';
import css from './Fab.module.scss';
type Icon = JSX.Element;
type GenericFabProps = {
  variant?: ButtonVariant;
  size: ButtonSize;
  floating?: boolean;
  icon?: Icon;
  rightIcon?: Icon;
  label?: string;
  disabled?: boolean;
};

export type FabProps = HTMLAttributes<HTMLButtonElement> & GenericFabProps;

const getVariant = (variant: ButtonVariant, size: ButtonSize, floating: boolean, disabled: boolean) => {
  const variants = {
    primary: `bg-black-900 text-${size} text-white ${disabled ? 'opacity-20' : 'hover:bg-black-700'} ${
      !disabled && floating ? 'shadow-lg' : ''
    }`,
    secondary: `bg-black-100 text-${size} text-black ${disabled ? 'opacity-20' : 'hover:bg-black-50'} ${
      !disabled && floating ? 'shadow-lg' : ''
    }`,
    tertiary: `bg-brand text-${size} text-white ${disabled ? 'opacity-20' : 'hover:bg-brand-200'} ${
      !disabled && floating ? 'shadow-lg' : ''
    }`,
    link: `text-${size} ${disabled ? 'opacity-20' : ''}`,
  };

  return variants[variant];
};

const base = `flex items-center justify-center rounded-full`;
const btnClasses = (
  variant: ButtonVariant,
  size: ButtonSize,
  floating: boolean,
  disabled?: boolean,
  className?: string,
) => classnames(base, getVariant(variant, size, floating, disabled), className);

const Fab = ({
  variant = 'primary',
  label,
  size,
  floating,
  icon,
  disabled,
  children,
  className = '',
  rightIcon,
  ...props
}: FabProps) => {
  return (
    <button
      className={`${css.button} ${css[size]} ${css[variant]} ${
        label || children ? `!h-auto !w-auto ${BUTTON_PADDING[size]} gap-x-md` : ''
      } ${btnClasses(variant, size, floating, disabled, className)}`}
      {...props}>
      {icon && <span className={`${css.icon} ${css[size]}`}>{icon}</span>}
      {label ?? children}
      {rightIcon && <span className={`${css.icon} ${css[size]}`}>{rightIcon}</span>}
    </button>
  );
};

export default Fab;
