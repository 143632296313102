import { StatusMessage } from '@/components/elements/StatusMessage';
import { Button } from '@/components/elements/forms/buttons';
import { LoadingIcon } from '@/components/icons';
import { ModalActionbar } from '@/components/modules/modals/Modal';
import { _s } from '@/locale';
import '@adyen/adyen-web/dist/adyen.css';
import css from './AddCardForm.module.scss';

const AddCardForm = ({
  checkout,
  error,
  loading,
  paymentContainer,
  sessionState,
  detachedSubmit,
  inModal,
  submitLabel,
  handleSubmit,
  disclaimerLabel,
}) => {
  return (
    <div className={`${css.adyen} w-full`}>
      {error && inModal && !loading && (
        <div className="my-4">
          <StatusMessage message={_s('onlinePaymentOrderRefused.invalid')} type="error" />
        </div>
      )}
      {loading && <LoadingIcon className="mx-auto mt-10 block h-10 w-10" />}
      <div data-cy="adyenAddNewCardContainer" className="mb-6 w-full" ref={paymentContainer}></div>
      {checkout && !detachedSubmit && sessionState !== 'hidden' && (
        <>
          {inModal ? (
            <>
              <div className="sm:hidden">
                <ModalActionbar>
                  <div className="container mx-auto">
                    <Button block size="md" disabled={sessionState === 'disabled'} onClick={handleSubmit}>
                      {submitLabel}
                    </Button>
                  </div>
                </ModalActionbar>
              </div>
              <div className="hidden sm:block">
                <Button
                  label={submitLabel}
                  onClick={handleSubmit}
                  size="md"
                  block
                  disabled={sessionState === 'disabled'}
                />
              </div>
            </>
          ) : (
            <Button
              data-cy="submitButton"
              block
              size="md"
              disabled={sessionState === 'disabled'}
              onClick={handleSubmit}>
              {submitLabel}
            </Button>
          )}
        </>
      )}
      {sessionState !== 'hidden' && disclaimerLabel && (
        <div className="flex items-center justify-center py-4">
          <p className="text-black-600 mt-md mx-auto max-w-[380px] text-center text-xs">{disclaimerLabel}</p>
        </div>
      )}
    </div>
  );
};
export default AddCardForm;
