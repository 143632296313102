import { TabAction } from '..';
import { TopTabBarItem as TopTabBarItemProps } from '../types';
import Icon from '@/components/icons/Icon';

const TopTabBarItem = ({ selected, label, onClick, to, isSubMenu, count }: TopTabBarItemProps) => {
  label += selected && count ? ` (${count} st)` : '';
  return isSubMenu ? (
    <TabAction className={`flex flex-col items-center text-sm rounded-full ${selected ? 'bg-black-900' : 'bg-black-100'}`} onClick={onClick} to={to}>
      <div className="relative flex items-center w-max text-xs py-xs px-lg h-[30px]">
        <span className={`${selected ? 'text-white' : 'text-black-600'}`}>{label}</span>
        {selected && (<Icon variant="check" color="white" size="sm" className="pl-xs float-right" />)}
      </div>
    </TabAction>
  ) : (
    <TabAction className="flex flex-col items-center text-sm w-full" onClick={onClick} to={to}>
      <div className="relative h-[27px] w-max text-sm">
        <span className={`pb-xs ${selected ? 'text-black-900 border-b-2' : 'text-black-600'}`}>{label}</span>
      </div>
    </TabAction>
  );
};

export default TopTabBarItem;
