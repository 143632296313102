import { bookActions, loadingActions } from '@/actions';
import Label from '@/components/elements/Label/Label';
import { Button } from '@/components/elements/forms/buttons';
import { ChevronIcon } from '@/components/icons';
import { ReadMoreModal } from '@/components/modules/modals';
import WaitlistModal from '@/components/modules/modals/WaitlistModal';
import { bookConstants } from '@/constants';
import {
  capitalizeFirstLetter,
  getPlaceTimezone,
  getServiceCampaignIfAny,
  getServiceDuration,
  getServicePrice,
  hasDynamicPricing,
  hoursFromSeconds,
  isMobile,
  isSistaminuten,
  isToday,
  isTomorrow,
  makeReadableDay,
  scrollAccordionContentInView,
} from '@/helpers';
import { themed } from '@/helpers/theme';
import { __ } from '@/locale';
import { bookServices } from '@/services';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BookWaitlist from './BookWaitlist';
import BookWithCapacity from './BookWithCapacity';
require('moment-timezone');

class Day extends React.Component {
  constructor(props) {
    super(props);

    moment.tz.setDefault(getPlaceTimezone(props.place));

    this.state = {
      showMoreInfo: false,
      activeItem: null,
      activeSchedule: null,
      expanded: props.isOpen || props.index === 0 ? true : false,
    };

    this.closeSubscribe = this.closeSubscribe.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ expanded: this.props.isOpen });
    }
  }

  handleCollapse = (name) => (e) => {
    if (!this.state.expanded) {
      this.setState({ expanded: !this.state.expanded }, () => scrollAccordionContentInView(e.target));
    } else {
      this.setState({ expanded: !this.state.expanded });
    }
  };

  buildDay(timestamp) {
    if (isToday(timestamp)) {
      return __('today');
    }

    if (isTomorrow(timestamp)) {
      return __('tomorrow');
    }
    return makeReadableDay(timestamp);
  }

  book = (capacity, data, day) => async (e) => {
    const { dispatch, place, history } = this.props;

    let dynamicPriceListIdKey = bookConstants.DYNAMIC_PRICE_KEY_DEFAULT;
    if (hasDynamicPricing(data.service)) {
      dispatch(loadingActions.show());
      try {
        const hasDynamicPrice = await bookServices.hasDynamicPrice(place.id, data.start);
        if (hasDynamicPrice?.dynamicPriceListIdKey) {
          dynamicPriceListIdKey = hasDynamicPrice.dynamicPriceListIdKey;
        }
      } catch (e) {}
      dispatch(loadingActions.hide());
    }

    dispatch(bookActions.bookAgain(place, [data.service], data.employee, parseInt(day, 10)));
    dispatch(
      bookActions.pickHour(
        parseInt(day, 10),
        parseInt(data.from, 10),
        [data.employee.id],
        dynamicPriceListIdKey,
        data.resources,
        capacity,
        1,
      ),
    );

    history.push({
      pathname: '/booking/checkout',
      state: { fromUrl: this.props.location.pathname },
    });
  };

  waitlistEnabled = () => {
    return !!this.props.place.about.settings?.supportsWaitlist;
  };

  getBookButton = (item, schedule, k) => {
    if (!item.fullbooked) {
      return (
        <div className="modal-actionbar stick-to-bottom opened">
          <Button onClick={this.book(item.capacity, item, schedule.day)}>{__('book')}</Button>
        </div>
      );
    } else if (this.waitlistEnabled()) {
      return (
        <div className="modal-actionbar stick-to-bottom opened">
          <Button
            onClick={() => this.showSubscribePopup(item, k)}
            variant="primary"
            style={{ borderColor: '#336CA8', backgroundColor: '#336CA8' }}>
            {__('bookWaitingList')}
          </Button>
        </div>
      );
    }

    return <></>;
  };

  showSubscribePopup(_, k) {
    this.setState({ showSubscribePopup: k });
  }

  closeSubscribe() {
    this.setState({ showSubscribePopup: undefined });
  }

  render() {
    const { schedule, place = {}, className } = this.props;
    const { expanded } = this.state;
    const services = [];
    const employees = [];

    const collapseClass = expanded ? 'block' : 'hidden';

    const hideEmployees = (place.about && place.about.settings && place.about.settings.hideEmployees) || false;

    place.services.forEach((groups) => {
      groups.services.forEach((service) => {
        services[service.id] = service;
        services[service.id].groupOrder = groups.order || 0;
      });
    });

    place.employees.forEach((employee) => {
      employees[employee.id] = employee;
    });

    if (schedule && schedule.day) {
      const dayLabel = this.buildDay(schedule.day);

      const list = [];
      schedule.program.forEach((day) => {
        const service = services[day.service] || {};
        const employee = employees[day.employee] || {};
        if (services[day.service] && employees[day.employee]) {
          list.push({
            from: day.from,
            service,
            employee,
            capacity: day.capacity,
            fullbooked: Boolean(day.capacity === 0),
            start: day.start,
          });
        }
      });

      list.sort((a, b) => {
        try {
          if (a.from === b.from) {
            // sort by service order
            if (a.service.groupOrder === b.service.groupOrder) {
              return a.service.sorting < b.service.sorting ? -1 : 1;
            }

            // sort by group order
            return a.service.groupOrder < b.service.groupOrder ? -1 : 1;
          }

          // Sort by from
          return a.from < b.from ? -1 : 1;
        } catch (e) {
          // Sort by from
          return a.from < b.from ? -1 : 1;
        }
      });

      const htmlList = list.map((item, k) => {
        const name = (item.service && item.service.name) || '';
        const serviceName = isMobile()
          ? capitalizeFirstLetter(name.split('.').join('. '))
          : capitalizeFirstLetter(name);

        const priceListId = item.employee && item.employee.about ? item.employee.about.priceListId : null;

        const { campaignService } = getServiceCampaignIfAny(item.service, place.campaigns, [item.service.id]);
        let price = getServicePrice(item.service, priceListId, place);
        if (campaignService) {
          const offerPrice = getServicePrice(item.service, priceListId, place, campaignService);
          if (offerPrice && offerPrice !== price) {
            price = (
              <span className="offer-price text-danger">
                {offerPrice + ' '}
                <s>{'(ord. ' + price + ')'}</s>
              </span>
            );
          }
        }

        const serviceDuration = getServiceDuration(
          item.service,
          item.employee && item.employee.about ? item.employee.about.priceListId : null,
        );

        let moreInfoProps = undefined;
        if (item.service && item.service.about && item.service.about.description) {
          moreInfoProps = {
            title: serviceName,
            info: item.service.about.description,
            button: this.getBookButton(item, schedule, k),
          };
        }

        const smDiscount = isSistaminuten() && place?.about?.sistaminutenDiscount;

        const campaignBadgeHtml = smDiscount && (
          <Label label={`-${smDiscount}%`} className={'bg-sm_secondary !text-white'} />
        );

        return (
          <div key={k} className="bookslot-container">
            <div
              className={`my-1 flex w-full justify-between px-3 py-3 ${
                item.fullbooked ? '' : 'md:hover:bg-black-50 md:cursor-pointer'
              }`}
              onClick={() => {
                if (!item.fullbooked) {
                  this.book(item.capacity, item, schedule.day)();
                } else if (this.waitlistEnabled()) {
                  this.showSubscribePopup(item, k);
                }
              }}>
              <div className="flex flex-col md:flex-row">
                {campaignBadgeHtml && <div className="mb-1 md:hidden">{campaignBadgeHtml}</div>}
                <div
                  className={`time text-black-900 flex flex-shrink-0 flex-col text-lg md:pr-8 ${
                    item.fullbooked ? 'text-black-600' : 'text-black-900'
                  }`}>
                  <span>{hoursFromSeconds(item.from)}</span>
                </div>
                <div className="info pr-2">
                  {campaignBadgeHtml && <div className="mb-1 hidden md:block">{campaignBadgeHtml}</div>}
                  <p
                    className={`${
                      item.fullbooked ? 'text-black-600' : 'text-black-900'
                    } text-sm !leading-5 md:text-base`}>
                    <span className="block">{serviceName}</span>
                    {!hideEmployees && item.employee && item.employee.about && <span>{item.employee.about.name}</span>}
                  </p>
                  <span className="text-black-600 text-sm">
                    {serviceDuration}
                    {price && serviceDuration && ', '}
                    {price && <span>{price}</span>}
                    {moreInfoProps && !this.state.showSubscribePopup && (
                      <div className="hidden md:inline-block">
                        {(price || serviceDuration) && <span>&nbsp;&middot;&nbsp;</span>}
                        {<ReadMoreModal modalProps={{ ...moreInfoProps }} />}
                      </div>
                    )}

                    {!item.fullbooked && item.capacity > 1 && (
                      <div className="text-danger text-xs font-semibold">
                        {__(`availableCapacity`, { count: item.capacity })}
                      </div>
                    )}
                    <div className="md:hidden">
                      {moreInfoProps && !this.state.showSubscribePopup && (
                        <ReadMoreModal modalProps={{ ...moreInfoProps }} />
                      )}
                    </div>
                  </span>

                  {item.fullbooked && this.waitlistEnabled() && (
                    <div className="mt-1">
                      <Label variant="fully-booked" label={__('fullBookedWaitlist')} />
                    </div>
                  )}
                  {item.fullbooked && !this.waitlistEnabled() && (
                    <div className="mt-1">
                      <Label variant="fully-booked" />
                    </div>
                  )}
                </div>
              </div>
              {!item.fullbooked && <BookWithCapacity />}
              {item.fullbooked && this.waitlistEnabled() && <BookWaitlist />}
            </div>
            {this.state.showSubscribePopup === k && <WaitlistModal isOpen onRequestClose={this.closeSubscribe} />}
            {k !== list.length - 1 && <hr />}
          </div>
        );
      });

      if (!list.length) {
        return null;
      }

      return (
        <div className={`item ${expanded && !isMobile() ? 'mb-4' : ''}`}>
          <div
            onClick={this.handleCollapse(__(dayLabel))}
            className={`mb-[2px] flex cursor-pointer items-center justify-between p-3 ${themed('bg-black-50', '')}`}>
            <h3 className="pointer-events-none font-semibold">{capitalizeFirstLetter(dayLabel)}</h3>
            <span
              className={`pointer-events-none flex items-center justify-center rounded-full p-2 ${
                expanded ? 'bg-[#ADB4C4]' : themed('bg-primary', 'bg-sm_primary')
              }`}>
              <ChevronIcon className={`h-[8px] w-[8px] text-white ${expanded ? 'rotate-180' : ''}`} />
            </span>
          </div>

          <div
            id={className ? className + schedule.day : 'services' + schedule.day}
            className={'accordion-content ' + collapseClass}
            role="tabpanel">
            {htmlList}
          </div>
          {this.state.showMoreInfo && this.getMoreInfo()}
        </div>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  const { user } = state.users;
  return { user };
}

export default connect(mapStateToProps)(withRouter(Day));
