import { trackMpEvent } from '@/helpers';
import { _s } from '@/locale';
import { LPAppBanner } from '@/types/landingPages';
import { useEffect, useState } from 'react';

type AppBannerProps = {
  landingPageID: number;
};
const AppBanner = ({ image, heading, text, landingPageID }: LPAppBanner & AppBannerProps) => {
  const [location, setLocation] = useState<string>('/sidor');

  useEffect(() => {
    setLocation(window.location.href);
  }, []);

  const url = `https://bokadirekt.smart.link/b0p4m18tk`;

  const handleBannerClick = () => {
    trackMpEvent('go_to_appstore_clicked', {
      screen_name: 'landing_page',
      landing_page_id: landingPageID,
    });
  };

  return (
    <div className="bg-information-50 flex flex-col items-center gap-6 p-6">
      <img src={image.url} className="block max-h-72 max-w-full" />
      <h2 className="text-xl font-bold">{heading}</h2>
      <p>{text}</p>
      <div className="flex justify-between">
        <a className="w-1/2" href={url} target="_blank" rel="noreferrer" onClick={handleBannerClick}>
          <img
            src="/images/landingPages/app-store-badge.svg"
            alt={_s('availableAppStore')}
            className="h-full max-w-full"
          />
        </a>
        <a className="w-1/2" href={url} target="_blank" onClick={handleBannerClick}>
          <img src="/images/landingPages/google-play-badge.png" alt={_s('availablePlayStore')} className="max-w-full" />
        </a>
      </div>
    </div>
  );
};

export default AppBanner;
