import { useGetAmplitudeExperimentVariant } from '@/hooks/useAmplitudeExperiment';

function withAmplitudeExperiment<P>(WrappedComponent: React.ComponentType<P>) {
  const WithAmplitudeExperiment = (props: P) => {
    const getVariant = useGetAmplitudeExperimentVariant();
    return <WrappedComponent {...props} getVariant={getVariant} />;
  };
  return WithAmplitudeExperiment;
}

export default withAmplitudeExperiment;
