const colors = {
  brand: {
    50: '#E6F2F0',
    200: '#A1D6CB',
    500: '#51A492',
    DEFAULT: '#51A492',
    700: '#18735F',
    900: '#0D4035',
  },
  primary: {
    50: '#F2F7FA',
    200: '#D9E0E7',
    500: '#73828F',
    DEFAULT: '#001A33',
    700: '#3B4A58',
    900: '#001A33',
  },
  secondary: {
    50: '#F0E6F2',
    200: '#DBB4E5',
    500: '#5C246B',
    DEFAULT: '#5C246B',
    700: '#421150',
    900: '#2A0634',
  },
  black: {
    50: '#F2F7FA',
    100: '#E6EDF2',
    200: '#D9E0E7',
    300: '#B8C3CC',
    400: '#A0A8AF',
    500: '#73828F',
    600: '#506172',
    700: '#3B4A58',
    800: '#273746',
    900: '#001A33',
    DEFAULT: '#000000',
  },
  brown: {
    50: '#FFF7F5',
    100: '#FFE3DB',
    200: '#FFD7CC',
    300: '#FECABC',
    400: '#FEC5B5',
    500: '#F9B39F',
    600: '#EA8D74',
    700: '#7D2B14',
    800: '#571807',
    900: '#2C0B02',
  },
  information: {
    50: '#EAF0F6',
    200: '#B6D6F6',
    500: '#71A4D9',
    DEFAULT: '#71A4D9',
    700: '#336CA8',
    900: '#092A4E',
  },
  success: {
    50: '#E4F0E9',
    200: '#CEEDDA',
    500: '#398758',
    DEFAULT: '#398758',
    700: '#1A6437',
    900: '#063418',
  },
  danger: {
    50: '#F0E5E4',
    200: '#D6A7A1',
    500: '#C04C3D',
    DEFAULT: '#C04C3D',
    700: '#982A1B',
    900: '#570F06',
  },
  highlight: {
    50: '#F8EFEE',
    200: '#F8C9C6',
    500: '#F86A61',
    DEFAULT: '#F86A61',
    700: '#C9524A',
    900: '#572522',
  },
  warning: {
    50: '#F9F6EC',
    200: '#F4E5B1',
    500: '#F5CC3B',
    DEFAULT: '#F5CC3B',
    700: '#C89F0C',
    900: '#846804',
  },
};

export type Color = typeof colors;
export type ColorNames = `${keyof Color}-${keyof Color[keyof Color]}`;

export const colorNames = Object.keys(colors).reduce((acc, color) => {
  const colorShades = Object.keys(colors[color])
    .map((shade) => (shade !== 'DEFAULT' ? `${color}-${shade}` : null))
    .filter((v) => v) as ColorNames[];
  acc.push(...colorShades);
  return acc;
}, [] as ColorNames[]);

export default colors;
