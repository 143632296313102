import { bookActions } from '@/actions';
import BundleCard from '@/components/elements/Card/BundleCard/BundleCard';
import { LinkButton } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import EmptyState from '@/components/templates/EmptyState';
import { SCREEN_NAME } from '@/constants/analytics';
import {
  BOOK_TIME_ACTION_ITEM,
  CONSUMED_BUNDLE_LABEL,
  EXPIRED_BUNDLE_LABEL,
  VALID_BUNDLE_LABEL,
} from '@/constants/bundle';
import { setBookingStartingPoint, trackMpEvent } from '@/helpers';
import { getBundleListContentProps } from '@/helpers/bundle';
import { _s } from '@/locale';
import { BundleTab } from '@/services';
import { ClientBundle } from '@/types/api/services/users/schema';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

const Bundle = ({ bundle, tab }: { bundle: ClientBundle; tab: BundleTab }) => {
  const dispatch = useDispatch();
  const bookWithBundlePathname = `/boka-tjanst/${bundle.place.about.slug}-${bundle.place.id}/${bundle.service.slug}-${bundle.service.serviceId}`;

  const handleUseBookingBundle = () => {
    setBookingStartingPoint('my_bundles');
    trackMpEvent(`${BOOK_TIME_ACTION_ITEM.identifier}_click`, { trigger_source: SCREEN_NAME.MY_BUNDLE_OVERVIEW });
    dispatch(bookActions.applyBundle(bundle));
  };

  const label = (() => {
    switch (tab) {
      case 'valid':
        return VALID_BUNDLE_LABEL;
      case 'expired':
        return EXPIRED_BUNDLE_LABEL;
      case 'used':
        return CONSUMED_BUNDLE_LABEL;
      default:
        const never: never = tab;
        console.error(`Unknown tab: ${never}`);
        return undefined;
    }
  })();

  return (
    <BundleCard
      {...getBundleListContentProps(bundle)}
      src={`/klippkortdetaljer/${bundle.id}`}
      label={label}
      {...(tab === 'valid' && {
        bottomSlot: (
          <LinkButton
            to={bookWithBundlePathname}
            variant="primary"
            block
            size="md"
            onClick={handleUseBookingBundle}
            label="Boka tid"
            leftIcon={<Icon variant="calendar-1" />}
          />
        ),
      })}
    />
  );
};

const BundlesList = ({
  selectedTab,
  bundles,
  hideEmptyStateBody,
}: {
  selectedTab: BundleTab;
  bundles: ClientBundle[];
  hideEmptyStateBody: boolean;
}) => {
  const baseTranslationKey = `components.modules.pages.klippkort.BundlesList.${selectedTab}`;
  const message = hideEmptyStateBody ? '' : _s(`${baseTranslationKey}.emptystate.body`);
  const title = _s(`${baseTranslationKey}.emptystate.title`);

  return (
    <div id={selectedTab} role="tabpanel" aria-labelledby={`${selectedTab}-tab`}>
      {bundles.length ? (
        <Fragment>
          {bundles.map((bundle) => (
            <div key={bundle.id} className="mb-md">
              <Bundle bundle={bundle} tab={selectedTab} />
            </div>
          ))}
        </Fragment>
      ) : (
        <EmptyState body={message} title={title} icon={<img src="/images/illustrations/bundles.svg" alt="" />} />
      )}
    </div>
  );
};

export default BundlesList;
