/* eslint jsx-a11y/anchor-is-valid: 0 */
import { searchActions } from '@/actions';
import { DropdownButton } from '@/components/elements/forms/DropdownButton';
import { Button } from '@/components/elements/forms/buttons';
import { CloseIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { Dropdown } from '@/components/modules/dropdown';
import { isServer, trackMpEvent } from '@/helpers';
import { __ } from '@/locale';
import React from 'react';
import { ModalContent, ModalDialog } from '../../modals';

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filterOpen: false };
  }

  toggleFilterOptions = (e) => {
    const { filterOpen } = this.state;
    if (!filterOpen) {
      trackMpEvent('view_more_filters_clicked', {
        screen_name: this.props.trackPage,
      });
    }
    this.setState({ filterOpen: !filterOpen });
  };

  hideFilterOptions = (e) => {
    this.setState({ filterOpen: false });
  };

  removeFilter = (e) => {
    const { dispatch, name } = this.props;
    dispatch(searchActions.removeParameter([name, 'page']));

    trackMpEvent('filter_removed', {
      screen_name: this.props.trackPage,
      search_filter: 'all',
    });
  };

  getFilterOptions() {
    if (isServer) {
      return;
    }
    const { title } = this.props;
    const { filterOpen } = this.state;

    return !this.props.isMobileView ? (
      filterOpen && (
        <Dropdown
          className={`
            left-[-0.05rem]
            top-7
            m-0
            min-w-[20rem] rounded-b-2xl rounded-t-none p-0
          `}
          allowClick={filterOpen}
          onClickOutside={this.toggleFilterOptions}
          children={<div className="pt-2">{this.getChildren()}</div>}
        />
      )
    ) : (
      <ModalDialog
        isOpen={this.state.filterOpen}
        appElement={document.getElementById('root')}
        showOverlay
        onRequestClose={this.hideFilterOptions}>
        <ModalContent size="md" stickToBottom>
          <div className="relative flex h-[50px] items-center justify-between pl-4 shadow">
            {title}
            <Button
              variant="link"
              onClick={this.hideFilterOptions}
              leftIcon={<CloseIcon className="text-black-900 h-3 w-3" />}
            />
          </div>
          <div className="py-4">{this.getChildren()}</div>
          <div className="flex justify-center pb-4">{this.showGetResultsButton()}</div>
        </ModalContent>
      </ModalDialog>
    );
  }

  showGetResultsButton() {
    return this.props.isMobileView && <Button onClick={this.hideFilterOptions}>{__('seeResults')}</Button>;
  }

  getChildren() {
    const { optionsTitle } = this.props;
    return (
      <div style={{ padding: 16 }}>
        {optionsTitle && <span className="title block">{optionsTitle}</span>}
        {this.getOptionsHtml()}
      </div>
    );
  }

  getCountBadge() {
    return 0;
  }

  isActive() {
    return false;
  }

  renderChips() {
    return null;
  }

  render() {
    const { title } = this.props;
    return (
      <li>
        <div className="flex flex-row gap-2">
          <DropdownButton
            toggleOpen={this.toggleFilterOptions}
            isActive={this.isActive()}
            icon={<Icon variant="settings" className="text-primary h-4 w-4" />}
            isToggleOpen={!this.props.isMobileView ? this.state.filterOpen : false}
            newDesign
            className="!min-w-[109px] pl-2"
            activeBadge={this.getCountBadge()}>
            <>
              <span className="whitespace-nowrap text-xs">{title}</span>
              {this.getFilterOptions()}
            </>
          </DropdownButton>
          {this.renderChips()}
        </div>
      </li>
    );
  }
}

export default Filter;
