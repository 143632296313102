import { Card, CardHeader } from '@/components/elements/cards';
import { __ } from '@/locale';
import { Link } from 'react-router-dom';

import css from './ShippingSummary.module.scss';

/**
 * @typedef {Object} ShippingSummaryCardProps
 * @prop {import('@/services/giftCardServices').Shipping} shipping
 * @prop {import('../Greeting/Greeting').Greeting}
 *
 * @param {ShippingSummaryCardProps} param0
 * @returns
 */
const ShippingSummaryCard = ({ shipping, greeting, ssn, isWellness }) => {
  const hasShipping = Object.keys(shipping).length > 0;
  const hasGreeting = Object.keys(greeting).length > 0;

  const cardContent = (
    <>
      <h2 className="mb-6 flex justify-between text-xl font-semibold">
        <span>{shipping.name}</span>
        <Link to={`/giftcards/buy${isWellness ? '?ugctype=wellness' : ''}`}>Ändra</Link>
      </h2>
      {hasShipping && (
        <p>
          <>
            {shipping.address}, {shipping.zipcode} {shipping.city} <br />{' '}
          </>
        </p>
      )}
      {ssn && (
        <p>
          {__('buyGiftcard.wellness.ssnSummary.prefix')}: {ssn}
        </p>
      )}
      {hasGreeting && (
        <>
          <CardHeader>{__('buyGiftcard.universal.greetingSummary.title')}</CardHeader>
          <div className={css.card}>
            <h3>{greeting.title}</h3>
            <div>{greeting.message}</div>
            <div>{greeting.giver}</div>
          </div>
        </>
      )}
    </>
  );

  return hasShipping || hasGreeting || ssn ? (
    <Card className={css.container} noShadow>
      {cardContent}
    </Card>
  ) : (
    <div className={css.container}>{cardContent}</div>
  );
};

export default ShippingSummaryCard;
