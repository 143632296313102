import CardWrapper from '@/components/modules/CardWrapper';
import useMobileView from '@/hooks/useMobileView';

type Props = {
  title: string;
  subTitles: string[];
  bulletPoints: string[];
  middleSlot?: React.ReactNode;
  imgSrc: { mobile: string; desktop: string; desktop2xl: string };
};

const ProductInfo = ({ title, subTitles, bulletPoints, middleSlot, imgSrc }: Props) => {
  const { isMobileView } = useMobileView();
  return (
    <>
      <style>{`
      .bullet-point::before {
        content: '•';
        font-size: 16px;
        position: absolute;
        left: 0;
        padding-left: 9px;
        padding-right: 9px;
      }
    `}</style>
      <CardWrapper className="w-full">
        <div className={`flex flex-col ${isMobileView ? 'p-lg' : 'px-lg pt-md'}`}>
          <picture>
            <source srcSet={imgSrc.desktop2xl} media="(min-width: 1900px)" />
            <source srcSet={imgSrc.desktop} media="(min-width: 768px)" />
            <img src={imgSrc.mobile} className="h-auto w-full bg-[#ddd2e1] object-cover" alt="" />
          </picture>
          <div className="text-black-700">
            <div className="pt-md">{middleSlot}</div>
            <div className="py-lg gap-sm flex flex-col">
              <h2 className={`${isMobileView ? `text-lg` : `text-xl`} text-black-900`}>{title}</h2>
              {subTitles.map((subTitle, index) => (
                <p key={index} className="text-sm leading-normal">
                  {subTitle}
                </p>
              ))}
            </div>
            {bulletPoints && bulletPoints.length > 0 && (
              <ul className={`gap-md py-lg flex flex-col ${isMobileView ? 'text-xs' : 'text-sm'}`}>
                {bulletPoints.map((point, index) => (
                  <li key={index} className="bullet-point relative pl-7">
                    {point}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </CardWrapper>
    </>
  );
};

export default ProductInfo;
