import { isSistaminuten } from './general';

export const twTextPrimary = (important: boolean = false) =>
  isSistaminuten()
    ? important
      ? '!text-sm_primary'
      : 'text-sm_primary'
    : important
    ? '!text-primary'
    : 'text-primary';
export const twBgPrimary = (important: boolean = false) =>
  isSistaminuten() ? (important ? '!bg-sm_primary' : 'bg-sm_primary') : important ? '!bg-primary' : 'bg-primary';

export const twBorderPrimary = (important: boolean = false) =>
  isSistaminuten()
    ? important
      ? '!border-sm_primary'
      : 'border-sm_primary'
    : important
    ? '!border-primary'
    : 'border-primary';

export const themed = (bokadirektClasses, sistaminutenClasses) =>
  isSistaminuten() ? sistaminutenClasses : bokadirektClasses;
