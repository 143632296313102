import { Card, cardHeaderClass } from '@/components/elements/cards';
import BorderLabelTextInput from '@/components/elements/forms/BorderLabelTextInput';
import { Button, LinkButton } from '@/components/elements/forms/buttons';
import { RepeatIcon } from '@/components/icons';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import {
  getHoursInterval,
  getPlaceTimezone,
  isServer,
  isSistaminuten,
  makeReadableDay,
  trackMpEvent,
  trackPage,
  url,
} from '@/helpers';
import React from 'react';
import { Helmet } from 'react-helmet';
import { __ } from '../locale';
import { bookServices } from '../services';

class Cancel extends React.Component {
  constructor(props) {
    super(props);
    const { code } = url.getParamsFromGET();
    this.state = { cancel: code ?? '', error: null };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  seo() {
    const title = __('seo.cancel');
    const description = __('seo.cancelDescription');
    const imageUrl = url.getBaseImageUrl();
    const isNoIndex = isSistaminuten();
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta name="robots" content={isNoIndex ? 'noindex' : 'index, follow'} />
      </Helmet>
    );
  }

  handleChange(value) {
    this.setState({ cancel: value });
  }

  componentDidMount() {
    if (!isServer) {
      window.scrollTo(0, 0);
    }
    trackPage();
    // trackMpEvent('screen_shown', { screen_name: 'cancel_booking' });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true, success: false });
    const { cancel } = this.state;
    if (cancel && cancel.search(/[^a-zA-Z2-9\s]/g) === -1) {
      bookServices.cancelByCode(cancel.trim().toUpperCase()).then((response) => {
        if (response.error) {
          let error = null;

          switch (response.error) {
            case 'invalid':
              error = __('noBookingError');
              break;
            case 'time_passed':
              error = __('cannotCancelOnline');
              break;
            case 'erp_bad_response':
              error = __('serverError');
              break;
            default:
              error = __('canceledError');
              break;
          }

          this.setState({ success: false, error: error });
        } else {
          const { booking } = response || {};
          trackMpEvent('booking_canceled', {
            screen_name: 'cancel_booking',
            company_id: booking.place.id,
            booking_id: booking.id,
            number_of_services: booking.services.length,
          });
          this.setState({ submitted: false, error: undefined, success: true, cancel: '', booking: booking });
        }
      });
    } else {
      this.setState({ success: false, error: __('completeBookingCode') });
    }
  }

  getSuccessHtml() {
    const { booking } = this.state;
    const { place } = booking;

    const timezone = getPlaceTimezone(place);
    const appointmentDate =
      makeReadableDay(booking.start * 1000, timezone) + ', ' + getHoursInterval(booking.start * 1000, 0, timezone);
    const salonName = place.about.name;
    const salonUrl = url.getBaseUrl() + 'places/' + place.about.slug + '-' + place.id;
    const { service } = booking.services[0];
    const serviceName = service.name;
    const serviceUrl =
      '/boka-tjanst/' + place.about.slug + '-' + place.id + '/' + service.about.slug + '-' + service.id;

    return (
      <div className="container flex flex-col justify-center ">
        <img src="images/cancel-success-background.svg" alt="" />
        <h1 className="mb-2 text-2xl font-semibold">{__('cancelBookingSuccessHeader')}</h1>
        <p className="text-truncate mb-1 font-semibold">{serviceName}</p>
        <p className="cancel-salon-name">{salonName}</p>
        <p className="text-black-600 mb-1">{appointmentDate}</p>
        <div className="mt-6 flex flex-col gap-2">
          <Button
            block
            variant="primary"
            onClick={() => this.props.history.replace(serviceUrl)}
            size="sm"
            icon={<RepeatIcon />}>
            {__('bookAgain')}
          </Button>
          <LinkButton variant="primary" to={salonUrl} size="sm">
            {__('makeNewBooking')}
          </LinkButton>
        </div>
      </div>
    );
  }

  render() {
    const { cancel, submitted, error, success } = this.state;
    const successHtml = !success ? null : this.getSuccessHtml();

    return (
      <PageViewLayout type="mainView">
        <div className="container">
          <Card className="mx-auto max-w-screen-sm">
            {success ? (
              successHtml
            ) : (
              <>
                <h1 className={cardHeaderClass}>{__('cancelBook')}</h1>
                <div className="text-black-600">{__('cancelAdvice')}</div>
                <br />
                <form onSubmit={this.handleSubmit}>
                  <div className={`form-group ${submitted && !cancel ? 'has-error' : ''}`}>
                    <BorderLabelTextInput
                      name={'cancel'}
                      value={cancel}
                      setValue={this.handleChange}
                      error={error}
                      label={__('cancelCode')}
                    />
                  </div>
                  <div>
                    <Button variant="primary" size="sm">
                      {__('cancel')}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Card>
        </div>
        {this.seo()}
      </PageViewLayout>
    );
  }
}

export default Cancel;
