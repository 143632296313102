import { loadingConstants } from '../constants';

type LoadingState = {
  show: boolean;
  loadSource?: string;
  loadingBarHidden?: boolean;
};

type LoadingAction =
  | {
      type: typeof loadingConstants.SHOW;
      payload?: string;
    }
  | {
      type: typeof loadingConstants.HIDE;
    };

const initialState: LoadingState = { show: false, loadSource: undefined, loadingBarHidden: true };

export function loading(state = initialState, action: LoadingAction): LoadingState {
  switch (action.type) {
    case loadingConstants.SHOW:
      return { show: true, loadingBarHidden: false, loadSource: action.payload };
    case loadingConstants.HIDE:
      return { show: false, loadSource: undefined };
    default:
      return state;
  }
}
