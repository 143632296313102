import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { config } from '../config';
import { allowTracking, isServer, isSistaminuten, loggedUser } from './';
export {
  allowMarketing,
  amplitudeLogEvent,
  amplitudeLogRevenue,
  analytics4TrackRevenue,
  facebookTrackRevenue,
  fetchUserOnce,
  getBookingInitiator,
  getBookingStartingPoint,
  getCustomerIOSiteId,
  getMainFbPixel,
  getTikTokPixel,
  initTrackerCustomerIO,
  initTrackerFacebook,
  initTrackerTikTok,
  isMpBooking,
  salonTrackEvent,
  salonTrackEventPurchase,
  salonTracking,
  setBookingStartingPoint,
  trackEventTikTok,
  trackMpAnalyticsEvent,
  trackMpEvent,
  trackMpRevenueGiftcards,
  trackMpUserEvent,
  trackPage,
  trackPageCio,
};

let isAmplitudeInitialized = false;

export function initAmplitudeAnalytics() {
  if (!allowTracking()) {
    return;
  }

  if (!isSistaminuten() && !config.amplitudeAnalytics) {
    Sentry.captureMessage('Amplitude analytics config is missing!');
    return;
  }

  if (isSistaminuten() && !config.amplitudeAnalyticsSistaminuten) {
    Sentry.captureMessage('Amplitude analytics config is missing!');
    return;
  }

  const { cookieDomain, sistaMinutenCookieDomain, amplitudeAnalyticsSistaminuten, amplitudeAnalytics } = config;

  const amplitudeAnalyticsConfig = isSistaminuten() ? amplitudeAnalyticsSistaminuten : amplitudeAnalytics;

  amplitude
    .init(amplitudeAnalyticsConfig, null, {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
      defaultTracking: false,
      cookieOptions: {
        domain: isSistaminuten() ? sistaMinutenCookieDomain : cookieDomain,
      },
    })
    .promise.then(() => {
      isAmplitudeInitialized = true;
    })
    .catch((error) => {
      Sentry.captureException(`Failed to init amplitude ${JSON.stringify(error)}`);
    });
}

function getMainFbPixel() {
  if (allowTracking() && window.fbq && config.env === 'production') {
    if (isSistaminuten()) {
      return config.lastMinuteFacebookPixel;
    } else {
      return config.facebookPixel;
    }
  }

  return null;
}

function getTikTokPixel() {
  if (allowTracking() && window.ttq && config.env === 'production') {
    if (isSistaminuten()) {
      return config.lastMinuteTikTokPixel;
    } else {
      return config.tikTokPixel;
    }
  }

  return null;
}

function getCustomerIOSiteId() {
  if (allowTracking() && window._cio) {
    return config.customerIOSiteId;
  }
  return null;
}

/**
 * [trackAnalyticsEvent Track ga4 event with gtag]
 * @param  {String} name - Name of the event
 * @param  {Object | undefined} data - Extra data sent along with the event
 */
function trackMpAnalyticsEvent(name, data) {
  if (!allowTracking()) return;
  window.gtag('event', name, {
    ...data,
    send_to: isSistaminuten() ? config.lastMinuteGa4 : config.ga4,
  });
}

/**
 * [trackPage  send pageview to mp fb pixel, mp tiktok pixel]
 * @return null
 */
function trackPage() {
  if (!allowTracking()) return;

  // facebook pixel
  if (getMainFbPixel()) {
    window.fbq('trackSingle', getMainFbPixel(), 'PageView');
  }

  if (window.ttq) {
    window.ttq.page();
  }
}

/**
 * [trackPageCio send pageview to customer.io]
 * @param  {String} page      [required, the path portion of a URL. This value should start with a slash (/) character]
 * @param  {Object} params   [optional, extra properties related to the pageview]
 * @return null
 */
function trackPageCio(page, params) {
  if (allowTracking() && window._cio) {
    if (!params) {
      params = {};
    }
    params.pageview = true;
    params.platform_source = 'web';
    params.is_sistaminutentider = isSistaminuten();
    window._cio.track(page, params);
  }
}

/**
 * [trackEventTikTok send event to tik tok tracker]
 * @param  {String} event    [required]
 * @param  {Object} params   [optional, extra properties related to the event]
 * @return null
 */
function trackEventTikTok(event, params) {
  if (allowTracking() && window.ttq) {
    if (!params) {
      params = {};
    }

    window.ttq.track(event, params);
  }
}

function amplitudeLogRevenue(params) {
  if (!allowTracking()) return;

  const revenue = params.value || params.hiddenValue;
  if (!revenue) return;
  amplitude.revenue(new amplitude.Revenue().setPrice(revenue).setQuantity(1)).promise.catch((error) => {
    Sentry.captureException(error);
  });
}

/**
 * [analytics4TrackRevenue Track ga4 purchase event with gtag]
 * @param  {Object | undefined} data - Extra data sent along with the event purchase
 */
function analytics4TrackRevenue(params) {
  if (!allowTracking()) return;

  window.gtag('event', 'purchase', {
    transaction_id: params.orderId,
    affiliation: params.place.id,
    value: params.value || params.hiddenValue || 0,
    currency: params.currency,
    items: params.items.map((item) => {
      return {
        item_id: item.id,
        item_name: item.name,
        price: item.price,
        quantity: item.quantity,
        currency: params.currency,
      };
    }),
    send_to: isSistaminuten() ? config.lastMinuteGa4 : config.ga4,
  });
}

function facebookTrackRevenue(params) {
  if (getMainFbPixel()) {
    window.fbq(
      'trackSingle',
      getMainFbPixel(),
      'Purchase',
      {
        value: params.value || params.hiddenValue || 0,
        currency: params.currency,
        content_name: 'Booking',
      },
      { eventID: params.orderId },
    );
  }
}

function trackMpRevenueGiftcards(params) {
  if (!allowTracking()) return;

  facebookTrackRevenueGiftcards(params);
}

function facebookTrackRevenueGiftcards(params) {
  if (getMainFbPixel()) {
    window.fbq(
      'trackSingle',
      getMainFbPixel(),
      'Purchase',
      {
        value: params.value,
        currency: params.currency,
        content_name: params.contentName,
      },
      { eventID: params.orderId },
    );
  }
}

function amplitudeLogEvent(eventType, eventProperties) {
  amplitude.track(eventType, eventProperties).promise.catch((error) => {
    Sentry.captureMessage(
      `Failed to track amplitude event - isAmplitudeInitialized=${isAmplitudeInitialized} ${JSON.stringify({
        eventType,
        eventProperties,
      })}`,
    );
    Sentry.captureException(error);
  });
}

/**
 * [trackMpEvent description]
 * @param  String event        [required] the name of the event
 * @param  Object properties   [optional] extra details for the event
 * @param  Boolean sendToCio   [optional] also send event to cio, default true
 * @return null
 */
function trackMpEvent(event, properties, sendToCio = true) {
  if (!allowTracking()) return;

  // send event to amplitude
  amplitudeLogEvent(event, { ...properties });

  if (!window.dataLayer) return;

  // send pageview to customer.io
  if (event === 'screen_shown' && sendToCio) {
    const params = { ...properties };
    const screenName = params.screen_name;
    delete params.screen_name;
    trackPageCio(screenName, params);
  }
}

function getUserProperties(user) {
  const usrProps = { web_logged_in: 'yes' };

  if (user.about?.signUpDate) {
    usrProps['sign_up_date'] = user.about.signUpDate;
  }

  usrProps['given_name'] = !!user.about?.givenName;
  usrProps['family_name'] = !!user.about?.familyName;
  usrProps['dob'] = !!user.about?.dob;
  usrProps['gender'] = !!user.about?.gender;
  usrProps['mobile'] = !!user.contact?.mobile;
  usrProps['street_address'] = !!user.contact?.streetAddress;
  usrProps['postal_code'] = !!user.contact?.postalCode;
  usrProps['city'] = !!user.contact?.locality;

  return usrProps;
}

let fetchedUser = false;
function fetchUserOnce() {
  if (!fetchedUser && loggedUser()) {
    fetchedUser = true;
    return true;
  }

  if (!fetchedUser) {
    //user is not logged in
    trackMpUserEvent();
  }
  return false;
}

function trackMpUserEvent(user) {
  if (!allowTracking()) return;

  if (user && user.id) {
    if (!user.emailHash) {
      return;
    }

    // amplitude identify
    amplitude.setUserId(user.emailHash);
    setAmplitudeUserProperties({ ...getUserProperties(user) });

    // customer.io identify
    if (user.contact?.email) {
      if (window._cio) {
        window._cio.identify({
          id: user.contact.email,
        });
      } else {
        setTimeout(() => {
          if (window._cio) {
            window._cio.identify({
              id: user.contact.email,
            });
          }
        }, 200);
      }
    }
  }
}

function setAmplitudeUserProperties(userProps) {
  let identify = new amplitude.Identify();
  for (let property in userProps) {
    if (Object.prototype.hasOwnProperty.call(userProps, property)) {
      identify.set(property, userProps[property]);
    }
  }
  amplitude.identify(identify).promise.catch((error) => {
    Sentry.captureException(error);
  });
}

function disableSalonAnalyticsTrackers(activeTrackers = []) {
  let disabledTrackers = (JSON.parse(sessionStorage.getItem('disabledTrk')) || []).filter(
    (tracker) => activeTrackers.indexOf(tracker) < 0,
  );
  disabledTrackers.forEach((tracker) => {
    window[`ga-disable-${tracker}`] = true;
  });
  if (activeTrackers) {
    disabledTrackers = disabledTrackers.concat(activeTrackers);
    sessionStorage.setItem('disabledTrk', JSON.stringify(disabledTrackers));
  }
}

function initTrackersAnalytics4(trackers) {
  disableSalonAnalyticsTrackers(trackers);

  trackers.forEach((id) => {
    window[`ga-disable-${id}`] = false;
  });

  trackers.forEach((id) => {
    window.gtag('config', id, {
      send_page_view: false,
    });
  });
}

function initTrackerFacebook(id) {
  if (window.fbq) {
    // Line to enable Manual Only mode.
    window.fbq('set', 'autoConfig', false, id);
    window.fbq('init', id);
    // this is for fb pixel to not track browser history changes
    // we will do it manually so no events are sent to all pixels
    // since there is no remove pixel functionality
    window.fbq.disablePushState = true;
  }
}

function initTrackerTikTok(id) {
  if (window.ttq) {
    window.ttq.load(id);
  }
}

function initTrackerCustomerIO(id) {
  if (window._cio?.initialize) {
    window._cio.initialize(id);
  }
}

/* basically sends pageviews if analytics / facebook trackers are set for the place */
function salonTracking(place) {
  if (allowTracking() && config.env === 'production') {
    if (place && place.about) {
      if (place.about.googleAnalytics4Tracking && place.about.googleAnalytics4Tracking.length > 0) {
        initTrackersAnalytics4(place.about.googleAnalytics4Tracking);
        window.gtag('event', 'page_view', {
          page_location: window.location.href,
          page_title: document.title,
          send_to: place.about.googleAnalytics4Tracking,
        });
      }

      if (place.about.facebookPixelTracking && place.about.facebookPixelTracking.length > 0) {
        place.about.facebookPixelTracking.forEach((pixel) => {
          if (window.fbq) {
            initTrackerFacebook(pixel);
            window.fbq('trackSingle', pixel, 'PageView');
          }
        });
      }
    }
  }
}

function salonTrackEventPurchase(params) {
  if (allowTracking() && config.env === 'production') {
    const { place, items, value, hiddenValue } = params;
    const revenue = Number(value || hiddenValue || 0);
    if (place && place.about) {
      if (place.about.googleAnalytics4Tracking && place.about.googleAnalytics4Tracking.length > 0) {
        initTrackersAnalytics4(place.about.googleAnalytics4Tracking);

        window.gtag('event', 'page_view', {
          page_location: window.location.href,
          page_title: document.title,
          send_to: place.about.googleAnalytics4Tracking,
        });

        window.gtag('event', 'Book', {
          event_category: 'Order',
          value: revenue,
          send_to: place.about.googleAnalytics4Tracking,
        });

        window.gtag('event', 'purchase', {
          transaction_id: params.orderId,
          affiliation: place.id,
          value: revenue,
          currency: params.currency,
          items: items.map((item) => {
            return {
              item_id: item.id,
              item_name: item.name,
              price: item.price,
              quantity: item.quantity,
              currency: params.currency,
            };
          }),
          send_to: place.about.googleAnalytics4Tracking,
        });

        if (place.about.googleAnalytics4Tracking.indexOf('AW-1033282580') !== -1) {
          window.gtag('event', 'conversion', {
            send_to: revenue ? 'AW-1033282580/2DpDCJ7nzOAZEJTI2uwD' : 'AW-1033282580/ZIQ4CKXB4IUZEJTI2uwD',
            value: revenue,
            currency: params.currency,
          });
        }

        if (place.about.googleAnalytics4Tracking.indexOf('AW-482327819') !== -1) {
          window.gtag('event', 'conversion', {
            send_to: revenue ? 'AW-482327819/DJ-lCJjqrOgZEIv6_uUB' : 'AW-482327819/IMSQCKKRs4sZEIv6_uUB',
            value: revenue,
            currency: params.currency,
          });
        }
      } else {
        disableSalonAnalyticsTrackers();
      }

      if (window.fbq && place.about.facebookPixelTracking && place.about.facebookPixelTracking.length > 0) {
        place.about.facebookPixelTracking.forEach((pixel) => {
          initTrackerFacebook(pixel);
          window.fbq('trackSingle', pixel, 'PageView');
          window.fbq('trackSingle', pixel, 'Purchase', {
            value: revenue,
            currency: params.currency,
            order_id: params.orderId,
            num_items: params.numItems,
            content_ids: params.contentIds,
            content_type: params.contentType,
            content_name: params.contentName,
            description: params.description,
          });
        });
      }
    }
  }
}

function salonTrackEvent(place, event, data) {
  if (allowTracking() && config.env === 'production') {
    if (place && place.about) {
      if (window.fbq && place.about.facebookPixelTracking && place.about.facebookPixelTracking.length > 0) {
        place.about.facebookPixelTracking.forEach((pixel) => {
          initTrackerFacebook(pixel);
          if (event.custom) {
            window.fbq('trackSingleCustom', pixel, event.action, data);
          } else {
            window.fbq('trackSingle', pixel, event.action, data);
          }
        });
      }

      if (place.about.googleAnalytics4Tracking && place.about.googleAnalytics4Tracking.length > 0) {
        initTrackersAnalytics4(place.about.googleAnalytics4Tracking);

        window.gtag('event', event.action, {
          ...data,
          send_to: place.about.googleAnalytics4Tracking,
        });
      }
    }
  }
}

function setBookingStartingPoint(value, key) {
  if (!isServer) {
    sessionStorage.setItem('bookSP', value);
    if (value === 'search_results') {
      sessionStorage.setItem('searchedKeyword', key);
    } else {
      sessionStorage.removeItem('searchedKeyword');
    }
  }
}

function getBookingStartingPoint() {
  if (isServer) return '';
  return sessionStorage.getItem('bookSP') || '';
}

function getSearchedKeyword() {
  if (isServer) return '';
  return sessionStorage.getItem('searchedKeyword') || '';
}

// List of MP booking sources
const MP_BOOKING_SOURCES = {
  favorites: 'favorites',
  home_recently_viewed: 'home_recently_viewed',
  my_bookings_book_again: 'previous_booking',
  my_bookings_change_booking_time: 'previous_booking',
  search_results: 'search_results',
};

// List of other known booking sources
const OTHER_BOOKING_SOURCES = {
  company_details: 'company_details',
  booking_step_choose_time: 'booking_checkout',
};

function isMpBooking() {
  // List of MP booking sources
  const mpList = Object.keys(MP_BOOKING_SOURCES);

  // Get booking starting point
  const bookingStartingPoint = getBookingStartingPoint();

  const serpKey = getSearchedKeyword();

  // Clear session
  sessionStorage.removeItem('searchedKeyword');

  // Return searched keyword (will be checked in backend for match in product keywords) / true / false
  return mpList.indexOf(bookingStartingPoint) !== -1 ? serpKey || true : false;
}

function getBookingInitiator() {
  // Get booking starting point
  const bookingStartingPoint = getBookingStartingPoint();

  // List of known booking sources
  const serpList = { ...OTHER_BOOKING_SOURCES, ...MP_BOOKING_SOURCES };

  // Get booking starting point value for ERP | null if not in list
  const initiator = serpList[bookingStartingPoint] || null;

  return {
    initiator,
    searchKeyword: null,
  };
}

function allowMarketing(services) {
  const allowMarketing = (services || []).every(
    (item) => !item?.about?.settings?.hasOwnProperty('allowMarketing') || item?.about?.settings?.allowMarketing,
  );
  return allowMarketing;
}
