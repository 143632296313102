import { bookActions } from '@/actions';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { promiseWrapper } from '@/helpers';
import { saveBookingTrackingProps } from '@/helpers/checkout';
import { fetchPlace, getEmployee, getServices } from '@/helpers/placeHelperTS';
import { _s } from '@/locale';
import { baseTranslationKey } from '@/pages/validate-swish-payment-redirect/ValidateSwishPaymentRedirect';
import { swishServices } from '@/services/swishServices';
import { SwishPaymentBookingResponse } from '@/types/api/services/booking';
import { keepAliveNotificationSchema, swishNotificationSchema } from '@/types/api/services/swish';
import { SelectedPaymentMethod } from '@/types/checkout';
import { bookDateSchema, bookStateSchema } from '@/types/state/book';
import * as Sentry from '@sentry/react';
import { Buffer } from 'buffer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import {
  BookingCheckoutState,
  CHECKOUT_STATE_LOCALSTORAGE_KEY,
  SubmitBookingContext,
  SwishSuccessRedirectData,
  bookingCheckoutStateSchema,
} from '../BookingCheckout.hooks';

const SERVER_ERROR = { message: 'Could not get status of the swish payment', status: 500 };
const FAILED_PAYMENT_ERROR = {
  message: 'The swish payment was not successful',
  status: 400,
  clientError: _s(`${baseTranslationKey}.failedBookingError`),
};

const timeBookStateRequestSchema = bookDateSchema.extend({
  employees: z.array(z.number()),
});

const bookStateRequestSchema = bookStateSchema
  .pick({
    employee: true,
    time: true,
    campaigns: true,
    place: true,
    services: true,
  })
  .extend({
    services: z.array(z.number()),
    time: timeBookStateRequestSchema,
    place: z.object({
      id: z.number(),
      slug: z.string(),
    }),
  });

type SubmitSwishRedirectContext = SubmitBookingContext & {
  savedCheckoutState: BookingCheckoutState;
  payment: SwishPaymentBookingResponse;
  eventSource: EventSource;
};

export type SavedBookingCheckoutState = Omit<
  SubmitSwishRedirectContext,
  'bookState' | 'successCallback' | 'errorCallback' | 'eventSource'
>;

type SwishSuccessRedirect = {
  pathname: '/booking/confirmation';
  search?: string;
  state: {
    selectedPaymentMethod?: SelectedPaymentMethod;
    savedCheckoutState?: BookingCheckoutState;
  };
};

type SwishErrorRedirect = {
  pathname: string;
  search?: string;
  state: {
    savedCheckoutState?: BookingCheckoutState;
    selectedPaymentMethod?: SelectedPaymentMethod;
  };
};

function getSavedCheckoutStateFromLocalStorage(): SavedBookingCheckoutState | null {
  const savedCheckoutState: SavedBookingCheckoutState = JSON.parse(
    localStorage.getItem(CHECKOUT_STATE_LOCALSTORAGE_KEY),
  );
  savedCheckoutState && localStorage.removeItem(CHECKOUT_STATE_LOCALSTORAGE_KEY);

  return savedCheckoutState;
}

/**
 * If we are redirected from swish we need to check if the payment was successful or not and redirect
 * the user accordingly. The actual booking has already been submitted and will be completed or cancelled
 * in the backend.
 */
async function handleValidateSwishPayment(context: SubmitSwishRedirectContext) {
  const { payment, bookingTrackingProps, eventSource } = context;
  if (eventSource) {
    eventSource.onmessage = (e) => {
      const swishNotification = JSON.parse(e.data);
      const validatedKeepAliveNotification = keepAliveNotificationSchema.safeParse(swishNotification);

      if (validatedKeepAliveNotification.success === true && validatedKeepAliveNotification.data.keepAlive) {
        return;
      }

      const validatedSwishNotification = swishNotificationSchema.safeParse(swishNotification);

      if (validatedSwishNotification.success === false) {
        context.errorCallback(SERVER_ERROR);
        eventSource.close();
        return;
      }

      const { success } = validatedSwishNotification.data;

      if (!success) {
        context.errorCallback(FAILED_PAYMENT_ERROR);
      } else {
        saveBookingTrackingProps(bookingTrackingProps);
        context.successCallback({
          paymentMethod: CHECKOUT_PAYMENT_METHOD.SWISH,
          responseData: { ...payment },
        });
      }

      eventSource.close();
    };

    eventSource.onerror = () => {
      context.errorCallback(SERVER_ERROR);
      eventSource.close();
    };
  }
}

const useValidateSwishRedirect = () => {
  const [checkoutState] = useState<SavedBookingCheckoutState | null>(getSavedCheckoutStateFromLocalStorage());
  const [redirect, setRedirect] = useState<SwishSuccessRedirect | SwishErrorRedirect | false>(false);
  const [isNewBrowser, setIsNewBrowser] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const queryData = new URLSearchParams(location.search).get('data');
  const bookStateRequestData = JSON.parse(Buffer.from(queryData, 'base64').toString());
  const { bookingRequestBody, bookingTrackingProps, payment, savedCheckoutState } = checkoutState || {};

  useEffect(() => {
    const validatedStateData = bookStateRequestSchema.safeParse(bookStateRequestData);

    if (validatedStateData.success === false) {
      Sentry.captureException(validatedStateData.error);
    }

    const { place, ...bookState } = bookStateRequestData;

    const controller = new AbortController();
    const { signal } = controller;

    const restoreBookState = async () => {
      const { data, error } = await promiseWrapper(fetchPlace({ abortSignal: signal, id: place.id, slug: place.slug }));
      const fetchedPlace = data;

      if (error || !fetchedPlace.id || !fetchedPlace.about?.slug) {
        Sentry.captureMessage('Place could not be fetched');
      }

      dispatch(bookActions.addPlace(fetchedPlace));

      const { services, time, ...restOfBookState } = bookState;

      // Rebuild services from serviceIds
      const rebuiltServices = getServices({ serviceIds: services, place: fetchedPlace });

      // Rebuild time with employee from employeeIds
      const { employees, ...restOfTime } = time;
      const rebuiltTimeEmployee = getEmployee({ employeeId: employees[0], place: fetchedPlace });
      const rebuiltTime = { ...restOfTime, employees: [rebuiltTimeEmployee] };

      const rebuiltBookState = { ...restOfBookState, services: rebuiltServices, time: rebuiltTime };

      dispatch(bookActions.restoreBookState(rebuiltBookState));
    };

    const successCallback = ({ responseData, paymentMethod }: SwishSuccessRedirectData) => {
      const selectedPaymentMethod = { type: paymentMethod };
      setRedirect({
        pathname: '/booking/confirmation',
        state: { selectedPaymentMethod },
        search: `?bookingId=${responseData.bookingId}&type=swish`,
      });
    };

    const errorCallback = async ({ status, clientError }: { status: number; clientError?: string }) => {
      await restoreBookState();

      const validatedCheckoutState = bookingCheckoutStateSchema.safeParse(savedCheckoutState);

      // This means we have dont know the status of the booking or payment
      if (status > 500) {
        toast(({ closeToast }) => (
          <Snackbar label={_s(`${baseTranslationKey}.unknownError`)} type="danger" onClose={closeToast} />
        ));
        setRedirect({
          pathname: '/booking/checkout',
          search: '',
          state: {},
        });
        return;
      }

      if (validatedCheckoutState.success === false) {
        setIsNewBrowser(true);
        return;
      }

      toast(({ closeToast }) => <Snackbar label={clientError} type="danger" onClose={closeToast} />);
      setRedirect({
        pathname: '/booking/checkout',
        search: '',
        state: { savedCheckoutState: { ...validatedCheckoutState.data, swish: null } },
      });
    };

    if (!payment?.channel) {
      errorCallback(SERVER_ERROR);
      return;
    }

    const context: SubmitSwishRedirectContext = {
      bookingRequestBody,
      bookingTrackingProps,
      payment,
      savedCheckoutState,
      errorCallback,
      successCallback,
      eventSource: swishServices.waitForSwishNotification(payment.channel),
    };

    handleValidateSwishPayment(context);

    return () => {
      controller.abort();
      context.eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { redirect, isNewBrowser };
};

export default useValidateSwishRedirect;
