import { Fab } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { Z_INDEX } from '@/constants/styleConstants';
import { classnames, isServer } from '@/helpers';
import { getSvgPath } from 'figma-squircle';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import * as ReactModal from 'react-modal';
import css from './Modal.module.scss';

const Header = ({ title, onClose, onBack }: { title: string; onClose?: (e) => void; onBack?: () => void }) => {
  return (
    <div className={classnames(css.header, 'gap-lg flex items-center')}>
      <div className="gap-lg flex flex-1 items-center">
        {onBack && (
          <Fab
            className="shrink-0 grow-0"
            onClick={onBack}
            icon={<Icon variant="arrow-left" />}
            floating={false}
            size="md"
          />
        )}
        <h2 className="text-lg font-bold">{title}</h2>
      </div>
      {onClose && <Fab onClick={onClose} icon={<Icon variant="close" />} size="md" floating={false} />}
    </div>
  );
};

const Footer = ({ atBottom = false, children }: { atBottom?: boolean; children?: ReactNode }) => {
  return (
    <div className={classnames(css.footer, atBottom && 'pb-lg mt-auto')}>
      <div className="gap-md flex justify-end">{children}</div>
    </div>
  );
};

const FLOATING_CONTENT_PADDING_PX = 18;

const Content = ({
  children,
  floating,
  style,
  contentPadding = true,
  gradientBackground,
  contentMaxWidth,
}: {
  children: ReactNode;
  floating: boolean;
  style?: React.CSSProperties;
  contentPadding?: boolean;
  gradientBackground?: boolean;
  contentMaxWidth?: number;
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const modalContentWrapperRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);
  const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});

  const handleResize = useCallback(() => {
    if (!modalContentRef.current || !modalContentWrapperRef.current || !floating) return;
    const { height } = modalContentRef.current.getBoundingClientRect();
    const { width } = modalContentWrapperRef.current.getBoundingClientRect();
    setContentStyle(getModalStyle({ width: width - FLOATING_CONTENT_PADDING_PX * 2, height }));
  }, [floating]);

  useEffect(handleResize, [mounted]);

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (floating) {
      interval = setInterval(handleResize, 100);
    }
    return () => interval && clearInterval(interval);
  }, [floating]);

  useEffect(() => setMounted(true), []);

  const wrapperDefaultStyle = {
    ...(contentMaxWidth && { maxWidth: contentMaxWidth }),
    ...(floating && { padding: FLOATING_CONTENT_PADDING_PX }),
  } as React.CSSProperties;

  return (
    <div
      ref={modalContentWrapperRef}
      className={classnames(
        css.modal,
        floating && css.floating,
        'outline-none',
        floating ? 'max-h-screen w-full max-w-[400px] flex-1 sm:p-0' : 'h-full w-full max-w-full',
      )}
      style={wrapperDefaultStyle}>
      <div
        ref={modalContentRef}
        style={{ ...(floating ? contentStyle : {}), ...style }}
        className={classnames(
          'gap-lg relative z-10 flex flex-col bg-white opacity-100',
          floating && contentPadding ? 'p-lg my-lg' : `${gradientBackground ? 'bg-gradient' : ''} pt-4xl min-h-full`,
        )}>
        {children}
      </div>
    </div>
  );
};

const getModalStyle = ({ width, height }: { width: number; height: number }) => {
  const path = getSvgPath({ width, height, cornerRadius: 18, cornerSmoothing: 1 });
  return { width, minHeight: 'auto', clipPath: `path('${path}')` };
};

export type ModalProps = {
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
  onRequestClose?: (e) => void;
  shouldCloseOnOverlayClick?: boolean;
  overlayClassName?: string;
  center?: boolean;
};

const Modal = ({
  isOpen,
  children,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
  overlayClassName,
  className = '',
  center = true,
}: ModalProps) => {
  return (
    <ReactModal
      {...{
        isOpen,
        onRequestClose,
        shouldCloseOnOverlayClick,
        appElement: isServer ? null : document.getElementById('root'),
      }}
      overlayClassName={
        overlayClassName ?? `fixed flex justify-center inset-0 outline-none bg-scrim-40 max-w-full overflow-y-scroll`
      }
      className={classnames('relative h-full w-full', center && 'flex items-center justify-center', className)}
      overlayElement={(props, contentElement) => {
        /**
         * we need to remove these classes from the overlay element since addblockers can block the modal
         * In this case the "shield" in Brave browser is blocking the modal because of these classes
         */
        const { className, ...rest } = props;
        const removeDefault = [
          'ReactModal__Overlay',
          'ReactModal__Overlay--after-open',
          'ReactModal__Overlay--before-close',
        ];
        const classes = className
          .split(' ')
          .filter((c) => !removeDefault.includes(c))
          .join(' ');

        return (
          <div {...rest} className={classes}>
            {contentElement}
          </div>
        );
      }}
      style={{
        overlay: {
          zIndex: Z_INDEX.MODAL,
        },
      }}>
      {children}
    </ReactModal>
  );
};

Modal.Header = Header;
Modal.Footer = Footer;
Modal.Content = Content;

export default Modal;
