import { isAndroidPhone, isIPhone } from '@/helpers';
import { _s } from '@/locale';
import { StarRating } from '../rating';

type AppBannerProps = {
  salonName: string;
  url: string;
  handleBannerClick: () => void;
  hideBanner: () => void;
};

const AppBanner = ({ salonName, url, handleBannerClick, hideBanner }: AppBannerProps) => (
  <div className="bg-black-900 absolute right-[6px] top-0 z-10 mt-16 w-[256px] rounded-lg p-4 text-white">
    <div className="relative">
      <span className="bg-black-900 absolute -top-[17px] right-[6px] h-3 w-3 rotate-45 transform"></span>
      <p className="mb-3 text-sm">
        {_s('downloadAppFav1') + ' '}
        <span>{salonName}</span>
        {' ' + _s('downloadAppFav2')}
      </p>
      <div className="my-2 flex flex-col items-center justify-center">
        <a href={url} onClick={handleBannerClick}>
          {isIPhone() && <img src="/images/Bokadirekt-download-appstore-app.svg" alt={_s('availableAppStore')} />}
          {isAndroidPhone() && (
            <img src="/images/Bokadirekt-download-googleplaystore-app.svg" alt={_s('availablePlayStore')} />
          )}
        </a>
        <div className="flex items-center text-xs">
          <span className="mr-2">4.9</span> <StarRating rating={4.9} />
        </div>
      </div>
      <div className="flex justify-between">
        <span className=" text-black-400" onClick={hideBanner}>
          {_s('noThanks')}
        </span>
        <a href={url} className="!text-[#51A492]" onClick={handleBannerClick}>
          {_s('downloadAppFavBtn2')}
        </a>
      </div>
    </div>
  </div>
);

export default AppBanner;
