import Input from '@/components/elements/forms/Input/Input';
import { Button } from '@/components/elements/forms/buttons';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Icon from '@/components/icons/Icon';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { trackMpEvent } from '@/helpers';
import { LoginContextType } from '@/hooks/useLogin';
import { _s } from '@/locale';
import { LoginTriggerSource } from '@/types/analytics';
import { Link } from 'react-router-dom';

const translationKey = `RequestToken`;

type RequestTokenProps = Omit<LoginContextType, 'handleLogin' | 'updateView' | 'view' | 'user'> & {
  source: LoginTriggerSource;
  redirectUrl: string;
};

const RequestToken = ({ email, updateEmail, handleEmailSubmit, source, redirectUrl }: RequestTokenProps) => {
  const handleLoginClick = () => {
    trackMpEvent(EVENT_NAME.LOGIN_CONTINUE_CLICK, { screen_name: SCREEN_NAME.LOGIN_START });
    handleEmailSubmit({ triggerSource: source, redirectUrl });
  };
  return (
    <>
      <div className="py-xl gap-xl flex flex-col">
        <Input
          type="email"
          autoComplete="email"
          leftSlot={<Icon variant="email" />}
          title={_s(`${translationKey}.input.title`)}
          placeholder={_s(`${translationKey}.input.placeholder`)}
          value={email.value}
          onChange={(e) => updateEmail(e.target.value)}
          error={email.error && email.submitting ? _s(`${translationKey}.input.error`) : undefined}
          data-cy="login-email-input"
        />
        {email.isChecked && !email.isRegistered && (
          <Alert
            leftSlot={<Icon size="sm" variant="info-circle" />}
            variant="information"
            verticalAlign="top"
            rightSlot={
              <p>
                {_s(`${translationKey}.newEmailAccount.notFound`)} <strong>{email.value}</strong>{' '}
                {_s(`${translationKey}.newEmailAccount.continue`, { cta: _s(`${translationKey}.cta`) })}
              </p>
            }
          />
        )}
        <Button
          disabled={email.error || email.submitting}
          block
          variant="primary"
          size="md"
          onClick={handleLoginClick}
          data-cy="login-submit-button">
          {_s(`${translationKey}.cta`)}
        </Button>
        <p className="text-black-900 text-xs">
          {_s(`${translationKey}.terms.text`)}{' '}
          <Link to="/articles/terms-booking-consumer" target="_blank" className="text-black-900 underline">
            {_s(`${translationKey}.terms.link`)}
          </Link>
        </p>
      </div>
    </>
  );
};

export default RequestToken;
