import Icon from '@/components/icons/Icon';
import { EVENT_NAME, SCREEN_NAME } from '@/constants/analytics';
import { isAndroidPhone, isIPhone, isServer, isSistaminuten, trackMpEvent } from '@/helpers';
import useTrackScreenView from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../forms/buttons';
import { ListItem, ListItemContent } from '../lists';
import Rating from '../Rating/Rating';
import styles from './AppPromotionCard.module.scss';
import Card from './Card';

const baseTranslationKey = 'components.elements.Card.AppPromotionCard';
const LOCAL_STORAGE_BASE_KEY = 'appPromoDismissed';

const getLocalStorageKey = (triggerSource: string) => {
  return `${LOCAL_STORAGE_BASE_KEY}.${triggerSource}`;
};

const getTrackingTriggerSource = (pathname: string): (typeof SCREEN_NAME)[keyof typeof SCREEN_NAME] | undefined => {
  switch (pathname) {
    case '/bokningar/tidigare':
      return 'my_bookings_finished';
    case '/bokningar':
      return 'my_bookings_upcoming';
    case '/favoriter':
      return 'favorites';
    case '/places':
      return 'place_landing';
    case '/':
      return 'home';
    default:
      Sentry.captureMessage(`Unknown trigger source (${pathname}) for general app promotion banner`);
      return undefined;
  }
};

const pathToStringMapping = {
  '/bokningar/tidigare': 'https://bokadirekt.smart.link/ys4wswub6',
  '/bokningar': 'https://bokadirekt.smart.link/yt4fa1zcp',
  '/favoriter': 'https://bokadirekt.smart.link/k42mipmq1',
  '/places': `https://bokadirekt.smart.link/ms9t9h2rz`,
  '/': 'https://bokadirekt.smart.link/am8n1ue9v',
} as const;

const getPageSmartLink = (pathname: string) => {
  const matchedPath = Object.keys(pathToStringMapping).find((path) => pathname === path);
  return matchedPath ? pathToStringMapping[matchedPath] : 'https://bokadirekt.smart.link/am8n1ue9v';
};

const checkIfDismissedWithinTimeperiod = (days: number, triggerSource: string) => {
  const dismissedTimestampString = localStorage.getItem(getLocalStorageKey(triggerSource));
  const dismissedTimestamp = dismissedTimestampString ? Number(dismissedTimestampString) : null;
  const oneDayInMs = days * 24 * 60 * 60 * 1000;
  const currentTime = new Date().getTime();

  if (!dismissedTimestamp) {
    return false;
  }

  // Dismissed more than 1 day ago
  if (currentTime - dismissedTimestamp >= oneDayInMs) {
    localStorage.setItem(getLocalStorageKey(triggerSource), Date.now().toString());
    return false;
  }

  return true;
};

const isGCPage = (pathname: string) => {
  return /^\/places\/[^/]+\/(valuecards|giftcards)\/(buy|confirmation)$/.test(pathname);
};

const AppPromotionCard = () => {
  const location = useLocation();

  const pathname = (() => {
    if (location.pathname.startsWith('/places/') && !isGCPage(location.pathname)) {
      return '/places';
    }
    return location.pathname;
  })();

  const [hasDismissed, setHasDismissed] = useState(false);
  const isWhiteListedPage = Object.keys(pathToStringMapping).some((page) => page === pathname);

  const triggerSource = isWhiteListedPage ? getTrackingTriggerSource(pathname) : undefined;

  const hasDismissedEarlier = (() => {
    if (isServer) return true;
    /**
     * Check if user has already dismissed the AppPromotion during the session
     * - if dismissed during session, dont show any banner on any of the pages for the remaining session
     */
    if (Boolean(sessionStorage.getItem(LOCAL_STORAGE_BASE_KEY))) {
      return true;
    }

    /**
     * - if not dismissed during session, check if the page specific banner has been dismissed
     */
    return checkIfDismissedWithinTimeperiod(1, triggerSource);
  })();

  const [timerTriggered, setTimerTriggered] = useState(false);
  const showCard =
    !isServer &&
    isWhiteListedPage &&
    !isSistaminuten() &&
    (isIPhone() || isAndroidPhone()) &&
    !hasDismissedEarlier &&
    !hasDismissed &&
    timerTriggered;

  useTrackScreenView(
    {
      name: 'screen_view_new_download_app_banner',
      properties: {
        trigger_source: showCard ? triggerSource : undefined,
        banner_type: 'general_banner',
      },
    },
    [showCard],
    [showCard],
  );

  useEffect(() => {
    const setNewTimeout = () => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        setTimerTriggered(true);
      }, 2000);
    };

    let timer = setTimeout(() => {
      setTimerTriggered(true);
    }, 2000);

    setNewTimeout();

    return () => {
      clearTimeout(timer);
    };
  }, [location.pathname]);

  const handleDismiss = () => {
    setHasDismissed(true);
    localStorage.setItem(getLocalStorageKey(triggerSource), Date.now().toString());
    sessionStorage.setItem(LOCAL_STORAGE_BASE_KEY, 'dismissed');
  };

  const handleDownloadAppClick = () => {
    trackMpEvent(EVENT_NAME.NEW_DOWNLOAD_APP_BANNER_CLICK, {
      trigger_source: getTrackingTriggerSource(pathname),
      banner_type: 'general_banner',
    });
    localStorage.setItem(getLocalStorageKey(triggerSource), Date.now().toString());
    sessionStorage.setItem(LOCAL_STORAGE_BASE_KEY, 'dismissed');
    let url = getPageSmartLink(pathname);

    if (pathname === '/places') {
      url += `?deeplink=${window.location.href}`;
    }
    window.location.href = url;
  };

  return showCard ? (
    <>
      <div className={`z-10003 fixed h-screen w-screen bg-black opacity-50`}></div>
      <Card bottom closable={false} className={`z-10004 fixed bottom-0 ${styles.slideInBottom}`}>
        <div className="gap-lg flex flex-col">
          <div className="gap-xl flex min-w-full flex-col">
            <div className="gap-lg flex">
              <div>
                <div className="p-md rounded-md bg-[#23A994]">
                  <Icon variant="bokadirekt" size="md" color="white" />
                </div>
              </div>
              <div className="gap-sm flex flex-col">
                <h2 className="text-md font-bold">{_s(`${baseTranslationKey}.title`)}</h2>
                <Rating rating={4.9} size="lg" count={44365} />
              </div>
            </div>
            <ul className="gap-sm flex flex-col">
              <ListItem
                className="!py-[0]"
                leftPadding={false}
                leftSlot={<Icon color="brand-500" variant="checkbox-checked-round" size="sm" />}>
                <ListItemContent subTitle={_s(`${baseTranslationKey}.contentList.item1`)} />
              </ListItem>
              <ListItem
                className="!py-[0]"
                leftPadding={false}
                leftSlot={<Icon color="brand-500" variant="checkbox-checked-round" size="sm" />}>
                <ListItemContent subTitle={_s(`${baseTranslationKey}.contentList.item2`)} />
              </ListItem>
              <ListItem
                className="!py-[0]"
                leftPadding={false}
                leftSlot={<Icon color="brand-500" variant="checkbox-checked-round" size="sm" />}>
                <ListItemContent subTitle={_s(`${baseTranslationKey}.contentList.item3`)} />
              </ListItem>
            </ul>
          </div>
          <div className="gap-sm flex flex-col">
            <Button
              variant="primary"
              onClick={handleDownloadAppClick}
              block
              label={_s(`${baseTranslationKey}.cta`)}
              size="lg"
            />
            <Button variant="link" onClick={handleDismiss} block label={_s(`${baseTranslationKey}.link`)} size="sm" />
          </div>
        </div>
      </Card>
    </>
  ) : null;
};

export default AppPromotionCard;
