import { bundleActions } from '@/actions/bundleActions';
import { LinkButton } from '@/components/elements/forms/buttons';
import { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import { getBundleValidity, url } from '@/helpers';
import { themed } from '@/helpers/theme';
import { _s } from '@/locale';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BundleReadMore from './BundleReadMore';

export type PlaceBundleService = {
  mpId: number;
  name: string;
  quantity: number;
};

export type PlaceBundle = {
  id: number;
  name: string;
  description?: string;
  price: number;
  priceLabel: string;
  finalPrice: number;
  finalPriceLabel: string;
  validMonths?: number;
  durationLabel?: string;
  service: PlaceBundleService;
  employees: number[];
};

type BundleProps = {
  place: any;
  bundle: PlaceBundle;
  filterQuery?: string;
};

const Bundle = ({ bundle, place, filterQuery }: BundleProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  useEffect(() => {
    const selectedBundleId = parseInt(url.returnGetParameter('sb'));
    if (bundle.id === selectedBundleId) {
      setShowMoreInfo(true);
    }
  }, []);

  const handleOnCloseMoreInfo = () => {
    history.replace({ search: url.changeGetParameter('sb') });
  };

  const handleOnOpenMoreInfo = () => {
    history.replace({ search: url.changeGetParameter('sb', bundle.id) });
  };

  const handleBuyClick = () => {
    dispatch(bundleActions.pickBundleToCheckout({ bundle, place }));
  };

  const handleBuyBundleClick = () => {
    handleBuyClick();
    history.push('/bundles/checkout');
  };

  const highlightServiceName = () => {
    if (!filterQuery) {
      return <span className="service-name">{bundle.name}</span>;
    }

    return (
      <span className="service-name">
        <Highlighter
          highlightClassName="highlight"
          searchWords={filterQuery.split(' ')}
          autoEscape={true}
          textToHighlight={bundle.name}
        />
      </span>
    );
  };

  const getBuyButton = (block?: boolean, size?: ButtonProps['size']) => {
    return (
      <LinkButton block={block} size={size} variant="primary" to="/bundles/checkout" onClick={handleBuyClick}>
        {_s('buy')}
      </LinkButton>
    );
  };

  const getReadMoreButton = () => {
    return (
      <div className="fixed bottom-0 left-0 right-0 flex justify-center bg-white p-6 shadow md:left-auto md:right-auto md:w-[362px]">
        {getBuyButton(true, 'md')}
      </div>
    );
  };

  const getBundlePriceFormatted = () => {
    if (bundle.finalPrice && bundle.finalPrice !== bundle.price) {
      return (
        <span className={themed('text-information', 'text-green-sm')}>
          {`${bundle.finalPriceLabel} `}
          <s className="text-black-600">{`(${bundle.priceLabel})`}</s>
        </span>
      );
    }

    return <span>{bundle.priceLabel}</span>;
  };

  return (
    <li
      className={`!last-of-type:border-none hover:bg-black-50 !flex cursor-pointer items-start justify-between p-[10px]`}
      onClick={handleBuyBundleClick}>
      <div className="flex max-w-sm flex-col">
        {highlightServiceName()}
        <span className="my-1 text-xs">{`(${_s('buyBundles.quantity', { count: bundle.service.quantity })})`}</span>
        <div className="my-1 flex flex-col md:flex-row">
          <span className="text-black-600 text-sm">
            {bundle.durationLabel}
            {bundle.durationLabel ? ', ' : ''}
            {getBundlePriceFormatted()}
          </span>
        </div>
        {bundle.validMonths && (
          <span className="text-black-600 my-1 text-sm">{getBundleValidity(bundle.validMonths)}</span>
        )}
        {bundle.description && bundle?.employees?.length > 0 && (
          <BundleReadMore
            bundle={bundle}
            place={place}
            bundlePrice={getBundlePriceFormatted()}
            actionButton={getReadMoreButton()}
            showMoreInfo={showMoreInfo}
            handleOnOpenMoreInfo={handleOnOpenMoreInfo}
            handleOnCloseMoreInfo={handleOnCloseMoreInfo}
          />
        )}
      </div>
      {getBuyButton()}
    </li>
  );
};

export default Bundle;
