import { bookConstants } from '@/constants';
import { isNationalId, isZipcode } from '@/helpers';
import React from 'react';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isLength from 'validator/lib/isLength';

class Parent extends React.Component {
  constructor(props) {
    super(props);
    this.validate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && (this.props.data.value !== prevProps.data.value || this.props.revalidate)) {
      this.validate();
    }
  }

  validate() {
    const { data } = this.props;

    if (this.props.setError) {
      this.props.setError(data.id, undefined);
    }

    if (data && data.required && !(data.value && data.value.length && data.value.trim())) {
      return this.props.setError(data.id, bookConstants.ERROR_REQUIRED);
    }

    if (data && data.value) {
      switch (data.id) {
        case 'email':
          if (!isEmail(data.value.trim(), { allow_utf8_local_part: false })) {
            this.props.setError(data.id, bookConstants.ERROR_INVALID_EMAIL);
          } else {
            this.props.setError(data.id, undefined);
          }
          break;

        case 'mobile':
        case 'phone':
          if (!isMobilePhone(data.value.trim()) || !isLength(data.value.trim(), { min: 10, max: 14 })) {
            this.props.setError(data.id, bookConstants.ERROR_INVALID_PHONE);
          } else {
            this.props.setError(data.id, undefined);
          }
          break;

        case 'nationalId':
          if (!isNationalId(data.value.trim())) {
            this.props.setError(data.id, bookConstants.ERROR_INVALID_NATIONAL_ID);
          } else {
            this.props.setError(data.id, undefined);
          }
          break;
        case 'postalCode':
          if (!isZipcode(data.value.trim())) {
            this.props.setError(data.id, bookConstants.ERROR_INVALID_ZIPCODE);
          } else {
            this.props.setError(data.id, undefined);
          }
          break;
        default:
          break;
      }
    }
  }

  render() {
    return null;
  }
}

export default Parent;
