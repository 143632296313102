import { modalActions } from '@/actions';
import { Button } from '@/components/elements/forms/buttons';
import { ArrowNorthWestIcon, ChevronIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import FakeSearchInput from '@/components/modules/mobile/FakeSearchInput';
import { LOCATION_PLACEHOLDER } from '@/constants';
import { isServer, isSistaminuten, promiseWrapper, setBookingStartingPoint, trackMpEvent, url } from '@/helpers';
import withMobileView from '@/hoc/withMobileView';
import { __ } from '@/locale';
import { autocompleteService } from '@/services';
import React, { createRef } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { ModalContent, ModalDialog } from '../../modals';
import { getIsUserLocationOnboarded } from '../../modals/UserLocationOnboarding';

const theme = {
  container: 'q-container',
  input: `form-control search-input ${isSistaminuten() ? 'sistaminuten' : ''}`,
  inputOpen: 'form-control search-input open',
  inputFocused: `form-control search-input`,
  suggestionsContainer: 'q-autocomplete-container',
  suggestionsContainerOpen: 'q-autocomplete-container--open',
  suggestionsList: 'q-autocomplete',
  suggestionFirst: 'react-autosuggest__suggestion--first',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
};

const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10050,
    overflow: 'scroll',
    outline: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  content: {
    maxWidth: 400,
  },
};

class Keyword extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.inputComponentRef = createRef(null);
    const { q } = url.getUrlParameters(props);
    this.state = {
      isModalOpen: false,
      value: q || '',
      suggestions: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props?.location?.state?.fromFakeSeach) {
      this.openModal();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openModal = () => {
    if (!getIsUserLocationOnboarded()) {
      this.props.dispatch(modalActions.userLocationOnboarding({ show: true }));
    }

    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  onChange = (event, { newValue }) => {
    if (this._isMounted) {
      this.setState({ value: newValue || '' }, () => this.props.update(this.state.value, !this.props.isMobileView));
    }
  };

  clear = () => {
    const { q = '' } = url.getUrlParameters(this.props);
    const shouldClear = this.state.value === q;
    if (this._isMounted) {
      this.setState({ value: '' }, () => {
        if (shouldClear) {
          this.props.clear();
        } else {
          if (this.input) {
            this.input.focus();
          }
        }
      });
    }
  };

  onBlur = (event) => {
    this.props.update(this.state.value, true);
  };

  onKeyDown = (event) => {
    if (event.keyCode === 13) {
      // if enter
      event.preventDefault();
      this.props.update(this.state.value);
      if (this.props.isMobileView) {
        this.setState({ isModalOpen: false });
      } else {
        if (this.input) {
          this.input.blur();
        }
      }
    }
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    if (this._isMounted) {
      const { data, error } = await promiseWrapper(autocompleteService.getSuggestions(value));
      if (error) return;
      const fetched = data && Array.isArray(data.suggestions) ? data.suggestions : [];

      this.setState({
        suggestions: fetched,
        newServices: data.newServices,
      });
    }
  };

  onSuggestionsClearRequested = () => {
    if (this._isMounted) {
      this.setState({
        suggestions: [],
        isModalOpen: false,
      });
    }
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, method, suggestionIndex, sectionIndex }) => {
    if (this._isMounted) {
      if (suggestion.type === 'place') {
        const { placeId, slug } = suggestion;

        let trackingProps = {
          screen_name: 'search_autocomplete',
          query: this.props.q,
        };
        if (this.props.searchLocation) {
          trackingProps['search_location_city'] = this.props.searchLocation;
          trackingProps['search_location_type'] = this.props.myLocation ? 'nearby' : 'user_input';
        }
        trackMpEvent('company_clicked', {
          ...trackingProps,
          ...{
            company_id: placeId,
            position: suggestionIndex,
            result_type: suggestion.isEmployee ? 'person' : 'salon',
          },
        });
        setBookingStartingPoint('search_autocomplete');
        return this.props.history.push(`/places/${slug}-${placeId}`);
      } else {
        trackMpEvent('suggested_item_clicked', {
          screen_name: 'search_autocomplete',
          result_type: suggestion.type,
        });
      }

      // keep suggestions open if autofill button clicked
      const keepSuggestionsOpen = !!(
        event.target &&
        event.target.className &&
        event.target.className === 'autofill-button'
      );
      this.setState(
        {
          value: suggestionValue.replace('%', ''),
          isModalOpen: keepSuggestionsOpen,
        },
        () => {
          this.props.update(this.state.value, keepSuggestionsOpen);
          if (keepSuggestionsOpen && this.input) {
            setTimeout(() => {
              this.input.focus();
            }, 10);
          }
        },
      );
    }
  };

  getSuggestionValue = (suggestion) => {
    return (suggestion && suggestion.text) || '';
  };

  renderSuggestion = (suggestion) => {
    switch (suggestion.type) {
      case 'spell':
        return (
          <span className="spelling-suggestion">
            <em>{suggestion.text.replace(/ /g, '\u00a0')}</em>
          </span>
        );
      case 'place':
        return (
          <span className="place-suggestion">
            <img
              className="place-image"
              alt=""
              src={suggestion.profileImage ? suggestion.profileImage : 'https://www.bokadirekt.se/favicon.ico'}
            />
            <span className="place-label">{suggestion.text}</span>
          </span>
        );
      case 'history':
      case 'popular':
        return (
          <span className="history-suggestion">
            {suggestion.text}
            <span className="float-right">
              <ArrowNorthWestIcon className="text-black-400 h-6 w-6" />
            </span>
          </span>
        );
      case 'category':
      default:
        return <span className="category-suggestion">{suggestion.text}</span>;
    }
  };

  renderSectionTitle = (section) => {
    return section.title ? (
      <div className="text-black-600 py-3 pl-3 text-xs font-semibold uppercase">{__(section.title)}</div>
    ) : null;
  };

  getSectionSuggestions = (section) => {
    return section.suggestions;
  };

  renderInputComponent = (inputProps) => {
    const { isUserLocationLoading } = this.props;
    const height = this.inputComponentRef.current?.clientHeight || 0;
    const { key, ...rest } = inputProps;
    return (
      <>
        <div
          ref={this.inputComponentRef}
          className={
            this.props.isMobileView
              ? `scrolled:shadow fixed left-0 right-0 top-0 z-10 space-y-4 bg-white px-4 py-6 duration-100`
              : ''
          }
          data-height={height}>
          <div className="input-container relative">
            <button
              onClick={this.closeModal}
              className="pl-md py-sm pr-sm absolute left-0 top-0 h-full !w-auto outline-none"
              aria-label={__('SearchServiceOrSalon')}>
              <Icon variant="back" />
            </button>
            <input key={key} {...rest} />
            {inputProps.value.length > 0 && (
              <button
                className="absolute right-0 top-0 z-10 h-full !w-auto px-4"
                onClick={this.clear}
                onMouseDown={this.clear}
                aria-label={__('clear')}>
                <Icon variant="close-circle" />
              </button>
            )}
          </div>
          {this.props.isMobileView && (
            <div>
              <FakeSearchInput
                onClick={() => {
                  this.closeModal();
                  this.props.forceOpenLocation?.();
                }}
                className="z-10 !my-0"
                icon="location"
                isLoading={isUserLocationLoading}
                content={this.props.locationValue || LOCATION_PLACEHOLDER}
              />
            </div>
          )}
          {this.props.isMobileView && (
            <div>
              <FakeSearchInput
                onClick={() => {
                  this.closeModal();
                  this.props.forceOpenDateTimeFilter?.();
                }}
                className="z-10 !my-0"
                icon={'calendar-events'}
                content={this.props.dateTimeValue}
              />
            </div>
          )}
        </div>
        {this.props.isMobileView && <div style={{ height: height - 20 }} className="sticky left-0 right-0 top-0"></div>}
      </>
    );
  };

  handleNewServicesFavoritesClick = (e) => {
    trackMpEvent('view_new_services_in_favorites_clicked', {
      screen_name: 'search_autocomplete',
    });
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { newServices } = this.state;
    const withValues = [];
    for (let i in newServices) {
      if (newServices[i].length) {
        withValues.push(i);
      }
    }

    const firstPlace = withValues.length ? withValues.filter((a) => a && a.length).pop() : undefined;
    const { key, ...rest } = containerProps;
    return (
      <div key={key} {...rest}>
        {firstPlace && (
          <Link
            className="go-to-favorites flex w-full items-center justify-between p-4 text-sm font-semibold "
            onClick={this.handleNewServicesFavoritesClick}
            to={'/favoriter?salon=' + firstPlace}>
            {__('toFavorite')}
            <span className="flex items-center">
              <span
                style={{ background: 'rgba(234, 87, 134, 0.1)' }}
                className="mr-4 rounded px-2 py-[6px] text-sm font-semibold text-[#ea5786]">
                {__('ny')}
              </span>
              <ChevronIcon className="text-black-400 h-4 -rotate-90" />
            </span>
          </Link>
        )}
        {children}
      </div>
    );
  };

  shouldRenderSuggestions = (value, reason) => {
    return true;
  };

  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  // Prepare/Filter suggestions for rendering
  filterSuggestions() {
    const { suggestions } = this.state;
    return suggestions.filter((section) => section && section.suggestions && section.suggestions.length > 0);
  }

  showSearchForm = (e) => {
    this.props.history.push({ pathname: '/search' + this.props.location.search, state: { fromFakeSeach: true } });
  };

  renderMobile = () => {
    const { value, isModalOpen } = this.state;
    const { source, isMobileView, autoFocus, search } = this.props;
    const searchPlaceholder = isMobileView && source === 'home' ? __('searchForToday') : __('Enter service or salon');
    const inputProps = {
      placeholder: searchPlaceholder,
      value: value || '',
      type: 'text',
      inputMode: 'search',
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      autoFocus,
    };
    const clear = value !== searchPlaceholder && value.length > 0;

    const hasKeyword = value && value.length > 0;
    const triggerSearchLabel =
      __('searchFor') + ' "' + value + (search.location ? ' ' + __('in') + ' ' + search.location : '') + '"';

    return (
      <div className={source === 'home' ? 'w-full' : ''}>
        <FakeSearchInput
          onClick={source === 'home' ? this.showSearchForm : this.openModal}
          className={`!mt-0 ${source === 'home' ? '!h-[52px] !rounded-br-none !rounded-tr-none' : ''}`}
          valid={clear}
          icon="search"
          onClear={this.clear}
          content={value || searchPlaceholder}
          size={source === 'home' ? 'md' : 'sm'}
        />
        {!isServer && (
          <ModalDialog
            isOpen={isModalOpen}
            contentLabel="Modal"
            onRequestClose={this.closeModal}
            shouldCloseOnOverlayClick={false}
            closeTimeoutMS={1 /* otherwise the modal is not closed when suggestion is selected by pressing Enter */}
            className="modal-search"
            overlayClassName=""
            appElement={document.getElementById('root')}
            style={modalStyle}>
            <ModalContent size="fullscreen">
              <div className="pointer-events-auto relative flex w-full flex-col bg-white bg-clip-padding outline-0">
                <div className="relative top-0 mt-4 flex-1 basis-auto px-4">
                  <form autoComplete="false" onSubmit={(e) => e.preventDefault()} action=".">
                    <Autosuggest
                      id="keyword-autosuggest"
                      theme={theme}
                      inputProps={inputProps}
                      renderInputComponent={this.renderInputComponent}
                      ref={this.storeInputReference}
                      alwaysRenderSuggestions={true}
                      suggestions={this.filterSuggestions()}
                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                      onSuggestionSelected={this.onSuggestionSelected}
                      getSuggestionValue={this.getSuggestionValue}
                      renderSuggestion={this.renderSuggestion}
                      renderSuggestionsContainer={this.renderSuggestionsContainer}
                      multiSection={true}
                      getSectionSuggestions={this.getSectionSuggestions}
                      renderSectionTitle={this.renderSectionTitle}
                    />
                    {hasKeyword && <Button variant="link">{triggerSearchLabel}</Button>}
                  </form>
                </div>
              </div>
            </ModalContent>
          </ModalDialog>
        )}
      </div>
    );
  };

  render() {
    const { isMobileView, source } = this.props;
    if (isMobileView) return this.renderMobile();

    const { value } = this.state;

    const inputProps = {
      placeholder: __('Enter service or salon'),
      value: value || '',
      type: 'search',
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      onBlur: this.onBlur,
    };
    theme.input =
      value.length > 0
        ? `form-control search-input source-${source} valid ${isSistaminuten() ? 'sistaminuten' : ''}`
        : `form-control search-input source-${source} ${isSistaminuten() ? 'sistaminuten' : ''}`;

    return (
      <Autosuggest
        id="keyword-autosuggest"
        theme={theme}
        inputProps={inputProps}
        renderInputComponent={this.renderInputComponent}
        ref={this.storeInputReference}
        focusInputOnSuggestionClick={false}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        suggestions={this.filterSuggestions()}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        multiSection={true}
        getSectionSuggestions={this.getSectionSuggestions}
        renderSectionTitle={this.renderSectionTitle}
      />
    );
  }
}

function mapStateToProps(state) {
  const { loading } = state.loading;
  const { user } = state.users;
  const { search } = state;

  return {
    search,
    loading,
    user,
  };
}

const KeywordAutosuggest = withMobileView(withRouter(connect(mapStateToProps)(Keyword)));
export default KeywordAutosuggest;
