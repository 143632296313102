export function trimObjectValuesWhitespace<T>(obj: T): T {
  if (typeof obj === 'string') {
    return obj.trim() as any;
  }

  if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = trimObjectValuesWhitespace(obj[key]);
      }
    }
  }

  return obj;
}

export function openIosKeyboard() {
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  input.setAttribute('style', 'position: fixed; top: -100px; left: -100px;');
  document.body.appendChild(input);
  input.focus();
  setTimeout(() => {
    document.body.removeChild(input);
  }, 30 * 1000);
}

export function arrayOfStrings(value: any): string[] {
  if (Array.isArray(value) && value.every((item: any) => typeof item === 'string')) {
    return value;
  }
  return [];
}
