import Icon from '@/components/icons/Icon';
import { ReactHookFormRegister } from '@/components/modules/checkout/CheckoutFormFields/CheckoutFormFields';
import { classnames } from '@/helpers';
import { LegacyRef, TextareaHTMLAttributes, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type GenericListInputProps = {
  error?: string;
  label?: string;
  leftIcon?: React.ReactNode;
  info?: string;
  labelClassName?: string;
  leftPadding?: boolean;
};

type ListInputTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  GenericListInputProps &
  ReactHookFormRegister<any>;

const ListInputTextArea = forwardRef(
  (
    {
      error,
      label,
      leftIcon,
      id,
      info,
      labelClassName,
      disabled,
      leftPadding = true,
      ...props
    }: ListInputTextAreaProps,
    ref: LegacyRef<HTMLTextAreaElement>,
  ) => {
    return (
      <div className={classnames('flex w-full flex-col', disabled && 'opacity-40', leftPadding && 'pl-lg')}>
        {label && (
          <label htmlFor={id} className={twMerge('text-black-900 pt-md text-xs', labelClassName)}>
            {label}
          </label>
        )}
        <div className={`py-md pr-lg relative flex border-b ${error ? 'border-danger-500' : 'border-black-100'}`}>
          {leftIcon && <div className="pr-md pt-md flex shrink-0 grow-0 flex-col justify-end">{leftIcon}</div>}
          <textarea
            id={id}
            ref={ref}
            className={`placeholder:text-black-600 text-black-900 w-full resize-none text-sm outline-none disabled:bg-transparent`}
            disabled={disabled}
            {...props}
          />
          {error && (
            <div className="pt-md flex shrink-0 grow-0 flex-col justify-end">
              <Icon variant="warning" color="danger-500" />
            </div>
          )}
        </div>
        {(error || info) && (
          <div className="mt-xxs flex">
            {error && <div className="text-danger text-xxs">{error}</div>}
            {info && <div className="text-xxs text-black-600 flex flex-1 justify-end">{info}</div>}
          </div>
        )}
      </div>
    );
  },
);

export default ListInputTextArea;
