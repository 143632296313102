import { LoadingIcon } from '@/components/icons/';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';

import { KlarnaPaymentsPurchaseConfirmation } from '@/components/modules/klarna/KlarnaPaymentsConfirmation';
import { trackEventTikTok, trackMpAnalyticsEvent, trackMpEvent, trackMpRevenueGiftcards, trackPage } from '@/helpers';
import { __ } from '@/locale';
import { giftCardServices } from '@/services/giftCardServices';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *
 * @param {object} response - Response from useConfirmGiftCardOrder
 */
export const trackGiftCardPurchase = (response, klarnaID) => {
  if (response.giftCard[0]?.message === 'order_created') return;

  const { isWellness, amount, isDigital } = response;

  const wellness = isWellness ? 'wellness_' : '';
  const w = isWellness ? 'W' : '';

  trackMpAnalyticsEvent('gift_card_purchase_success', {
    card_type: isWellness ? 'wellness' : 'universal',
    amount,
  });
  trackMpAnalyticsEvent('gc_purchase_success', {
    gc_type: isWellness ? 'wellness' : 'universal',
  });
  trackMpEvent(`gift_card_${wellness}purchase`, {
    klarna_order_id: klarnaID,
    order_value: amount,
    is_digital: isDigital,
  });
  trackPage();
  trackMpEvent('screen_shown', {
    screen_name: `${isWellness ? 'wellness_card' : 'gift_card'}_confirmation`,
  });
  trackMpRevenueGiftcards({
    value: amount || 0,
    currency: 'SEK',
    contentName: `UGC${w}`,
    contentType: 'product',
    description: isWellness ? 'wellness_card' : 'gift_card',
    orderId: klarnaID,
  });
  trackEventTikTok(
    `${isWellness ? 'CompletePayment' : 'CompleteRegistration'}`,
    {
      value: amount, // number. Value of the order or items sold. Example: 100.
      currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
      contents: [
        {
          content_id: `UGC${w}`, // string. ID of the product. Example: '1077218'.
          content_type: 'product', // string. Either product or product_group.
          content_name: `${isWellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
        },
      ],
      content_type: 'product',
    },
    { event_id: klarnaID },
  );
  trackEventTikTok(
    `${isWellness ? 'WellnessCardPurchase' : 'UniversalGiftCardPurchase'}`,
    {
      value: amount, // number. Value of the order or items sold. Example: 100.
      currency: 'SEK', // string. The 4217 currency code. Example: 'USD'.
      contents: [
        {
          content_id: `UGC${w}`, // string. ID of the product. Example: '1077218'.
          content_type: 'product', // string. Either product or product_group.
          content_name: `${isWellness ? 'Wellness card' : 'Universal gift card'}`, // string. The name of the page or product. Example: 'shirt'.
        },
      ],
      content_type: 'product',
    },
    { event_id: `${isWellness ? 'W' : 'U'}-${klarnaID}` },
  );
};

const GiftCardConfirmation = () => {
  const location = useLocation();
  const parsed = location.search ? queryString.parse(location.search) : {};
  const klarnaID = parsed.klarna_order_id ?? '';
  const sessionID = parsed.session_id ?? '';

  const [response, loading, error] = giftCardServices.useConfirmGiftCardOrder(klarnaID, sessionID);
  const confirmationEmail = response?.giftCard[0]?.receiverEmail
    ? response.giftCard[0].receiverEmail
    : response?.confirmationEmail
    ? response.confirmationEmail
    : '';

  useEffect(() => {
    if (!response || response.error) return;

    trackGiftCardPurchase(response, klarnaID);
  }, [response]);

  return (
    <PageViewLayout type="mainView" title={__('giftcardConfirmation.title')} source={'buyGiftCard'}>
      <div className="container-fixed py-lg">
        {loading && <LoadingIcon className="mx-auto block h-10 w-10" />}
        {error && <p className="text-center">{__('giftcardConfirmation.error')}</p>}
        {response && (
          <KlarnaPaymentsPurchaseConfirmation
            email={confirmationEmail}
            orderLines={response.orderLines}
            tax={response.tax}
            totalAmount={response.totalAmount}
            paymentMethod={response.paymentMethod}
            ctaButtonText={__('giftcardConfirmation.ctaButtonText')}
            ctaButtonLink={'/giftcards/buy'}
          />
        )}
      </div>
    </PageViewLayout>
  );
};

export default GiftCardConfirmation;
