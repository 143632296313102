import PlaceCardContent from '@/components/templates/placeCard/PlaceCardContent';

import { Button } from '@/components/elements/forms/buttons';
import {
  getDateFormattedForFirstAvailableTime,
  getImageSize,
  isMobile,
  setCookie,
  shouldDisplayReviews,
  getPlacePreviewImage,
  trackMpEvent,
} from '@/helpers';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { placeService } from '@/services';
import { getScreenName, handleLoginClick } from '@/services/navigationServices';
import { NavItemIdentifier } from '@/types/navigation';
import { Fragment, SyntheticEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Service } from '../../salon';

const styles = {
  card: `
  relative
  flex
  min-w-0
  flex-col
  break-words
  border
  bg-white
  rounded-lg
  border-none
  shadow
  border-[1px]
  bg-white
  w-full
  p-4
  border-[rgba(0, 0, 0, 0.125)]
  sm:hover:shadow-cardHover
  duration-200
  group
  max-w-[400x]
  sm:max-w-full
  w-full
  sm:w-auto
  mx-auto
  sm:mx-0
  `,
};

export type PlaceSubscriptionType = 'essential' | 'stable' | 'growth';

const PlaceCard = (props) => {
  const { isMobileView } = useMobileView();
  const dispatch = useDispatch();
  const tooltipContainerRef = useRef<HTMLDivElement>(null);
  const subscriptionType: PlaceSubscriptionType = props.place?.about?.settings?.subscriptionType || 'stable';

  const setHover = (placeId) => {
    const { fireHover } = props;
    if (fireHover) {
      fireHover(placeId);
    }
  };

  const setHoverOut = (placeId) => {
    const { fireHoverOut } = props;
    if (fireHoverOut) {
      fireHoverOut(placeId);
    }
  };

  const handleClick = (e) => {
    const { place } = props;

    if (place) {
      if (place.topSearch && place.topSearch.bidId) {
        placeService.topOfSearchClicked(place.topSearch.bidId);
        setCookie('clickedTopPlaces_' + place.id, place.topSearch.bidId, {
          path: '/',
          expires: new Date(Date.now() + 30 * 86400 * 1000),
        });
      } else if (
        props.source === 'serp-page-top' &&
        place.about &&
        place.about.settings &&
        place.about.settings.topOfSearch
      ) {
        sessionStorage.setItem('ClickedOffer', place.id);
      }
    }

    if (props.onClickPlace) {
      props.onClickPlace(e);
    }
  };

  const handleRemove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.removeAction(e);
  };

  const getMainImage = () => {
    const { place } = props || {};
    const { images } = place.about || {};
    const imagesLength = (images || []).length;
    if (!images || imagesLength === 0) return null;

    const mainImage = getImageSize(images[0], isMobileView ? '500x' : '388x', place.about.slug + '-1');
    if (!mainImage) return null;

    const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
      const fallbackDimensions = isMobile() ? '300x200' : '390x260';
      e.currentTarget.src = `/images/bd-fallback-img${fallbackDimensions}.png`;
    };

    return (
      <img loading="lazy" className="h-full w-full object-cover" src={mainImage} alt="" onError={handleImageError} />
    );
  };

  const getFirstAvailableTime = (place) => {
    const { firstAvailableTime, timezone } = place;
    return firstAvailableTime ? getDateFormattedForFirstAvailableTime(firstAvailableTime, timezone) : null;
  };

  const goToLogin = (e) => {
    trackMpEvent('recently_viewed_login_clicked', {
      screen_name: 'home',
    });
    handleLoginClick(dispatch, NavItemIdentifier.LogIn, getScreenName());
  };

  const { place, source, className, onBookClick } = props || {};
  if (!place || !place.about) {
    return null;
  }
  const { rating, about } = place;
  const { images, welcomeText } = about || {};
  const address = place.contact && place.contact.address ? place.contact.address : null;
  const showAddress = address && (address.city || address.street);
  const showRatings = shouldDisplayReviews(place) && rating && rating.count > 4;
  const firstAvailableTime = !props.hasLoginForLastVisited && getFirstAvailableTime(place);
  const hasNewServices = source === 'favorites' && place.newServices && place.newServices.length ? true : false;
  const hasMainImage = (images || []).length > 0;

  const cardHeight = 'h-full';

  // Resized image (thumbnail)
  const cardImage = getPlacePreviewImage(place);

  return (
    <Fragment>
      <div id="tooltip-slot" className="relative" ref={tooltipContainerRef} />
      <Link
        key={place.id}
        ref={(r) => (props.myRef ? props.myRef(r) : null)}
        to={{
          pathname: '/places/' + place.about.slug + '-' + place.id,
          state: { fromList: true, searchParams: props.searchParams },
        }}
        onClick={handleClick}
        className={`${styles.card} ${cardHeight} ${className ? className : ''}`}
        onMouseOver={() => setHover(place.id)}
        onMouseOut={() => setHoverOut(place.id)}
        style={!hasNewServices ? { height: 'auto' } : {}}>
        <PlaceCardContent
          place={place}
          address={address}
          cardImage={cardImage}
          getMainImage={getMainImage}
          hasMainImage={hasMainImage}
          showRatings={showRatings}
          showAddress={showAddress}
          rating={rating}
          firstAvailableTime={firstAvailableTime}
          handleRemove={handleRemove}
          removeAction={props.removeAction}
          source={props.source}
          welcomeText={welcomeText}
          subscriptionType={subscriptionType}
          tooltipContainer={tooltipContainerRef}
        />

        {props.hasLoginForLastVisited && (
          <div className="absolute inset-0 flex items-end justify-center ">
            <div className="z-[2] mb-4">
              <Button onClick={goToLogin}>{_s('loginAndViewLastVisited')}</Button>
            </div>
            <div className="absolute inset-0 z-[1] bg-white opacity-75"></div>
          </div>
        )}
        {hasNewServices && subscriptionType !== 'essential' && (
          <div className="border-black-200 text-black-900 mt-4 w-full border-t-[1px] pt-4">
            {place.newServices.map((service, i) => {
              return (
                <div className="mb-2" key={i}>
                  <ul>
                    <li style={{ paddingBottom: 4 }}>
                      <span
                        style={{ background: 'rgba(234, 87, 134, 0.1)' }}
                        className="rounded px-2 py-[6px] text-xs font-semibold text-[#ea5786]">
                        {_s('new')}
                      </span>
                    </li>
                    <Service
                      section="none"
                      place={place}
                      service={service}
                      key={service.id}
                      source={source}
                      fakeButton={true}
                      onBookClick={onBookClick}
                    />
                  </ul>
                </div>
              );
            })}
          </div>
        )}
      </Link>
    </Fragment>
  );
};

export default withRouter(PlaceCard);
