import Icon from '@/components/icons/Icon';
import { classnames } from '@/helpers';
import { NavItem, NavItemIdentifier } from '@/types/navigation';
import { Link, LinkProps } from 'react-router-dom';

const getLinkProps = <T,>(props: T, handleClick = null): T & { onClick?: () => void } => ({
  ...props,
  ...(handleClick && { onClick: handleClick }),
});

const getNavBarItemClasses = (item: NavItem) =>
  classnames(
    'items-center rounded-full inline-flex gap-xs relative',
    item.breakpoint && `hidden ${item.breakpoint}`,
    item.selected ? '!text-black-900' : '!text-black-600',
  );

type NavBarItemProps = {
  item: NavItem;
  className?: string;
  handleClick?: () => void;
};

const NavBarItem = ({ item, className, handleClick, ...props }: NavBarItemProps) => {
  const isLink = item?.to;
  if (isLink) {
    return item.to.startsWith('/') ? (
      <Link
        id={item.id}
        to={item.to}
        className={getNavBarItemClasses(item)}
        {...getLinkProps<LinkProps>(props, handleClick)}>
        <Icon
          variant={item.icon}
          size="sm"
          style={{ width: 24, height: 24 }}
          color={item.selected ? 'black-900' : 'black-500'}
        />
        {item.label && (
          <small className={`text-xxs !leading-3 ${item.selected ? 'text-black-900' : 'text-black-600'}`}>
            {item.label}
          </small>
        )}
      </Link>
    ) : (
      <a
        id={item.id}
        href={item.to}
        className={getNavBarItemClasses(item)}
        {...getLinkProps<LinkProps>(props, handleClick)}>
        <Icon
          variant={item.icon}
          size="sm"
          color={item.selected ? 'black-900' : 'black-500'}
          style={{ width: 24, height: 24 }}
        />
        {item.label && (
          <small className={`text-xxs !leading-3 ${item.selected ? 'text-black-900' : 'text-black-600'}`}>
            {item.label}
          </small>
        )}
      </a>
    );
  }

  return (
    <button
      id={item.id}
      className={getNavBarItemClasses(item) + ' outline-none'}
      {...getLinkProps<LinkProps>(props, handleClick)}>
      <Icon
        variant={item.icon}
        size="sm"
        alt={item.identifier === NavItemIdentifier.Hamburger ? 'meny' : ''}
        color={item.selected ? 'black-900' : 'black-500'}
        style={{ width: 24, height: 24 }}
      />
      {item.label && (
        <small className={`text-xxs !leading-3 ${item.selected ? 'text-black-900' : 'text-black-600'}`}>
          {item.label}
        </small>
      )}
    </button>
  );
};

export default NavBarItem;
