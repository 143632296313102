import Header from '@/components/elements/Header/Header';
import Label from '@/components/elements/Label/Label';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import Button, { ButtonProps } from '@/components/elements/forms/buttons/Button/Button';
import ListInput from '@/components/elements/forms/listInput/ListInput/ListInput';
import Alert from '@/components/elements/notifications/Alert/Alert';
import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import Icon from '@/components/icons/Icon';
import PageViewLayout from '@/components/layouts/PageViewLayout/PageViewLayout';
import CardWrapper from '@/components/modules/CardWrapper';
import SEO, { seoPropsFromBaseString } from '@/components/modules/SEO';
import UpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo';
import useUpdateUserInfo from '@/components/modules/UpdateUserInfo/UpdateUserInfo.hooks';
import CheckoutTerms from '@/components/modules/checkout/CheckoutTerms/CheckoutTerms';
import DeliveryInfo from '@/components/modules/checkout/giftcard/DeliveryInfo/DeliveryInfo';
import DiscountCode from '@/components/modules/checkout/giftcard/DiscountCode';
import PersonalGreeting from '@/components/modules/checkout/giftcard/PersonalGreeting';
import SSNFormCard from '@/components/modules/checkout/giftcard/SSNForm';
import SelectAmount from '@/components/modules/checkout/giftcard/SelectAmount/SelectAmount';
import SelectType from '@/components/modules/checkout/giftcard/SelectType/SelectType';
import Modal from '@/components/modules/modals/redesign/Modal/Modal';
import ActionSteps from '@/components/templates/giftCards/ActionSteps';
import CheckoutDetails from '@/components/templates/giftCards/CheckoutDetails/CheckoutDetails';
import ProductInfo from '@/components/templates/giftCards/ProductInfo/ProductInfo';
import { SCREEN_NAME } from '@/constants/analytics';
import { GIFTCARD_DEFAULT_VALUE_SELECTED, WELLNESS_DEFAULT_VALUE_SELECTED } from '@/constants/giftcardConstants';
import { APP_ROUTES } from '@/constants/pages';
import { TermLinkIdentifier } from '@/constants/terms';
import { classnames, getWellnessCardExpiryDateFromToday, trackMpEvent, trackPage, url } from '@/helpers';
import { getCheckoutTerms } from '@/helpers/checkout';
import { arrayOfStrings } from '@/helpers/utilsTS';
import withWalletPayment from '@/hoc/withWalletPayment';
import { useAppSelector } from '@/hooks';
import {
  GiftcardCheckoutFormDataProvider,
  GiftcardFormData,
  useGiftcardCheckoutFormData,
} from '@/hooks/useGiftcardCheckoutFormData';
import useMobileView from '@/hooks/useMobileView';
import { trackScreenView } from '@/hooks/useTrackScreenView';
import { _s } from '@/locale';
import { authorizeKlarnaPurchase, getInitializeKlarnaSDKOnce, loadKlarnaWidget } from '@/services/klarnaServices';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GiftcardCheckoutProvider, useGiftcardCheckout } from './GiftcardCheckout.hooks';
import useKlarnaGiftcardCheckoutManager from './klarna/KlarnaGiftcardCheckout.hooks';

const baseTranslationKey = 'pages.giftcard.checkout';

function handleUpdateUserInfoCallback({ success, retry }, closeUpdateUserInfo: () => void) {
  trackScreenView({ name: success ? 'screen_view_update_profile_success' : 'screen_view_update_profile_fail' });

  if (success) closeUpdateUserInfo();

  toast(
    ({ closeToast }) => (
      <Snackbar
        label={_s(`${baseTranslationKey}.editProfileModal.snackbar.label.${success ? 'success' : 'error'}`)}
        type={success ? 'success' : 'danger'}
        action={
          <button onClick={success ? closeToast : retry}>
            {_s(`${baseTranslationKey}.editProfileModal.snackbar.action.${success ? 'success' : 'error'}`)}
          </button>
        }
      />
    ),
    { autoClose: 2000 },
  );
}

const getCampaignType = (type?: string): 'fathersDay' | null => {
  switch (type) {
    case 'fars-dag':
      return 'fathersDay';
    default:
      return null;
  }
};

const getProductInfo = (type: 'giftcard' | 'wellnesscard' | 'fathersDay') => {
  switch (type) {
    case 'giftcard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.giftcard.title`),
        subTitles: [_s(`${baseTranslationKey}.productInfo.giftcard.subTitle`)],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-desktop-2xl.png`,
          desktop: `/images/product-info-giftcard-desktop-lg.png`,
          mobile: `/images/product-info-giftcard-mobile.png`,
        },
      };
    case 'wellnesscard':
      return {
        title: _s(`${baseTranslationKey}.productInfo.wellnesscard.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.wellnesscard.subTitle`),
          _s(`${baseTranslationKey}.productInfo.wellnesscard.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-wellnesscard-desktop-2xl.png`,
          desktop: `/images/product-info-wellnesscard-desktop-lg.png`,
          mobile: `/images/product-info-wellnesscard-mobile.png`,
        },
      };
    case 'fathersDay':
      return {
        title: _s(`${baseTranslationKey}.productInfo.campaigns.fathersDay.title`),
        subTitles: [
          _s(`${baseTranslationKey}.productInfo.campaigns.fathersDay.subTitle`),
          _s(`${baseTranslationKey}.productInfo.campaigns.fathersDay.subTitle2`),
        ],
        imgSrc: {
          desktop2xl: `/images/product-info-giftcard-fathersday.png`,
          desktop: `/images/product-info-giftcard-fathersday.png`,
          mobile: `/images/product-info-giftcard-fathersday.png`,
        },
      };
  }
};

const GiftcardCheckout = () => {
  const { isMobileView } = useMobileView();
  const user = useAppSelector((state) => state.users.user);
  const location = useLocation();
  const { campaign } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const utm_source = queryParams.get('utm_source');
  const appUser = utm_source === 'mp_android_app' || utm_source === 'mp_ios_app';
  const { handleSubmit, formdata, reset } = useGiftcardCheckoutFormData();
  const updateUserInfo = useUpdateUserInfo({
    callback: (props) =>
      handleUpdateUserInfoCallback({ retry: props.retry, success: props.success }, handleCloseEditProfile),
    triggerSource: SCREEN_NAME.CHECKOUT_BOOKING_SUMMARY,
  });
  const [isDeliveryTooltipOpen, setIsDeliveryTooltipOpen] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const { summary, handleKlarnaPayment, klarna } = useGiftcardCheckout();
  const productType = location.pathname === APP_ROUTES.WELLNESSCARD_CHECKOUT ? 'wellnesscard' : 'giftcard';
  const deliveryType = formdata.type;
  const seoBaseString = productType === 'wellnesscard' ? 'buyGiftcardWellness' : 'buyGiftcardUniversal';
  const campaignType = getCampaignType(campaign);

  const checkoutTerms = getCheckoutTerms('giftcard', TermLinkIdentifier.COUPON, _s('Buy'));

  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
  };

  useEffect(() => {
    handleKlarnaPayment();
    trackPage();
    trackMpEvent('screen_shown', {
      screen_name: `${productType === 'wellnesscard' ? 'wellness_card' : 'gift_card'}_landing_page`,
    });
  }, []);

  useEffect(() => {
    const newInitialData: GiftcardFormData = {
      amount: productType === 'giftcard' ? GIFTCARD_DEFAULT_VALUE_SELECTED : WELLNESS_DEFAULT_VALUE_SELECTED,
      quantity: 1,
      type: 'digital',
    };
    reset(newInitialData);
  }, [productType, reset]);

  useEffect(() => {
    if (klarna?.clientToken) {
      const initializeKlarnaSDK = getInitializeKlarnaSDKOnce();
      initializeKlarnaSDK();
      loadKlarnaWidget('#klarna-payment', klarna.clientToken);
    }
  }, [klarna?.clientToken]);

  const bulletPoints = arrayOfStrings(_s(`${baseTranslationKey}.productInfo.${productType}.bulletPoints`));

  const deliveryInfoContent = deliveryType === 'digital' ? user?.contact?.email : user?.contact?.streetAddress;
  const deliveryInfoSubTitle =
    deliveryType === 'digital'
      ? user?.contact?.mobile
      : `${user?.contact?.postalCode}${user?.contact?.locality ? ', ' + user?.contact?.locality : ''}`;

  return (
    <PageViewLayout
      type={appUser ? 'subView' : 'mainView'}
      title={_s(`${baseTranslationKey}.page-title.${productType}`)}
      wrapperClass={isMobileView ? 'bg-brown-50' : 'bg-gradient'}>
      <form onSubmit={handleSubmit(() => {})}>
        <div className=" bg-brown-50 flex justify-center lg:bg-transparent">
          <div className="lg:py-xxl lg:container">
            <div className={`flex items-start lg:p-[30px] ${isMobileView ? '' : 'gap-[30px]'}`}>
              <div className={`flex grow-[9999] flex-col lg:max-w-[600px] ${isMobileView ? 'gap-md' : 'gap-lg'}`}>
                <ProductInfo
                  {...getProductInfo(campaignType ?? productType)}
                  bulletPoints={bulletPoints}
                  middleSlot={
                    productType === 'wellnesscard' ? (
                      <Label
                        label={`${_s(`${baseTranslationKey}.productInfo.wellnesscard.label`, {
                          expire: getWellnessCardExpiryDateFromToday(),
                        })}`}
                        className="bg-success-900 text-white"></Label>
                    ) : undefined
                  }
                />

                <SelectAmount title={_s(`${baseTranslationKey}.selectAmount.title`)} type={productType} />

                {productType === 'giftcard' && <SelectType onClick={() => setIsDeliveryTooltipOpen(true)} />}

                <DeliveryInfo
                  productType={productType}
                  onClick={() => setShowEditProfile(true)}
                  content={deliveryInfoContent}
                  subTitle={deliveryInfoSubTitle}
                />

                {deliveryType === 'physical' && <PersonalGreeting />}

                {productType === 'giftcard' && <DiscountCode />}

                {productType === 'wellnesscard' && <SSNFormCard />}

                <div className="w-full">
                  <CardWrapper>
                    <div className="gap-xl p-lg flex flex-col">
                      <div id="klarna-payment"></div>
                      <SubmitCTA />
                      <CheckoutTerms
                        description={checkoutTerms.description}
                        linkLabel={checkoutTerms.linkLabel}
                        termsPageId={checkoutTerms.termsPageId}
                        onClose={() => {}}
                        triggerSource={SCREEN_NAME.CHECKOUT_GIFTCARD_SUMMARY}
                      />
                    </div>
                  </CardWrapper>
                </div>

                {isMobileView && productType === 'wellnesscard' && (
                  <ActionSteps
                    title={_s(`${baseTranslationKey}.actionSteps.title`)}
                    steps={[
                      { text: _s(`${baseTranslationKey}.actionSteps.stepOne`) },
                      {
                        text: _s(`${baseTranslationKey}.actionSteps.stepTwo`),
                        link: _s(`${baseTranslationKey}.actionSteps.bookLink`),
                      },
                      { text: _s(`${baseTranslationKey}.actionSteps.stepThree`) },
                    ]}
                  />
                )}
              </div>
              {!isMobileView && (
                <div
                  className={`sticky top-20 hidden lg:flex lg:grow-[100] lg:basis-[390px] lg:flex-col lg:gap-[30px]`}>
                  <CardWrapper className="space-y-lg w-[390px]">
                    <CheckoutDetails
                      selectedAmount={formdata.amount ?? formdata.customAmount}
                      selectedQuantity={formdata.quantity}
                      productType={productType}
                      deliveryType={deliveryType}
                      discount={
                        formdata.discountCode
                          ? { code: formdata.discountCode, amount: summary.discountAmount }
                          : undefined
                      }
                    />

                    <Alert
                      variant="information"
                      leftSlot={<Icon variant="info-circle" />}
                      body={
                        productType === 'wellnesscard'
                          ? _s(`${baseTranslationKey}.alert.wellnesscard`, {
                              expire: getWellnessCardExpiryDateFromToday(),
                            })
                          : _s(`${baseTranslationKey}.alert.giftcard`)
                      }
                    />
                  </CardWrapper>
                  {productType === 'wellnesscard' && (
                    <ActionSteps
                      insideCard={true}
                      title={_s(`${baseTranslationKey}.actionSteps.title`)}
                      steps={[
                        { text: _s(`${baseTranslationKey}.actionSteps.stepOne`) },
                        {
                          text: _s(`${baseTranslationKey}.actionSteps.stepTwo`),
                          link: _s(`${baseTranslationKey}.actionSteps.bookLink`),
                        },
                        { text: _s(`${baseTranslationKey}.actionSteps.stepThree`) },
                      ]}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>

      <Modal isOpen={isDeliveryTooltipOpen}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header
            title={_s(`pages.${productType}.checkout.deliveryTooltip.title`)}
            onClose={() => setIsDeliveryTooltipOpen(false)}
          />
          <div className="px-lg pb-lg">
            <p>{_s(`pages.${productType}.checkout.deliveryTooltip.body1`)}</p>
            <br />
            <p>{_s(`pages.${productType}.checkout.deliveryTooltip.body2`)}</p>
          </div>
        </Modal.Content>
      </Modal>

      <Modal isOpen={showEditProfile}>
        <Modal.Content floating={!isMobileView}>
          <Modal.Header title={_s(`${baseTranslationKey}.editProfileModal.title`)} onClose={handleCloseEditProfile} />
          <div className={classnames(isMobileView && 'px-lg')}>
            <div className="p-lg">
              <Header label={_s('Email')} size="lg" />
            </div>
            <ListInput id="email" disabled value={user?.contact?.email} />
            <UpdateUserInfo {...updateUserInfo} familyName givenName locality mobile postalCode streetAddress />
          </div>
        </Modal.Content>
      </Modal>

      <SEO {...seoPropsFromBaseString(seoBaseString, `${url.getBaseUrl()}${productType}/checkout`)} />
    </PageViewLayout>
  );
};

const SubmitCTA = () => {
  const { handleKlarnaPayment, submitting, klarna } = useGiftcardCheckout();
  const { klarnaGiftcardCheckoutAPI, status } = useKlarnaGiftcardCheckoutManager();
  const { handleSubmit } = useGiftcardCheckoutFormData();
  const payWithKlarna = async () => {
    const klarnaResponse = await handleKlarnaPayment();
    if (klarnaResponse) {
      authorizeKlarnaPurchase(klarnaResponse.orderLines, 'pay_later', (res) => {
        if (res.authorization_token) {
          klarnaGiftcardCheckoutAPI().onSubmitKlarna(res, klarnaResponse);
        }
      });
    }
  };

  const buttonProps: ButtonProps = {
    size: 'lg',
    block: true,
    label: _s(`checkout.payWithKlarna`),
    onClick: handleSubmit(payWithKlarna),
    className: 'whitespace-nowrap',
    leftIcon: <Icon variant={'payment-method-klarna-lg'} noFilter />,
    iconNoFilter: true,
    disabled: !klarna || status === 'SUBMITTING' || submitting,
  };

  return (
    <>
      {(status === 'SUBMITTING' || submitting) && <LoadingPlaceHolder className="fixed h-screen" />}
      <Button {...buttonProps} />
    </>
  );
};

const GiftcardCheckoutWithProviders = () => {
  const location = useLocation();
  const productType = location.pathname === APP_ROUTES.WELLNESSCARD_CHECKOUT ? 'wellnesscard' : 'giftcard';

  return (
    <GiftcardCheckoutFormDataProvider
      initialData={{
        amount: productType === 'giftcard' ? GIFTCARD_DEFAULT_VALUE_SELECTED : WELLNESS_DEFAULT_VALUE_SELECTED,
        quantity: 1,
        type: 'digital',
      }}>
      <GiftcardCheckoutProvider>
        <GiftcardCheckout></GiftcardCheckout>
      </GiftcardCheckoutProvider>
    </GiftcardCheckoutFormDataProvider>
  );
};

export default withWalletPayment(GiftcardCheckoutWithProviders);
