import { XS } from '@/components/elements/content';
import { Button } from '@/components/elements/forms/buttons';
import { formatPrice } from '@/helpers';
import { __ } from '@/locale';
import { MouseEventHandler } from 'react';

type ValueCardProps = {
  name: string;
  value: number;
  price: number;
  onSelect: MouseEventHandler<HTMLButtonElement>;
  hasInfo: boolean;
  showInfo: MouseEventHandler<HTMLButtonElement>;
};

const ValueCard = ({ name, value, price, onSelect, hasInfo, showInfo }: ValueCardProps) => (
  <div className="border-black-100 my-2 flex items-center justify-between rounded-lg border bg-white p-4 !py-4 shadow">
    <div className="">
      <h3 className="mb-1 text-base">
        <button onClick={onSelect} className="hover:text-primary transition-colors">
          {name}
        </button>
      </h3>
      <div className="flex items-center justify-start">
        <XS className="text-danger mr-2 font-semibold">{formatPrice(price / 100)} kr</XS>
        <XS className="text-black-600 mr-2 font-semibold line-through">{formatPrice(value / 100)} kr</XS>
        {hasInfo && (
          <>
            <div className="bg-black-600 mr-2 inline-block h-1 w-1 rounded-full"></div>
            <button className="text-information-700 text-xs underline" onClick={showInfo}>
              {__('buyValueCard.checkout.moreInfo') as string}
            </button>
          </>
        )}
      </div>
    </div>
    <Button variant="primary" onClick={onSelect}>
      {__('buyValueCard.checkout.selectValue') as string}
    </Button>
  </div>
);

export default ValueCard;
