import { userActions } from '@/actions';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button } from '@/components/elements/forms/buttons';
import { isServer, promiseWrapper, trackMpEvent, trackPage } from '@/helpers';
import { useAppSelector } from '@/hooks';
import { bookServices } from '@/services';
import { _s } from '@/locale';
import { Dispatch, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppointmentsList from './AppointmentsList';
import BookingFilter, { filterOptions } from './BookingFilter';

async function fetchAndSetAppointments(
  {
    status,
    past,
    lastBooking,
    currentItems,
  }: { status: string; past: boolean; lastBooking: number; currentItems: any[] },
  dispatch: Dispatch<AppointmentAction>,
): Promise<void> {
  dispatch({ type: 'FETCH_APPOINTMENTS', isSilent: !!lastBooking });
  const { data, error } = await promiseWrapper(bookServices.appointments({ status, past: past ? 1 : 0, lastBooking }));
  if (error) {
    dispatch({ type: 'FETCH_APPOINTMENTS_ERROR' });
  } else {
    dispatch({
      type: 'FETCH_APPOINTMENTS_SUCCESS',
      payload: lastBooking ? [...currentItems, ...data] : data,
      isSilent: !!lastBooking,
    });
    if (data?.length < 10) {
      dispatch({ type: 'HIDE_LOAD_MORE' });
    } else {
      dispatch({ type: 'SHOW_LOAD_MORE' });
    }
  }
}

function setActiveBookings(user, appointments, appDispatch) {
  user.activeBookings = appointments.reduce((filtered, item) => {
    if (Number(item.status) === 1) filtered.push(item.start);
    return filtered;
  }, []);
  appDispatch(userActions.updateUser(user, true));
}

const getScreenName = (past: AppointmentsTabProps['past']) => (past ? 'my_bookings_finished' : 'my_bookings_upcoming');

type AppointmentState = {
  appointments: any[];
  loading: boolean;
  loadMore?: boolean;
  hideButton?: boolean;
  status: any;
};

type AppointmentAction =
  | { type: 'FETCH_APPOINTMENTS'; isSilent: boolean }
  | { type: 'FETCH_APPOINTMENTS_SUCCESS'; payload: any[]; isSilent?: boolean }
  | { type: 'FETCH_APPOINTMENTS_ERROR' }
  | { type: 'CLEAR_APPOINTMENTS' }
  | { type: 'CHANGE_STATUS'; payload: string }
  | { type: 'LOAD_MORE' }
  | { type: 'CLEAR_LOAD_MORE' }
  | { type: 'SHOW_LOAD_MORE' }
  | { type: 'HIDE_LOAD_MORE' };

const appointmentsReducer = (state: AppointmentState, action: AppointmentAction) => {
  switch (action.type) {
    case 'FETCH_APPOINTMENTS':
      return {
        ...state,
        loading: action.isSilent ? false : true,
      };
    case 'FETCH_APPOINTMENTS_SUCCESS':
      return {
        ...state,
        loading: false,
        appointments: action.payload,
      };
    case 'FETCH_APPOINTMENTS_ERROR':
      return {
        ...state,
        loading: false,
        appointments: [],
      };
    case 'CLEAR_APPOINTMENTS':
      return {
        ...state,
        appointments: [],
      };
    case 'CHANGE_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'LOAD_MORE':
      return {
        ...state,
        loadMore: true,
      };
    case 'CLEAR_LOAD_MORE':
      return {
        ...state,
        loadMore: false,
      };
    case 'HIDE_LOAD_MORE':
      return {
        ...state,
        hideButton: true,
      };
    case 'SHOW_LOAD_MORE':
      return {
        ...state,
        hideButton: false,
      };

    default:
      return state;
  }
};

const getInitialStatus = (filter: string) => {
  if (isServer) return filterOptions[0];
  const selected = filterOptions.filter((item) => item.trackingValue === localStorage.getItem(filter));
  return selected[0] || filterOptions[0];
};

type AppointmentsTabProps = {
  filter: string;
  past?: boolean;
};

const AppointmentsTab = ({ filter, past }: AppointmentsTabProps) => {
  const location = useLocation();
  const [state, dispatch] = useReducer(appointmentsReducer, {
    appointments: [],
    loading: true,
    hideButton: true,
    status: getInitialStatus(filter),
  });

  const { user } = useAppSelector((state) => state.users);
  const appDispatch = useDispatch();

  useEffect(() => {
    fetchAndSetAppointments(
      { status: state.status.value, past: !!past, lastBooking: undefined, currentItems: [] },
      dispatch,
    );
    return () => {
      dispatch({ type: 'CLEAR_APPOINTMENTS' });
    };
  }, [state.status, past]);

  useEffect(() => {
    if (!past && state.appointments.length) {
      setActiveBookings(user, state.appointments, appDispatch);
    }
  }, [state.appointments]);

  useEffect(() => {
    trackPage();
    trackMpEvent('screen_shown', {
      screen_name: getScreenName(past),
    });
  }, [past]);

  const handleStatusChange = (option: any) => {
    dispatch({ type: 'CHANGE_STATUS', payload: option });
    localStorage.setItem(filter, option.trackingValue);
    trackMpEvent('my_bookings_filter_applied', {
      screen_name: getScreenName(past),
      filter_option: option.trackingValue,
    });
  };

  const handleLoadMore = async () => {
    dispatch({ type: 'LOAD_MORE' });
    const { start } = (state.appointments || [])[state?.appointments?.length - 1];
    fetchAndSetAppointments(
      { status: state.status.value, past: !!past, lastBooking: start, currentItems: state.appointments },
      dispatch,
    ).then(() => {
      dispatch({ type: 'CLEAR_LOAD_MORE' });
    });
  };

  return (
    <div className="relative min-h-[100px] w-full">
      {state.loading ? (
        <LoadingPlaceHolder />
      ) : (
        <div>
          {(state.appointments.length > 0 || state.status !== filterOptions[0]) && (
            <div className="mb-lg w-full lg:w-1/3">
              <BookingFilter onChange={handleStatusChange} value={state.status} />
            </div>
          )}
          <AppointmentsList appointments={state.appointments} past={past} />
          {!state.hideButton && !!state.appointments.length && (
            <div className="flex justify-center pb-2">
              <Button
                loading={!!state.loadMore}
                variant="primary"
                size="sm"
                onClick={() => handleLoadMore()}
                className="">
                {_s('loadMore')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AppointmentsTab;
