import Label from '@/components/elements/Label/Label';
import { ChevronIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { isMobile, scrollAccordionContentInView, startOfDay, trackMpEvent } from '@/helpers';
import { themed } from '@/helpers/theme';
import useMobileView from '@/hooks/useMobileView';
import { _s } from '@/locale';
import { Fragment, useRef, useState } from 'react';
import Tooltip from '../Tooltip';
import Bundle, { PlaceBundle } from './Bundle';

type Place = {
  id: number;
  bookingBundles?: PlaceBundle[];
};
type BundlesProps = {
  place: Place;
  isOpen?: boolean;
  date?: number;
  source?: string;
};

const baseTranslationKey = 'components.modules.salon.Bundles';

const Bundles = ({ place, isOpen, date, source }: BundlesProps) => {
  const [expanded, setExpanded] = useState(!!(isOpen ?? true));
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTriggerRef = useRef<HTMLButtonElement>(null);
  const { isMobileView } = useMobileView();
  const collapseClass = expanded ? 'block' : 'hidden';

  if (!place?.bookingBundles?.length) {
    return null;
  }

  if (!date) {
    date = startOfDay();
  }

  const handleCollapse = (e) => {
    const newExpanded = !expanded;
    if (expanded) {
      trackMpEvent('bundles_category_expanded', {
        company_id: place.id,
        screen_name: source ? source : 'company_details',
      });
      setExpanded(newExpanded);
      scrollAccordionContentInView(e.target);
    } else {
      setExpanded(newExpanded);
    }
  };

  const bundles = place.bookingBundles.map((item) => (
    <Fragment key={item.id}>
      <Bundle bundle={item} place={place} />
    </Fragment>
  ));

  const handleOnMouseEnter = () => {
    if (localStorage.getItem('hideBundleNoticeBanner') === 'true') {
      return;
    }
    setShowTooltip(true);
  };

  const handleTooltipCloseClick = (e) => {
    localStorage.setItem('hideBundleNoticeBanner', 'true');
    setShowTooltip(false);
  };

  const handleTooltipClickOutside = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setShowTooltip(false);
  };

  return (
    <div className="relative">
      {showTooltip && (
        <Tooltip
          onClose={handleTooltipCloseClick}
          triggerRef={tooltipTriggerRef}
          onClickOutside={handleTooltipClickOutside}>
          <div className="p-lg space-y-md">
            <div className="flex items-start justify-between">
              <span className="max-w-[200px] text-xl font-bold text-white" id="popover-title">
                {_s(`${baseTranslationKey}.NoticePopover.title`)}
              </span>
              <button onClick={handleTooltipCloseClick} aria-label={_s('close')}>
                <Icon variant="close-alt" color="white" />
              </button>
            </div>
            <p className="text-black-300" id="popover-content">
              {_s(`${baseTranslationKey}.NoticePopover.description`)}
            </p>
          </div>
        </Tooltip>
      )}
      <div
        className={`item  ${expanded && !isMobile() ? 'mb-4' : ''}`}
        {...(!isMobileView && { onMouseEnter: handleOnMouseEnter })}>
        <div
          onClick={handleCollapse}
          className={`mb-[2px] flex cursor-pointer items-center justify-between p-3 ${themed('bg-black-50', '')}`}>
          <h3 className="font-semibold">{_s('buyBundles.bundles')}</h3>
          <span className="ml-sm relative flex flex-1">
            <button
              ref={tooltipTriggerRef}
              className="focus-visible:outline-black-900 flex items-center justify-center rounded-sm focus:outline-none focus-visible:outline"
              aria-label={_s(`${baseTranslationKey}.showNotice.ariaLabel`)}
              aria-expanded={showTooltip}
              aria-controls="popover"
              onClick={(e) => {
                e.stopPropagation();
                if (!showTooltip) {
                  trackMpEvent('bundles_news_label_clicked', {});
                }
                setShowTooltip((prev) => !prev);
              }}>
              <Label variant="novelty" />
            </button>
          </span>
          <span
            className={`flex items-center justify-center rounded-full p-2 ${
              expanded ? 'bg-[#ADB4C4]' : themed('bg-primary', 'bg-sm_primary')
            }`}>
            <ChevronIcon className={`h-[8px] w-[8px] text-white ${expanded ? 'rotate-180' : ''}`} />
          </span>
        </div>

        <div className={'accordion-content ' + collapseClass} role="tabpanel">
          <ul>{bundles}</ul>
        </div>
      </div>
    </div>
  );
};

export default Bundles;
