const initialState: {
  [key: string]: { value: string; payload: unknown };
} = {};

export function flags(state = initialState, action) {
  switch (action.type) {
    default: {
      return state;
    }
  }
}
