import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { CHECKOUT_PAYMENT_METHOD } from '@/constants/checkout';
import { saveBookingTrackingProps } from '@/helpers/checkout';
import { _s } from '@/locale';
import { swishServices } from '@/services';
import { CheckoutTracking } from '@/types/analytics';
import { ErrorResponse } from '@/types/api/services/schema';
import { SwishPaymentResponse, keepAliveNotificationSchema, swishNotificationSchema } from '@/types/api/services/swish';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseTranslationKey } from './ValidateSwishPaymentRedirect';

export type SwishSuccessRedirectData = {
  paymentMethod: typeof CHECKOUT_PAYMENT_METHOD.SWISH;
  responseData: SwishPaymentResponse;
};

type ResourceType = 'booking' | 'bundle';

const SERVER_ERROR = { message: 'Could not get status of the swish payment', status: 500 };
const FAILED_PAYMENT_ERROR = {
  message: 'The swish payment was not successful',
  status: 400,
  clientError: _s(`${baseTranslationKey}.failedPaymentError`),
};

export const PAYMENT_REDIRECT_LOCALSTORAGE_KEY = 'payment-redirect-swish';

type SubmitSwishRedirectContext = {
  payment: SwishPaymentResponse;
  eventSource: EventSource;
  trackingProps: CheckoutTracking.Booking | CheckoutTracking.Bundle;
  successCallback: (props: SwishSuccessRedirectData) => void;
  errorCallback: (error: ErrorResponse) => void;
};

export type SavedSwishPaymentCheckoutState = {
  trackingProps: CheckoutTracking.Booking | CheckoutTracking.Bundle;
  payment: SwishPaymentResponse;
};

function getSavedCheckoutStateFromLocalStorage(): SavedSwishPaymentCheckoutState | null {
  const savedCheckoutState: SavedSwishPaymentCheckoutState = JSON.parse(
    localStorage.getItem(PAYMENT_REDIRECT_LOCALSTORAGE_KEY),
  );
  savedCheckoutState && localStorage.removeItem(PAYMENT_REDIRECT_LOCALSTORAGE_KEY);

  return savedCheckoutState;
}

export function saveSwishCheckoutStateToLocalStorage(state: SavedSwishPaymentCheckoutState) {
  localStorage.setItem(PAYMENT_REDIRECT_LOCALSTORAGE_KEY, JSON.stringify(state));
}

export function removeSwishCheckoutStateToLocalStorage() {
  localStorage.removeItem(PAYMENT_REDIRECT_LOCALSTORAGE_KEY);
}

/**
 * If we are redirected from swish we need to check if the payment was successful or not and redirect
 * the user accordingly. The actual booking has already been submitted and will be completed or cancelled
 * in the backend.
 */
async function handleValidateSwishPayment(context: SubmitSwishRedirectContext) {
  const { payment, trackingProps, eventSource } = context;
  if (eventSource) {
    eventSource.onmessage = (e) => {
      const swishNotification = JSON.parse(e.data);
      const validatedKeepAliveNotification = keepAliveNotificationSchema.safeParse(swishNotification);

      if (validatedKeepAliveNotification.success === true && validatedKeepAliveNotification.data.keepAlive) {
        return;
      }

      const validatedSwishNotification = swishNotificationSchema.safeParse(swishNotification);

      if (validatedSwishNotification.success === false) {
        context.errorCallback(SERVER_ERROR);
        eventSource.close();
        return;
      }

      const { success, id } = validatedSwishNotification.data;

      if (!success) {
        context.errorCallback(FAILED_PAYMENT_ERROR);
      } else {
        saveBookingTrackingProps(trackingProps);
        context.successCallback({
          paymentMethod: CHECKOUT_PAYMENT_METHOD.SWISH,
          responseData: { ...payment, id },
        });
      }

      eventSource.close();
    };

    eventSource.onerror = () => {
      context.errorCallback(SERVER_ERROR);
      eventSource.close();
    };
  }
}

const getRedirectPathname = (
  type: ResourceType,
  id: number,
  placeId?: number,
): { successPathname: string; failurePathname: string } => {
  switch (type) {
    case 'booking':
      return {
        successPathname: '/booking/confirmation',
        failurePathname: `/booking/checkout/${id}`,
      };
    case 'bundle':
      return {
        successPathname: '/bundles/confirmation',
        failurePathname: `/bundles/checkout/${placeId}/${id}`,
      };
  }
};

const useValidateSwishRedirect = () => {
  const [checkoutState] = useState<SavedSwishPaymentCheckoutState | null>(getSavedCheckoutStateFromLocalStorage());
  const [redirect, setRedirect] = useState<any | false>(false);
  const [isNewBrowser, setIsNewBrowser] = useState<boolean>(false);
  const routeMatch = useRouteMatch();

  const { id, type, placeId } = routeMatch.params;
  const pathname = getRedirectPathname(type, id, placeId);
  const { trackingProps, payment } = checkoutState || {};

  useEffect(() => {
    if (!payment) {
      setIsNewBrowser(true);
      return;
    }

    const successCallback = ({ responseData }: SwishSuccessRedirectData) => {
      setRedirect({
        pathname: pathname.successPathname,
        state: { selectedPaymentMethod: { type: CHECKOUT_PAYMENT_METHOD.SWISH } },
        search: (() => {
          switch (type) {
            case 'booking':
              return `?bookingId=${responseData.id}&type=swishActivatePayment`;
            case 'bundle':
              return `?bundleId=${responseData.id}`;
          }
        })(),
      });
    };

    const errorCallback = ({ status, clientError }: { status: number; clientError?: string }) => {
      // This means we have dont know the status of the booking or payment
      if (status > 500) {
        toast(({ closeToast }) => (
          <Snackbar label={_s(`${baseTranslationKey}.unknownError`)} type="danger" onClose={closeToast} />
        ));
      } else {
        toast(({ closeToast }) => <Snackbar label={clientError} type="danger" onClose={closeToast} />);
      }

      setRedirect({
        pathname: pathname.failurePathname,
        search: '',
        state: {},
      });
    };

    const context: SubmitSwishRedirectContext = {
      trackingProps,
      payment,
      errorCallback,
      successCallback,
      eventSource: swishServices.waitForSwishNotification(payment.channel),
    };

    handleValidateSwishPayment(context);

    return () => {
      context.eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { redirect, isNewBrowser };
};

export default useValidateSwishRedirect;
