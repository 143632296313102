import { h5Class } from '@/components/elements/content';
import { Fab } from '@/components/elements/forms/buttons';
import Icon from '@/components/icons/Icon';
import { ModalContent, ModalDialog } from '@/components/modules/modals';
import { BeautyCategory, allBeautyCategories, beautyCategories } from '@/constants/beautyCategories';
import { isServer, isSistaminuten, trackMpEvent } from '@/helpers';
import { __, _s } from '@/locale';
import { useState } from 'react';
import Category from './Category';
import CategoryAllButton from './CategoryAllButton';

const handleSelfTestClick = () => {
  trackMpEvent('categories_bokahem_click', { screen_name: 'home' });
};

const BOKA_ONLY_CATEGORIES = ['hemstadning', 'fillers3', 'product-giftcard', 'product-wellnesscard'];

const showCategory = (category: BeautyCategory): boolean =>
  isSistaminuten() ? !BOKA_ONLY_CATEGORIES.includes(category.img) : true;

const CategoriesContainer = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const categoriesList = beautyCategories(handleSelfTestClick).map((category, i) =>
    showCategory(category) ? <Category key={i} dataset={i} source="home" category={category} /> : null,
  );

  const allCategories = [...allBeautyCategories].sort((a, b) => __(a).localeCompare(__(b)));

  const modal = isServer ? null : (
    <ModalDialog
      isOpen={showModal}
      onRequestClose={() => setShowModal(false)}
      appElement={document.getElementById('root')}>
      <ModalContent size="md">
        <div className="border-black-100 relative w-full flex-col justify-start border bg-white bg-clip-padding sm:rounded-lg">
          <div className="z-10002 fixed top-0 flex h-16 w-full items-center justify-center bg-white shadow sm:relative sm:bg-transparent sm:shadow-none">
            <h3 className={`${h5Class} !m-0`}>{_s('allCategories')}</h3>
            <Fab
              icon={<Icon variant="close" />}
              onClick={() => setShowModal(false)}
              size="md"
              variant="link"
              className="absolute right-4"
            />
          </div>
          <div className="h-16 sm:hidden"></div>
          <div className="p-4">
            {allCategories.map((keyword, i) => (
              <Category
                key={i}
                dataset={i}
                category={{ name: __(keyword), q: keyword.toLowerCase() }}
                source="services_categories"
              />
            ))}
          </div>
        </div>
      </ModalContent>
    </ModalDialog>
  );

  const headingDesc = isSistaminuten()
    ? __('homepageSistaminutenHeadingDescription')
    : __('homepageHeadingDescription');

  return (
    <div>
      <h2 className="pt-lg text-xl font-semibold tracking-normal sm:text-3xl">{_s('homepageHeading')}</h2>
      <p className="mb-6 max-w-3xl sm:mb-2 sm:text-lg">{headingDesc}</p>
      <div className="-mx-1 flex flex-wrap gap-y-2 sm:-mx-2 lg:-mx-4">
        {categoriesList}
        <CategoryAllButton onClick={() => setShowModal(true)} />
      </div>
      {modal}
    </div>
  );
};

export default CategoriesContainer;
