import 'react-toastify/dist/ReactToastify.minimal.css';
import './App.scss';
import './styles/global/_actionsheet.scss';
import './styles/global/_autosuggest.scss';
import './styles/global/_base.scss';
import './styles/global/_forms.scss';
import './styles/global/_tag.scss';
import './styles/global/_tooltip.scss';
import './styles/global/_unhide.scss';
import './styles/global/_utils.scss';
import './styles/tailwind.css';
import './styles/vendors/react-toastify/main.scss';

import NpsModal from '@/components/modules/modals/NpsModal';
import { Suspense, useState } from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import useStartupEvents from './App.hooks';
import AppPromotionCard from './components/elements/Card/AppPromotionCard';
import LoadingPlaceHolder from './components/elements/LoadingPlaceholder';
import { AlertPortal } from './components/modules/alert/AlertPortal';
import AppRouter from './components/modules/app/AppRouter';
import AppRoutes from './components/modules/app/AppRoutes';
import { AllowCookies } from './components/modules/modals';
import LoginModal from './components/modules/modals/LoginModal';
import UserProfileOnboarding from './components/modules/modals/UserProfileOnboarding';
import { Z_INDEX } from './constants/styleConstants';
import { getCookie, isSistaminuten, renameCookie } from './helpers';
import { useAppSelector } from './hooks';

export type AppProps = {
  isMobile?: boolean;
  location?: any;
  context: any;
};

const App = ({ isMobile = false }: AppProps) => {
  // state
  const [visible, setVisible] = useState(true);
  const loading = useAppSelector((state) => state.loading);
  const { nps, redirect, login, userProfileOnboarding } = useAppSelector((state) => state.modal);

  // effects
  useStartupEvents(isMobile, setVisible);

  // computed
  const loadingbarProps = { color: isSistaminuten() ? '#ffcb2e' : '#23A994', progress: loading.show ? 50 : 100 };
  const cookieAcceptedOldName = Boolean(getCookie('CookiePreferencesMP'));
  const cookieAccepted = Boolean(getCookie('BokadirektCookiePreferencesMP'));
  const hideCookiesModal = cookieAccepted || cookieAcceptedOldName;

  const showLoginModal = login?.show;
  const showCompleteProfileModal = !showLoginModal && userProfileOnboarding?.show;
  const showAppPromotionCard = !showLoginModal && !showCompleteProfileModal && hideCookiesModal;
  const showNpsModal = nps?.show && hideCookiesModal && !showLoginModal && !showCompleteProfileModal;

  if (cookieAcceptedOldName) renameCookie('CookiePreferencesMP', 'BokadirektCookiePreferencesMP');

  return (
    <div className="App" style={{ visibility: visible ? 'visible' : 'hidden' }}>
      {!loading.loadingBarHidden && <LoadingBar transitionTime={300} {...loadingbarProps} />}

      {/* Modals */}
      {!hideCookiesModal && <AllowCookies />}
      {showAppPromotionCard && <AppPromotionCard />}
      {showLoginModal && <LoginModal isOpen source={login?.triggerSource} />}
      {showCompleteProfileModal && <UserProfileOnboarding isOpen />}
      {showNpsModal && <NpsModal isOpen />}

      {/* Router */}
      <Suspense fallback={<LoadingPlaceHolder className="fixed h-screen" />}>
        <AppRoutes redirect={redirect} />
      </Suspense>

      {/* Portals */}
      <AlertPortal />
      <ToastContainer
        limit={1}
        draggable={false}
        closeOnClick={false}
        position={toast.POSITION.BOTTOM_CENTER}
        autoClose={4000}
        closeButton={false}
        transition={Slide}
        style={{ zIndex: Z_INDEX.SNACKBAR }}
      />
    </div>
  );
};

export default function AppWithRouter(props: AppProps) {
  return (
    <AppRouter {...{ location: props.location, context: props }}>
      <App {...props} />
    </AppRouter>
  );
}
