import { bookActions, loadingActions, userActions } from '@/actions';
import breakpoints from '@/breakpoints';
import LoadingPlaceHolder from '@/components/elements/LoadingPlaceholder';
import { Button, Fab } from '@/components/elements/forms/buttons';
import { ChevronIcon } from '@/components/icons';
import Icon from '@/components/icons/Icon';
import { ModalContent, ModalDialog } from '@/components/modules/modals';
import WaitlistModal from '@/components/modules/modals/WaitlistModal';

import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import ConfirmChangeBookingTimeModal from '@/components/modules/modals/ConfirmChangeBookingTime/ConfirmChangeBookingTime';
import { config } from '@/config';
import { bookConstants } from '@/constants';
import { EVENT_NAME } from '@/constants/analytics';
import {
  firstWeekDay,
  getCalendarWeek,
  getDayInfo,
  getPlaceTimezone,
  getWeeksFromFirstDay,
  hasDynamicPricing,
  hoursFromSeconds,
  isMobile,
  isServer,
  promiseWrapper,
  startOfDay,
  trackMpEvent,
} from '@/helpers';
import withAmplitudeExperiment from '@/hoc/withAmplitudeExperiment';
import withMobileView from '@/hoc/withMobileView';
import withWalletPayment from '@/hoc/withWalletPayment';
import { __ } from '@/locale';
import { bookServices as bookServicesOld } from '@/services';
import { bookServices } from '@/services/bookServicesTs';
import { changeBookingTimeResponseSchema } from '@/types/api/services/booking';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { LoginTeaser } from './';
require('moment-timezone');

function convertToUTCPlusOffset(dateTimeString) {
  const utcOffset = moment.parseZone(dateTimeString).utcOffset() / 60;
  return moment(dateTimeString).utc(utcOffset);
}

class WeekPicker extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.blockNavigation = false;

    moment.tz.setDefault(getPlaceTimezone(props.place));

    this.months = config.monthsShort;
    this.days = config.weekDaysShort;

    this.setWeeksCount(props);
    const weeks = this.getWeeks();

    let day = startOfDay();
    if (props.time && props.time.timestamp && props.time.timestamp > day) {
      day = props.time.timestamp;
    }

    const dateRelated = this.getSelectedDateInfo(day, weeks);

    this.state = {
      loading: true,
      weeks: weeks,
      firstDay: getDayInfo(startOfDay()), // today
      hour: props.time && props.time.selected ? props.time.selected : '',
      weekData: {},
      intervals: [],
      weekDays: this.getWeekDays(dateRelated.startFrom, weeks),
      ...dateRelated,
      showLogin: false,
      changeTimeAvailability: Boolean(props.changeTimeBookingId),
    };

    if (!isServer) window.scrollTo(0, 0);

    props.dispatch(
      bookActions.getAvailability(
        props.services,
        this.prepDate(dateRelated.startFrom),
        props.employee || 0,
        'loadHours',
        true,
        props.changeTimeBookingId,
      ),
    );

    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
    this.closeSubscribe = this.closeSubscribe.bind(this);
    this.pickDay = this.pickDay.bind(this);
  }

  getWeekDays(startFrom, weeks) {
    const days = [];
    days.push(this.getDays(startFrom));
    return days;
  }

  componentDidUpdate(prevProps, prevState) {
    let nextState = { clicked: false };

    if (this.props.show !== prevProps.show && this.props.show) {
      this.setState(nextState);
      return;
    }

    if (
      this.props.set !== prevProps.set ||
      this.props.availability !== prevProps.availability ||
      this.props.time !== prevProps.time ||
      this.props.employee !== prevProps.employee
    ) {
      const time = this.props.time && this.props.time.timestamp;
      if (time) {
        const dateRelated = this.getSelectedDateInfo(time, this.state.weeks);
        nextState = { ...nextState, ...dateRelated };
        nextState.loading = false;
        nextState.weekData = (this.props.availability && this.props.availability.weekDays) || {};

        const mondayDateTime = moment(new Date(time)).startOf('isoWeek');
        /*
          We don't want to override the data from previous erpOverviews
          So in the below block we make sure to add the old and new data together
        */
        const newErpOverview = this.props.availability && this.props.availability.fromErpOverview;
        let oldErpOverview = this.state.availabilityOverview ? { ...this.state.availabilityOverview } : null;
        if (newErpOverview && oldErpOverview) {
          if (oldErpOverview.minDateChecked === newErpOverview.minDateChecked) {
            oldErpOverview.datesWithOpeningHours = [...newErpOverview.datesWithOpeningHours];
          } else {
            oldErpOverview.datesWithOpeningHours = [
              ...oldErpOverview.datesWithOpeningHours,
              ...newErpOverview.datesWithOpeningHours,
            ].filter((a, b, c) => c.indexOf(a) === b);
          }
          oldErpOverview.maxDateChecked = '' + newErpOverview.maxDateChecked;
          oldErpOverview.firstAvailableDate = newErpOverview.firstAvailableDate;
        } else if (newErpOverview) {
          oldErpOverview = newErpOverview;
        }
        if (
          oldErpOverview &&
          oldErpOverview.firstAvailableDate &&
          moment(oldErpOverview.firstAvailableDate) < mondayDateTime
        ) {
          const datesInFuture = oldErpOverview.datesWithOpeningHours.filter((d) => moment(d) > mondayDateTime);
          if (datesInFuture && datesInFuture.length > 0) {
            oldErpOverview.firstAvailableDate = datesInFuture[0];
          }
        }

        nextState.availabilityOverview = oldErpOverview;

        /*
          This check is needed for when we fetch availabilityOvers dynamically after having scrolled to the "last week"
          At this point the {time} won't change, and thus we shouldn't change intervals.
        */
        const intervalDateTime = (this.state.intervals[0] && moment(parseInt(this.state.intervals[0].day))) || null;
        nextState.intervals = !mondayDateTime.isSame(intervalDateTime, 'day')
          ? this.dayIntervals(nextState.weekData, dateRelated.startFrom)
          : this.state.intervals;
        nextState.weekDays = this.getWeekDays(dateRelated.startFrom, this.state.weeks);
        // nextState.disableNext = !this.hasAnAvailableTime(nextState.intervals, this.props.availability);
        if (!this.props.set && prevProps.employee === this.props.employee) {
          trackMpEvent('week_changed', {
            screen_name: 'booking_step_choose_time',
            available_slots: this.currentWeekHasNoSlots(nextState.intervals) ? 'no' : 'yes',
          });
        }
      }

      this.setState(nextState, () => {
        this.setWeeksCount(this.props);
      });
    } else {
      this.setWeeksCount(this.props);
    }

    const selectedEmployee = this.props.place.employees.find((e) => e.id === this.props.employee);

    // return selected employee bookahead or the bookAhead max of all employees
    const bookAhead = selectedEmployee
      ? selectedEmployee.about.settings.bookAhead
      : this.props.place.employees.reduce((acc, employee) => {
          if (employee.about.settings.bookAhead !== undefined) {
            return employee.about.settings.bookAhead > acc ? employee.about.settings.bookAhead : acc;
          }
          return acc;
        }, 0);

    /*
      If we have reached the end of weeks with available times
      We fetch a new override from the last maxCheckedDate returned from the API
    */
    if (
      !prevState.disableNext &&
      this.state.disableNext &&
      !!this.state.availabilityOverview &&
      moment(this.state.availabilityOverview.maxDateChecked).diff(moment().startOf('isoWeek'), 'weeks') <= bookAhead
    ) {
      this.props.dispatch(
        bookActions.getAvailability(
          this.props.services,
          this.prepDate(moment(this.state.availabilityOverview.maxDateChecked).unix() * 1000),
          this.props.employee || 0,
          null,
          false,
          this.props.changeTimeBookingId,
        ),
      );
    }

    /*
      If we tried to fetch a new range in the future, and nothing was returned
      We try a new range (up until 13 (i pull this number out of ...thin air..., we can change it) months ahead)
      Might need a small fallback time to not DOS ourself also
     */
    if (
      this.state.availabilityOverview &&
      this.state.availabilityOverview.datesWithOpeningHours.length === 0 &&
      (!prevState.availabilityOverview ||
        (prevState.availabilityOverview &&
          prevState.availabilityOverview.maxDateChecked < this.state.availabilityOverview.maxDateChecked)) &&
      moment(this.state.availabilityOverview.maxDateChecked).diff(
        moment(this.state.availabilityOverview.minDateChecked),
        'months',
      ) < 14 &&
      moment(this.state.availabilityOverview.maxDateChecked).diff(moment().startOf('isoWeek'), 'weeks') <= bookAhead
    ) {
      this.props.dispatch(
        bookActions.getAvailability(
          this.props.services,
          this.prepDate(moment(this.state.availabilityOverview.maxDateChecked).unix() * 1000),
          this.props.employee || 0,
          null,
          false,
          this.props.changeTimeBookingId,
        ),
      );
    }
  }

  prepDate(date) {
    return date < startOfDay() ? startOfDay() : date;
  }

  getWeeks() {
    let startingFrom = firstWeekDay();

    let i = this.weeksCount;
    const weeks = [];
    while (i) {
      weeks.push(startingFrom);
      startingFrom += 3600 * 24 * 7 * 1000;
      i--;
    }
    return weeks;
  }

  getSelectedDateInfo(timestamp, weeks) {
    let startingFrom = firstWeekDay(timestamp);
    let selectedWeek = weeks.indexOf(startingFrom);
    const selectedDate = moment(startingFrom);

    const data = {
      selectedWeek: selectedWeek,
      startFrom: startingFrom,
      year: this.getYearLabel(startingFrom),
      weekOfYear: selectedDate.isoWeek(),
      selectedDay: getDayInfo(startingFrom),
      disablePrev: weeks[0] === startingFrom ? true : false,
      disableNext: weeks[weeks.length - 1] === startingFrom,
    };
    return data;
  }

  handleNextSwipe() {
    this.handleNext();
  }

  handleNextClick() {
    this.handleNext();
  }

  handleNext() {
    if (this.blockNavigation || this.state.disableNext) return false;
    this.blockNavigation = true;

    const selectedDate = this.state.startFrom + 3600 * 24 * 7 * 1000;
    const dateRelated = this.getSelectedDateInfo(selectedDate, this.state.weeks);

    this.getWeekData(this.prepDate(dateRelated.startFrom)).then((data) => {
      this.blockNavigation = false;
    });
  }

  handlePrevSwipe() {
    this.handlePrev();
  }

  handlePrevClick() {
    this.handlePrev();
  }

  handlePrev() {
    if (this.blockNavigation || this.state.disablePrev) return false;
    this.blockNavigation = true;

    const selectedDate = this.state.startFrom - 3600 * 24 * 7 * 1000;
    const dateRelated = this.getSelectedDateInfo(selectedDate, this.state.weeks);

    this.getWeekData(this.prepDate(dateRelated.startFrom)).then((data) => {
      this.blockNavigation = false;
    });
  }

  currentWeekHasNoSlots(intervals) {
    let allEmpty = true;
    intervals.forEach((weekDay) => {
      if (Boolean(weekDay.options.length)) {
        weekDay.options.forEach((available) => {
          allEmpty = false;
        });
      }
    });
    return allEmpty;
  }

  getWeekData(date) {
    const { employee, dispatch, place, services, changeTimeBookingId } = this.props;
    const { availabilityOverview } = this.state;
    const firstAvailableDate =
      availabilityOverview && availabilityOverview.firstAvailableDate
        ? moment(availabilityOverview.firstAvailableDate).unix() * 1000
        : null;
    dispatch(loadingActions.show('loadHours'));
    return bookServicesOld
      .getWeekDays(services, date, employee, firstAvailableDate, changeTimeBookingId)
      .then((data) => {
        dispatch(bookActions.pickDate(date, data.weekDays || {}, data.fromErp || []));
        dispatch(bookActions.setFirstDay(data));
        dispatch(bookActions.setCampaigns(services, place.campaigns, date));
        dispatch(loadingActions.hide());

        if (data.weekDays) {
          if (data.fromErpOverview) {
            this.setState({ availabilityOverview: data.fromErpOverview });
          }
          return data.weekDays;
        }

        return {};
      });
  }

  getDays(startingFrom) {
    const display = [];
    let i = 7;
    while (i) {
      const day = getDayInfo(startingFrom);
      day.timestamp = startingFrom;
      display.push(day);
      startingFrom += 86400 * 1000;
      i--;
    }

    return display;
  }

  getYearLabel(day) {
    return getCalendarWeek(day);
  }

  pickDay(selectedDay) {
    if (this.state.clicked) {
      return null;
    }

    trackMpEvent('next_available_time_clicked', {
      screen_name: 'booking_step_choose_time',
    });

    const { dispatch, services, employee, place } = this.props;

    const selected = startOfDay(selectedDay);
    const dateRelated = this.getSelectedDateInfo(selected, this.state.weeks);
    this.setState({ clicked: true, ...dateRelated });

    dispatch(
      bookActions.getAvailability(
        services,
        dateRelated.startFrom,
        employee || 0,
        'loadHours',
        true,
        this.props.changeTimeBookingId,
      ),
    );
    dispatch(bookActions.setCampaigns(services, place.campaigns, dateRelated.startFrom));
  }

  dayIntervals(week, selected, set, employee) {
    const intervals = [];

    for (let day in week) {
      intervals.push({ day: day, options: this.makeOption(week[day].periods) });
    }

    if (set) {
      this.setState({ intervals: intervals });
    }

    return intervals;
  }

  componentDidMount() {
    this._isMounted = true;
    // here we make sure we are logged in or not,
    // so we have an accurate user throughout booking process
    this.props.dispatch(userActions.profile(null, null, false));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hasAnAvailableTime(intervals = [], availability) {
    let allEmpty = true;

    intervals.forEach((weekDay) => {
      if (Boolean(weekDay.options.length)) {
        weekDay.options.forEach((available) => {
          allEmpty = false;
        });
      }
    });

    if (allEmpty && availability && availability.firstDay && availability.firstDay.none) {
      return false;
    } else {
      return true;
    }
  }

  setWeeksCount(props) {
    const { place, lastWorkingDays } = props;
    const { about } = place || {};
    const { book } = about || {};
    let newWeeksCount = 1;
    const { availabilityOverview } = this.state || {};
    if (availabilityOverview) {
      const { datesWithOpeningHours } = availabilityOverview || {};
      if (datesWithOpeningHours && datesWithOpeningHours.length > 0) {
        newWeeksCount =
          Math.abs(
            moment()
              .startOf('isoWeek')
              .diff(moment(datesWithOpeningHours[datesWithOpeningHours.length - 1]), 'weeks'),
          ) + 1;
      } else {
        // This happens when switching week, we only fetch the overview every ~8 weeks
        // So we keep it the same if the old hasn't changed
        newWeeksCount = this.weeksCount;
      }
    } else {
      if (lastWorkingDays && lastWorkingDays.last && place.source !== 2) {
        newWeeksCount = getWeeksFromFirstDay(lastWorkingDays.last) + 1;
      } else if (place.source === 2) {
        newWeeksCount = lastWorkingDays.bookAhead;
      } else {
        newWeeksCount = book && book.bookAhead ? book.bookAhead : 16;
      }
    }

    if (newWeeksCount !== this.weeksCount) {
      this.weeksCount = newWeeksCount;
      if (this._isMounted) {
        const weeks = this.getWeeks();
        const dateRelated = this.getSelectedDateInfo(this.state.startFrom, weeks);
        this.setState(
          {
            weeks: weeks,
            weekDays: this.getWeekDays(dateRelated.startFrom, weeks),
            ...dateRelated,
          },
          () => {
            // if(this.slider) {
            //   this.slider.slickGoTo((this.state.selectedWeek)*7, false);
            // }
          },
        );
      }
    }
  }

  makeOption(options) {
    const { services } = this.props;
    const ret = [];
    for (const op in options) {
      const span = options[op].hour;
      const time = hoursFromSeconds(span.from);
      ret.push({
        label: time,
        timestamp: span.from,
        employees: options[op].employee,
        disabled: options[op].disabled,
        resources: span.resources,
        capacity: services.length === 1 ? span.capacity || undefined : undefined,
      });
    }

    return ret;
  }

  showLoginTeaserModal = (value, callback) => {
    this.setState({ showLogin: value || false }, () => {
      if (callback) {
        callback();
      }
    });
  };

  /**
   * @typedef ErpTimeSlot
   * @property {string} id the Id
   * @property {string} start the starting time
   * @property {number} capacity the capacity
   * @property {number} price the price in cents
   * @property {number[]} serviceIds services that will be booked on this slot
   * @property {number} listPrice the list price in cents
   * @property {number} employeeId which calendar will be booked on this slot
   *
   * Pick a specific ErpTimeSlot to book using ERP availabilities
   * @param {ErpTimeSlot} timeSlot the time slot to book
   */
  async pickHourErp(timeSlot) {
    const { changeTimeBookingId } = this.props;

    if (Boolean(changeTimeBookingId)) {
      this.setState({ confirmChangeBookingTime: timeSlot });
      return;
    }

    const { dispatch, place, services, hasActiveGooglePayPaymentMethod, hasActiveApplePayPaymentMethod } = this.props;

    const timeSlotEmployee = place.employees.filter((e) => e.id === timeSlot.employeeId)[0];
    const timeSlotMoment = moment(timeSlot.start);
    const timeSlotUnix = moment.utc(timeSlotMoment).unix() + timeSlotMoment.utcOffset() * 60;
    const startOfDayUnix = moment.utc(timeSlotMoment).startOf('day').unix();
    const startOfDayUnixOld = startOfDay(startOfDayUnix * 1000);
    const hour = timeSlotUnix - startOfDayUnix;

    let dynamicPriceListIdKey = bookConstants.DYNAMIC_PRICE_KEY_DEFAULT;
    if ((services || []).some((service) => hasDynamicPricing(service))) {
      dispatch(loadingActions.show('loadHours'));
      try {
        const hasDynamicPrice = await bookServicesOld.hasDynamicPrice(place.id, timeSlot.start);
        if (hasDynamicPrice?.dynamicPriceListIdKey) {
          dynamicPriceListIdKey = hasDynamicPrice.dynamicPriceListIdKey;
        }
      } catch (e) {}
      dispatch(loadingActions.hide());
    }

    this.setState({ selectedDay: getDayInfo(startOfDayUnixOld), clicked: true, hour: hour });

    dispatch(
      bookActions.pickHour(startOfDayUnixOld, hour, [timeSlotEmployee], dynamicPriceListIdKey, [], timeSlot.capacity),
    );
    dispatch(bookActions.setCampaigns(services, place.campaigns, startOfDayUnixOld));

    const checkoutRedirect = {
      pathname: '/booking/checkout',
      state: {
        employee: timeSlotEmployee,
        fromUrl: window.location.href,
        user: this.props.user,
        hasActiveGooglePayPaymentMethod,
        hasActiveApplePayPaymentMethod,
        cancelBooking: this.props?.location?.state?.cancelBooking,
      },
    };

    this.props.history.push(checkoutRedirect);
  }

  hideTooltip(isDisabled) {
    if (isDisabled) {
      this.setState({ showTooltip: false });
    }
  }

  moveMouse(e, isDisabled) {
    if (isDisabled) {
      const x = e.clientX - 10 + 'px',
        y = e.clientY + 20 + 'px';
      this.setState({ showTooltip: { x: x, y: y } });
    }
  }

  showSubscribePopup() {
    this.setState({ showSubscribePopup: true });
  }

  closeSubscribe() {
    this.setState({ showSubscribePopup: false });
  }

  renderSubscribe() {
    return (
      <ModalDialog isOpen={true} appElement={document.getElementById('root')} onRequestClose={this.closeSubscribe}>
        <ModalContent size={isMobile() ? 'lg' : undefined}>
          <div>
            <div className="flex w-full justify-end p-4">
              <Fab
                icon={<Icon variant="close" />}
                onClick={this.closeSubscribe}
                size="md"
                variant="primary"
                aria-label="Close"
              />
            </div>
            <div className={`p-4 sm:px-3 md:px-10`}>
              <h3 className="mb-4 mr-12 hidden text-center text-lg font-semibold sm:block">
                {__('subscribeToWaitingList')}
              </h3>
              <p className="text-center">{__('subscribeToWaitingListInfo')}</p>
              <h5 className="text-md mt-4 font-semibold sm:block">{__('subscribeToWaitingListWhen')}</h5>
              <div className="py-4">
                <div
                  className={`hover:border-primary
                  border-black-300
                  relative
                  mb-4
                  flex
                  w-full
                  cursor-pointer
                  flex-col
                  items-start
                  justify-between
                  rounded-lg
                  border-[1px]
                  bg-white
                  py-3
                  pl-4
                  transition-colors
                  duration-300
                  focus:outline-none
                  focus-visible:outline-1
                  focus-visible:outline-black
                  ${this.state.waitingList === 1 ? 'border-primary bg-primary-100' : ''}
                  `}
                  onClick={() => {
                    this.setState({ waitingList: 1 });
                  }}>
                  <span className="font-semibold">{__('subscribeToWaitingListOption1Title')}</span>
                  {__('subscribeToWaitingListOption1text')}
                </div>
                <div
                  className={`hover:border-primary
                  border-black-300
                  relative
                  mb-4
                  flex
                  w-full
                  cursor-pointer
                  flex-col
                  items-start
                  justify-between
                  rounded-lg
                  border-[1px]
                  bg-white
                  py-3
                  pl-4
                  transition-colors
                  duration-300
                  focus:outline-none
                  focus-visible:outline-1
                  focus-visible:outline-black
                  ${this.state.waitingList === 2 ? 'border-primary bg-primary-50' : ''}
                  `}
                  onClick={() => {
                    this.setState({ waitingList: 2 });
                  }}>
                  <span className="font-semibold">{__('subscribeToWaitingListOption2Title')}</span>
                  {__('subscribeToWaitingListOption2text')}
                </div>
                <div
                  className={`hover:border-primary
                  border-black-300
                  relative
                  mb-4
                  flex
                  w-full
                  cursor-pointer
                  flex-col
                  items-start
                  justify-between
                  rounded-lg
                  border-[1px]
                  bg-white
                  py-3
                  pl-4
                  transition-colors
                  duration-300
                  focus:outline-none
                  focus-visible:outline-1
                  focus-visible:outline-black
                  ${this.state.waitingList === 3 ? 'border-primary bg-primary-50' : ''}
                  `}
                  onClick={() => {
                    this.setState({ waitingList: 3 });
                  }}>
                  <span className="font-semibold">{__('subscribeToWaitingListOption3Title')}</span>
                  {__('subscribeToWaitingListOption3text')}
                </div>
              </div>
              <Button
                className="w-full"
                disabled={!this.state.waitingList}
                href={'/waiting-list-success'}
                onClick={() => {
                  this.props.history.push({ pathname: '/waiting-list-success' });
                }}>
                {__('subscribeToWaitingAdd')}
              </Button>
            </div>
          </div>
        </ModalContent>
      </ModalDialog>
    );
  }

  getNotAvailableButton(allEmpty) {
    const { availability } = this.props;
    const { availabilityOverview, changeTimeAvailability } = this.state;
    const { loading /*, selectedDay*/ } = this.state;
    const buttonContainerClassName = 'w-full border-[1px] border-black-100 pb-10 text-center';
    const illustration = <img src="/images/calendar-no-time-icon.svg" className="mx-auto py-8" alt="" width={260} />;
    let button = null;

    if (availabilityOverview) {
      let nextAvailableText = null;
      let firstAvailableTime = null;
      if (allEmpty && !loading && availabilityOverview.firstAvailableDate) {
        const firstAvailableDate = moment(availabilityOverview.firstAvailableDate);
        firstAvailableTime = moment
          .utc([firstAvailableDate.year(), firstAvailableDate.month(), firstAvailableDate.date()])
          .valueOf();
        nextAvailableText =
          firstAvailableDate.date() +
          ' ' +
          this.months[firstAvailableDate.month()] +
          ' ' +
          firstAvailableDate.year() +
          ', ' +
          config.weekDaysLong[firstAvailableDate.day()];
      }

      button =
        allEmpty && !loading ? (
          <div className={buttonContainerClassName}>
            {illustration}
            <h3 className="daypicker">
              {__('notAvailableWeek')}
              {nextAvailableText && !changeTimeAvailability && (
                <span className="firstday">
                  <span>{__('nextAvailableWeek')}: </span>
                  <span className="font-semibold" onClick={() => this.pickDay(firstAvailableTime)}>
                    {nextAvailableText}
                  </span>
                </span>
              )}
            </h3>
            <div className="flex flex-col-reverse justify-center gap-2 md:flex-row md:gap-0">
              {this.props.place.about.settings?.supportsWaitlist && !changeTimeAvailability && (
                <div className="px-4">
                  <Button
                    variant="secondary"
                    className="waitinglist-button whitespace-nowrap"
                    style={{ minWidth: 200 }}
                    onClick={() => this.showSubscribePopup()}>
                    {__('subscribeToWaitingList')}
                  </Button>
                </div>
              )}
              {nextAvailableText && !changeTimeAvailability && (
                <div className="px-4">
                  <Button style={{ minWidth: 200 }} onClick={() => this.pickDay(firstAvailableTime)}>
                    {__('showNextTime')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : null;
      if (
        this.props.place.about.settings?.supportsWaitlist &&
        availabilityOverview &&
        !availabilityOverview.firstAvailableDate
      ) {
        button =
          allEmpty && !loading ? (
            <div className={buttonContainerClassName}>
              {illustration}
              <h3 className="daypicker">{__('notAvailableCouldNotFind')}</h3>
              <div className="px-4">
                <Button
                  variant="secondary"
                  className="waitinglist-button whitespace-nowrap"
                  style={{ minWidth: 200 }}
                  onClick={() => this.showSubscribePopup()}>
                  {__('subscribeToWaitingList')}
                </Button>
              </div>
            </div>
          ) : null;
      }
    } else {
      let nextAvailableText = null;
      if (allEmpty && !loading && availability && availability.firstDay) {
        const dayInfo = getDayInfo(availability.firstDay.time);
        nextAvailableText =
          dayInfo.dd + ' ' + this.months[dayInfo.mm] + ' ' + dayInfo.yyyy + ', ' + config.weekDaysLong[dayInfo.weekDay];
      }

      button =
        allEmpty && !loading ? (
          <div className={buttonContainerClassName}>
            {illustration}
            <h3 className="daypicker">
              {__('notAvailableWeek')}
              {nextAvailableText && (
                <span className="firstday">
                  <span>{__('nextAvailableWeek')}: </span>
                  <span className="font-semibold" onClick={() => this.pickDay(this.props.availability.firstDay.time)}>
                    {nextAvailableText}
                  </span>
                </span>
              )}
            </h3>
            <div className="flex flex-col-reverse justify-center gap-2 md:flex-row">
              {this.props.place.about.settings?.supportsWaitlist && (
                <div className="px-4">
                  <Button className={'bg-white'} style={{ minWidth: 200 }} onClick={() => this.showSubscribePopup()}>
                    {__('subscribeToWaitingList')}
                  </Button>
                </div>
              )}
              {nextAvailableText && (
                <div className="px-4">
                  <Button style={{ minWidth: 200 }} onClick={() => this.pickDay(availability.firstDay.time)}>
                    {__('showNextTime')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        ) : null;
      if (
        this.props.place.about.settings?.supportsWaitlist &&
        availability &&
        availability.firstDay &&
        availability.firstDay.none
      ) {
        button =
          allEmpty && !loading ? (
            <div className={buttonContainerClassName}>
              {illustration}
              <h3 className="daypicker">{__('notAvailableCouldNotFind')}</h3>
              <div className="px-4">
                <Button
                  variant="secondary"
                  className="waitinglist-button whitespace-nowrap"
                  style={{ minWidth: 200 }}
                  onClick={() => this.showSubscribePopup()}>
                  {__('subscribeToWaitingList')}
                </Button>
              </div>
            </div>
          ) : null;
      }
    }

    return button;
  }

  handleConfirmChangeBookingTime = async () => {
    const { changeTimeBookingId, dispatch } = this.props;
    const { confirmChangeBookingTime } = this.state;

    dispatch(loadingActions.show('changeBookingTime'));

    const timestamp = convertToUTCPlusOffset(confirmChangeBookingTime?.start).unix();

    const { data, error } = await promiseWrapper(bookServices.changeBookingTime(changeTimeBookingId, timestamp));

    const validateResponse = changeBookingTimeResponseSchema.safeParse(data);
    const isError = error || !validateResponse.success;

    dispatch(loadingActions.hide());

    if (isError) {
      toast(({ closeToast }) => <Snackbar type="danger" label={__('serverError')} onClose={closeToast} />);
    } else {
      trackMpEvent(EVENT_NAME.CHANGE_BOOKING_TIME_SUCCESS, {
        screen_name: 'booking_step_choose_time',
      });

      this.props.history.replace({
        pathname: `/bokning/${validateResponse.data.id}`,
        state: { changedBookingTime: true },
      });
    }
    this.setState({ confirmChangeBookingTime: undefined });
  };

  allowsGroupBookings() {
    const { services = [] } = this.props;
    for (let i = 0; i < services.length; i++) {
      const { capacity } = (services[i] && services[i].about) || {};
      if (!(capacity && capacity > 1)) return false;
    }

    return true;
  }

  getPriceAdjusted(price) {
    const { windowSize } = this.props;

    if (!price) {
      return <>&nbsp;</>;
    }

    if (price?.length && !price.includes(' kr')) {
      if (windowSize.width >= 500 && windowSize.width < breakpoints.md) {
        if (price.length <= 7) {
          price += ' kr';
        }
      }

      if (windowSize.width >= breakpoints.md && windowSize.width < 825) {
        if (price.length <= 5) {
          price += ' kr';
        }
      }

      if (windowSize.width >= 825 && windowSize.width < breakpoints.lg) {
        if (price.length <= 6) {
          price += ' kr';
        }
      }

      if (windowSize.width >= breakpoints.lg) {
        price += ' kr';
      }
    }

    return <>{price}</>;
  }

  render() {
    const {
      year,
      weekOfYear,
      intervals,
      firstDay,
      showTooltip,
      weekDays,
      showLogin,
      confirmChangeBookingTime,
      changeTimeAvailability,
    } = this.state;
    const { show, loadSource, campaignStart, campaignEnd, isLoading: loadingActiveWalletMethods } = this.props;

    const settings = {
      dots: false,
      infinite: false,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      draggable: false,
      onSwipe: (direction) => {
        direction === 'left' ? this.handleNextSwipe() : this.handlePrevSwipe();
      },
    };

    let i = 0;
    let j = 0;
    let k = 0;
    let allEmpty = true;
    const calendarTimeSlots = (this.props.availability && this.props.availability.calendarTimeSlots) || {};
    const showPrices = (this.props.availability?.fromErp || []).some((slot) => !!slot.employeePrices?.priceLabel);
    const showCapacity = !changeTimeAvailability && this.allowsGroupBookings();
    let availabilities = [];
    let availableDays = {};

    availabilities = intervals.map((weekDay) => {
      return (
        <div
          className="border-black-100 min-w-0 flex-shrink flex-grow basis-0 border-b-[1px] border-l-[1px] text-center last-of-type:border-r-[1px]"
          key={i++}>
          {Object.keys(calendarTimeSlots)
            .filter((key) => {
              const fe = calendarTimeSlots[key];
              return moment(fe.start).isSame(moment(new Date(parseInt(weekDay.day, 10))), 'day');
            })
            .map((key) => {
              allEmpty = false;
              availableDays[weekDay.day] = '';
              const fe = calendarTimeSlots[key];
              const hasCampaign =
                campaignStart &&
                campaignEnd &&
                parseInt(weekDay.day / 1000, 10) >= campaignStart &&
                parseInt(weekDay.day / 1000, 10) <= campaignEnd;

              const showCampaignStyles = hasCampaign && !changeTimeAvailability;

              return (
                <span
                  key={j++}
                  data-cy="weekPickerTimeslot"
                  className={`group mx-1 my-[6px] flex h-auto cursor-pointer flex-col rounded-sm border-[1px] border-solid text-center text-xs md:m-2 ${
                    weekDay.day === this.state.selectedDay.timestamp && this.state.hour === moment(fe.start).hour()
                      ? showCampaignStyles
                        ? ' bg-information-200 border-information-900'
                        : ' bg-primary-200 border-primary-900'
                      : ''
                  } ${
                    showCampaignStyles
                      ? ' text-information-900 border-information-700 bg-information-50 hover:border-information-900 hover:bg-information-200'
                      : ' border-brand-700 bg-brand-50 text-brand-900 hover:border-primary-900 hover:bg-primary-200'
                  } ${showPrices || showCapacity ? 'pt-1 ' : 'py-2'}`}
                  onClick={() => {
                    this.pickHourErp(fe);
                  }}>
                  <span className="font-semibold">{moment(fe.start).format('HH:mm')}</span>
                  {showPrices && (
                    <span className={`pb-1 text-[10px] leading-[12px] ${showCampaignStyles ? '' : ''}`}>
                      {this.getPriceAdjusted(fe.employeePrices?.finalPriceLabel || fe.employeePrices?.priceLabel)}
                    </span>
                  )}
                  {showCapacity && (
                    <span
                      className={`text-xxs rounded-bl-[5px] rounded-br-[5px] !leading-4 text-white ${
                        showCampaignStyles
                          ? ' group-hover:bg-information-900 bg-information-700'
                          : ' group-hover:bg-primary-900 bg-brand-900'
                      }`}>
                      {fe.capacity} {__('cap')}
                    </span>
                  )}
                </span>
              );
            })}
        </div>
      );
    });

    availableDays = Object.keys(availableDays);

    const button = this.getNotAvailableButton(allEmpty);
    let loader = null;
    let hourLoad = null;

    if (show) {
      switch (loadSource) {
        case 'pickService':
        case 'pickEmployee':
          loader = <LoadingPlaceHolder text={__('loadingHours')} style={{ marginTop: '140px' }} />;
          break;
        case 'loadHours':
          hourLoad = <LoadingPlaceHolder text={__('loadingHours')} />;
          break;
        case 'changeBookingTime':
          loader = <LoadingPlaceHolder style={{ marginTop: '140px' }} />;
          break;
        default:
          break;
      }
    }

    return (
      <div className="calendar week-view">
        {showTooltip && (
          <span
            className="tooltip shadow-black-50 h-auto max-w-[200px] rounded bg-white px-4 py-3 text-sm opacity-100"
            style={{ position: 'fixed', top: showTooltip.y, left: showTooltip.x }}>
            {__('noAppointmentsInThisDate')}
          </span>
        )}
        {loadingActiveWalletMethods && <LoadingPlaceHolder style={{ marginTop: '140px' }} />}
        {loader}
        <p className="sticky !top-16 z-[100] m-0 flex flex-col items-center justify-center bg-white p-3 uppercase">
          <span className="text-black-900 font-semibold">{year}</span>
          <span className="text-black-700 text-xs">{__('week') + ' ' + weekOfYear}</span>
        </p>
        <div className="sticky !top-32 z-[100] bg-white shadow">
          <Slider ref={(c) => (this.slider = c)} className="slick-calendar" {...settings}>
            {weekDays.map((currentWeek, index) => (
              // Hours Header
              <div key={index} className="!flex w-full justify-between">
                {currentWeek.map((day, key) => {
                  const hasProgram = availableDays.indexOf(day.timestamp + '') !== -1;
                  const isDisabled = !!(day.timestamp < firstDay.timestamp || !hasProgram);
                  const hasCampaign =
                    !isDisabled &&
                    campaignStart &&
                    campaignEnd &&
                    parseInt(day.timestamp / 1000, 10) >= campaignStart &&
                    parseInt(day.timestamp / 1000, 10) <= campaignEnd;

                  const showCampaignStyles = hasCampaign && !changeTimeAvailability;

                  return (
                    <div
                      key={k++}
                      className={`border-black-200 flex-grow basis-0 border-b-[1px] border-l-[1px] bg-white text-center last-of-type:border-r-[1px] colIndex-${
                        key % 7
                      } ${
                        day.timestamp === startOfDay()
                          ? 'text-black-900'
                          : isDisabled
                          ? 'text-black-300'
                          : 'text-black-900'
                      }`}>
                      <span className="block pt-2 text-center text-sm capitalize text-inherit">
                        {firstDay.timestamp === day.timestamp ? __('today') : this.days[day.weekDay]}
                      </span>
                      <span className="relative mx-auto mb-1 block h-11 text-lg font-semibold !leading-[44px] text-inherit">
                        {day.dd}
                        {day.timestamp === startOfDay() && (
                          <span className="bg-black-900 absolute bottom-[2px] left-1/2 h-[6px] w-[6px] -translate-x-1/2 rounded-full"></span>
                        )}
                      </span>
                      {showCampaignStyles && (
                        <span className="bg-information block text-center text-[11px] text-white">
                          {__('campaignOffer')}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            ))}
          </Slider>
          <div className="absolute -top-12 left-0 z-20">
            <Button
              className="focus:!ring-0"
              variant="link"
              onClick={this.handlePrevClick}
              disabled={this.state.disablePrev}
              leftIcon={<ChevronIcon className="h-4 w-3 rotate-90" />}>
              {__('earlier')}
            </Button>
          </div>
          <div className="absolute -top-12 right-0 z-20">
            <Button
              className="focus:!ring-0"
              variant="link"
              onClick={this.handleNextClick}
              disabled={this.state.disableNext}
              rightIcon={<ChevronIcon className="h-4 w-3 -rotate-90" />}>
              {__('later')}
            </Button>
          </div>
        </div>
        <div className="hours sticky">
          {hourLoad}
          <div className="flex w-full" style={{ minHeight: '300px' }}>
            {!allEmpty && availabilities}
            {button}
            {this.state.showSubscribePopup && <WaitlistModal isOpen onRequestClose={this.closeSubscribe} />}
          </div>
        </div>
        {showLogin && <LoginTeaser showModal={showLogin} showLoginTeaserModal={this.showLoginTeaserModal} />}
        {changeTimeAvailability && confirmChangeBookingTime && (
          <ConfirmChangeBookingTimeModal
            isOpen
            loading={Boolean(loader)}
            newTimestamp={convertToUTCPlusOffset(confirmChangeBookingTime.start).toDate()}
            onClose={() => this.setState({ confirmChangeBookingTime: undefined })}
            onConfirm={this.handleConfirmChangeBookingTime}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { services, availability, employee, time, place, set, isNotAvailable, changeTimeBookingId } = state.book;
  const { show, loadSource } = state.loading;
  const { user } = state.users;

  return {
    changeTimeBookingId,
    services,
    availability,
    employee,
    place,
    time,
    set,
    isNotAvailable,
    show,
    loadSource,
    user,
    flags: state.flags,
  };
}

const connectedWeekPicker = connect(mapStateToProps)(
  withRouter(withWalletPayment(withAmplitudeExperiment(withMobileView(WeekPicker)))),
);
export { connectedWeekPicker as WeekPicker };
