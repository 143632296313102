import Icon from '@/components/icons/Icon';
import { APP_ROUTES } from '@/constants/pages';
import { getImageSize } from '@/helpers';
import { _s } from '@/locale';
import { Link } from 'react-router-dom';

type AssociationBadgeProps = {
  association: {
    description: string;
    imgSrc: string;
    name: string;
    text?: string;
    slug?: string;
  };
};

const AssociationBadge = ({ association }: AssociationBadgeProps) => {
  const imageSource = getImageSize(association.imgSrc, 'x44');
  return (
    <Link
      to={APP_ROUTES.ASSOCIATION_PAGES + (association.slug ? `/${association.slug}` : '')}
      className="bg-black-50 rounded-xs p-md flex">
      <div className="space-y-xxs">
        <span className="text-black-600 text-xxs gap-xxs inline-flex">
          {_s('member')} <Icon variant="industry-organization" color="black-500" />
        </span>
        <span className="text-xxs text-black-900 block">{association.name}</span>
      </div>
      <div className="ml-auto flex flex-shrink-0 items-center justify-end">
        <img
          width={28}
          height={28}
          className="max-h-[28px] w-auto"
          src={imageSource}
          alt={association.description}
          loading="lazy"
        />
      </div>
    </Link>
  );
};

export default AssociationBadge;
