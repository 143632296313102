import Icon from '@/components/icons/Icon';
import { IconProps } from '@/components/icons/types';
import { _s } from '@/locale';
import { twMerge } from 'tailwind-merge';

const baseTranslationKey = 'components.elements.Label';

export const LABEL_VARIANT = {
  DEFAULT: 'default',
  GIFTCARD: 'giftcard',
  WELLNESS: 'wellness',
  ROCKET: 'rocket',
  DISCOUNT: 'discount',
  CERTIFICATE: 'certificate',
  CAMPAIGN: 'campaign',
  ORG: 'org',
  BUNDLE: 'bundle',
  KLARNA: 'klarna',
  QLIRO: 'qliro',
  NOVELTY: 'novelty',
  FULLY_BOOKED: 'fully-booked',
} as const;

export type LabelVariant = (typeof LABEL_VARIANT)[keyof typeof LABEL_VARIANT];

export type LabelProps = {
  variant?: LabelVariant;
  icon?: Pick<IconProps, 'variant' | 'color'> | false;
  label?: string;
  className?: string;
  size?: 'sm' | 'lg';
};

/**
 * We will do a rewamp of our design system which will come with a completly new color palette and design tokens spacing, typography etc.
 * Labels and tags will be the first components that will use colors from new palette. For now we specify design tokens
 * with one-off styles like hex colors, px values etc just because implementing new design system will be a bigger effort
 *
 * When we implement new design system we will remove this and move to tailwind/make it proper
 */
const LABEL_DEFAULT_COLOR = '#E6EDF2';

const BADGE_COLORS: {
  [key in LabelVariant]?: string;
} = {
  [LABEL_VARIANT.DEFAULT]: LABEL_DEFAULT_COLOR,
  [LABEL_VARIANT.QLIRO]: LABEL_DEFAULT_COLOR,
  [LABEL_VARIANT.CERTIFICATE]: LABEL_DEFAULT_COLOR,
  [LABEL_VARIANT.ORG]: LABEL_DEFAULT_COLOR,
  [LABEL_VARIANT.BUNDLE]: '#FFD7CC',
  [LABEL_VARIANT.ROCKET]: '#F4E5B1',
  [LABEL_VARIANT.NOVELTY]: '#F5CC3B',
  [LABEL_VARIANT.CAMPAIGN]: '#B6D6F6',
  [LABEL_VARIANT.DISCOUNT]: '#F86A61',
  [LABEL_VARIANT.GIFTCARD]: '#DBB4E5',
  [LABEL_VARIANT.WELLNESS]: '#CEEDDA',
  [LABEL_VARIANT.KLARNA]: '#F4B6CA',
  [LABEL_VARIANT.FULLY_BOOKED]: '#F0E5E4',
};

const LABEL_ICON_MAP: {
  [key in LabelVariant]?: Pick<IconProps, 'variant' | 'color'>;
} = {
  giftcard: { variant: 'gift-card', color: 'black-900' },
  wellness: { variant: 'wellness-card', color: 'black-900' },
  rocket: { variant: 'rocket', color: 'black-900' },
  certificate: { variant: 'certificate', color: 'black-900' },
  campaign: { variant: 'campaign', color: 'black-900' },
  org: { variant: 'industry-organization', color: 'black-900' },
  bundle: { variant: 'punch-card', color: 'black-900' },
  klarna: { variant: 'klarna', color: 'black-900' },
  qliro: { variant: 'qliro-alt', color: 'black-900' },
  novelty: { variant: 'news', color: 'black-900' },
};

const sizeClasses = {
  sm: 'px-xs py-[4px] space-x-xxs',
  lg: 'px-sm py-xs space-x-xs',
};
const variantClasses: {
  [key in LabelVariant]?: string;
} = {
  discount: 'text-white',
};

const getLabelTextIfAny = (type: LabelVariant): string | undefined => {
  switch (type) {
    case LABEL_VARIANT.GIFTCARD:
    case LABEL_VARIANT.WELLNESS:
    case LABEL_VARIANT.ROCKET:
    case LABEL_VARIANT.CERTIFICATE:
    case LABEL_VARIANT.CAMPAIGN:
    case LABEL_VARIANT.ORG:
    case LABEL_VARIANT.BUNDLE:
    case LABEL_VARIANT.KLARNA:
    case LABEL_VARIANT.QLIRO:
    case LABEL_VARIANT.NOVELTY:
    case LABEL_VARIANT.FULLY_BOOKED:
      return _s(`${baseTranslationKey}.text.${type}`);
  }
  return undefined;
};

const Label = ({ variant = 'default', icon, label, size = 'sm', className }: LabelProps) => {
  const hasBackgroundClassOverride = className?.includes('bg-');

  const style: React.CSSProperties = {
    ...(!hasBackgroundClassOverride && { backgroundColor: BADGE_COLORS[variant] ?? LABEL_DEFAULT_COLOR }),
  };
  const _icon = icon ?? LABEL_ICON_MAP[variant];
  const _label = label ?? getLabelTextIfAny(variant);

  return (
    <div
      className={twMerge(
        'text-black-900 inline-flex items-center rounded-sm',
        className,
        sizeClasses[size],
        variantClasses[variant],
      )}
      style={style}>
      {_icon && <Icon variant={_icon.variant} size="xs" color={_icon.color || 'black-900'} />}
      {_label && <span className="text-xxs text-current">{_label}</span>}
    </div>
  );
};

export default Label;
