import Snackbar from '@/components/elements/notifications/Snackbar/Snackbar';
import { promiseWrapper } from '@/helpers';
import { _s } from '@/locale';
import { giftCardServices } from '@/services';
import { InitKlarnaResponse } from '@/types/api/services/booking';
import { KPAuthorizationResponse } from '@/types/klarnaPayments';
import { Dispatch, useState } from 'react';
import { toast } from 'react-toastify';

type Status = 'SUBMITTING' | 'ERROR' | 'INITIAL';

type SubmitKlarnaGiftcardContext = {
  successCallback: ({ redirectURL }: { redirectURL: string }) => void;
  errorCallback: () => void;
};

type KlarnaGiftcardCheckoutAPI = {
  onSubmitKlarna: (res: KPAuthorizationResponse, klarnaOrder: InitKlarnaResponse) => Promise<void>;
};

type KlarnaGiftcardCheckoutManager = {
  klarnaGiftcardCheckoutAPI: () => KlarnaGiftcardCheckoutAPI;
  status: Status;
};

function handleOnSubmitKlarna(context: SubmitKlarnaGiftcardContext, dispatch: Dispatch<Status>) {
  return async (res: KPAuthorizationResponse, klarnaOrder: InitKlarnaResponse) => {
    dispatch('SUBMITTING');
    const { data } = await promiseWrapper(
      giftCardServices.createGiftCardOrder(klarnaOrder.sessionID, res.authorization_token),
    );

    if (!data?.redirectURL) {
      context.errorCallback();
      return;
    }
    context.successCallback({ redirectURL: data?.redirectURL });
  };
}

const useKlarnaGiftcardCheckoutManager = (): KlarnaGiftcardCheckoutManager => {
  const [status, setStatus] = useState<Status>('INITIAL');

  const handleKlarnaError = () => {
    setStatus('ERROR');
    toast(({ closeToast }) => <Snackbar label={_s('serverError')} type="danger" onClose={closeToast} />, {
      autoClose: false,
    });
  };

  const handleKlarnaSuccess = ({ redirectURL }) => {
    window.location.href = redirectURL;
  };

  const klarnaGiftcardCheckoutAPI = (): KlarnaGiftcardCheckoutAPI => {
    const errorCallback = handleKlarnaError;
    const successCallback = handleKlarnaSuccess;
    const context = {
      errorCallback,
      successCallback,
    };

    return {
      onSubmitKlarna: handleOnSubmitKlarna(context, setStatus),
    };
  };

  return {
    klarnaGiftcardCheckoutAPI,
    status,
  };
};

export default useKlarnaGiftcardCheckoutManager;
