import colors from '@/colors';
import { AVATAR_SIZE } from '@/constants/AvatarConstants';
import { classnames } from '@/helpers';
// import * as chroma from 'chroma-js';
// import stc from 'string-to-color';
import { AvatarVariantIcon } from '../AvatarVariantIcon';
import { AvatarProps, AvatarSize } from '../types';

type InitialsAvatarProps = Omit<AvatarProps, 'imgUrl' | 'alt'> & {
  initials: string;
};

const initialFontSize: Record<AvatarSize, string> = {
  sm: 'text-xxs',
  md: 'text-sm',
  lg: 'text-md',
  xl: 'text-xl',
};

const InitialsAvatar = ({ initials, variant = 'default', size = 'xl' }: InitialsAvatarProps) => {
  const bgColor = colors.black[900]; // stc(initials);
  const textColor = '#FFFFFF';
  // chroma.contrast(colors.black[900], bgColor) > chroma.contrast('#FFFFFF', bgColor) ? colors.black[900] : '#FFFFFF';

  return (
    <div className="relative inline-block">
      <div
        style={{ backgroundColor: bgColor, color: textColor }}
        className={classnames(
          AVATAR_SIZE[size],
          'relative flex items-center justify-center overflow-hidden rounded-full',
        )}>
        <span className={classnames(initialFontSize[size])}>{initials || ''}</span>
      </div>
      {variant !== 'default' && <AvatarVariantIcon size={size} variant={variant} />}
    </div>
  );
};

export default InitialsAvatar;
