import colors from '@/colors';

export const getBannerStyles = (backgroundColor: string, textColor?: string): React.CSSProperties => ({
  backgroundColor: backgroundColor || '#FFFFFF',
  color: textColor || colors['black'][900],
});

const H1 = ({ children }) => <h1 className="text-xl">{children}</h1>;
const H2 = ({ children }) => <h1 className="text-black-900 text-xl font-semibold">{children}</h1>;
const H3 = ({ children }) => <h1 className="text-black-900 text-lg font-semibold">{children}</h1>;
const H4 = ({ children }) => <h1 className="text-md text-black-900">{children}</h1>;

const SpanStyledAsAnchor = ({ children, style, className }) => {
  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
};

export const markdownOverrides = (linkColor: string) => ({
  a: { props: { style: { color: linkColor }, className: 'underline' }, component: SpanStyledAsAnchor },
  strong: { props: { className: 'font-bold text-inherit' } },
  p: { props: { className: '' } },
  ul: { props: { className: 'list-disc pl-lg space-y-md' } },
  h1: { component: H1 },
  h2: { component: H2 },
  h3: { component: H3 },
  h4: { component: H4 },
});

export const MarkdownContentWrapper = ({ children }) => {
  return <div className="space-y-lg">{children}</div>;
};
