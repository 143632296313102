import * as animationData from '@/assets/animations/loadingDotsBlue.json';
import AnimationWrapper from '../AnimationWrapper';
import { AnimationProps } from '../types';

const LoadingDotsBlue = (props: AnimationProps) => {
  const animationProps = { ...props, animationData };
  return <AnimationWrapper {...animationProps} />;
};

export default LoadingDotsBlue;
